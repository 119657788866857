import React from "react";
import { useSelector } from "react-redux";
import CustomMagicDropDown from "../../../../../../../../common/components/ChatDropDown/CustomMagicDropDown";
import { translate } from "../../../../../../../../common/providers";

export default function ReviewRedirectionId() {
	const selectedRectangles = useSelector(({ srDocument }) => srDocument.selectedRectangles);
	const informationIds = selectedRectangles.map((rectangle) => rectangle.informationId);
	const questions = [
		{
			title: translate("smart-review.translate-paragraph"),
			choices: [
				{
					title: translate("smart-review.french-language"),
					text: translate("smart-review.paragraph-translate.question", {
						language: translate("smart-review.french-language"),
					}),
					summary: translate("smart-review.paragraph-translate.summary", {
						IdParagraph: informationIds,
					}),
				},
				{
					title: translate("smart-review.english-language"),
					text: translate("smart-review.paragraph-translate.question", {
						language: translate("smart-review.english-language"),
					}),
					summary: translate("smart-review.paragraph-translate.summary", {
						IdParagraph: informationIds,
					}),
				},
				{
					title: translate("smart-review.spanish-language"),
					text: translate("smart-review.paragraph-translate.question", {
						language: translate("smart-review.spanish-language"),
					}),
					summary: translate("smart-review.paragraph-translate.summary", {
						IdParagraph: informationIds,
					}),
				},
			],
		},
		{
			title: translate("smart-review-summarize-paragraph"),
			text: translate("smart-review-summarize.question"),
			summary: translate("smart-review-summarize-paragraph.summary", { IdParagraph: informationIds }),
		},
		{
			title: translate("smart-review-contradiction"),
			text: translate("smart-review-contradiction-paragraph.question"),
			summary: translate("smart-review-contradiction-paragraph.summary", { IdParagraph: informationIds }),
		},
		{
			title: translate("smart-review.ask-another-question"),
			text: "",
		},
	];
	const handleGetRedirectToChatFilters = (value, summary) => ({
		state: {
			filters: {
				filter: {
					informationIds: {
						elements: informationIds,
						is: true,
						matchAll: false,
					},
					separator: "AND",
					Type: "QuickAccessSearchFilterDTO",
				},
				value,
				summary,
			},
		},
	});
	const handleDisabled = () => informationIds.length === 0;

	return (
		<CustomMagicDropDown
			notAutoLaunch
			disabled={handleDisabled()}
			questions={questions}
			onGetRedirectToChatFilters={handleGetRedirectToChatFilters}
		/>
	);
}
