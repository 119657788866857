export const updateTreeBranch = (
	{ idToReplace, iterativeBranchName, identifier = "id", newBranch, tree, additionalConditions },
	{ deepUpdate = false, sortKey = "" } = {}
) => {
	let updatedTree = [];
	if (Array.isArray(tree)) {
		if (deepUpdate) {
			updatedTree = JSON.parse(JSON.stringify(tree));
		} else {
			updatedTree = [...tree];
		}
	}
	if (!Array.isArray(updatedTree) || updatedTree.length === 0) {
		return null;
	}
	let found = false;
	updatedTree.forEach((branch, index) => {
		if (found === false) {
			if (
				branch[identifier] === idToReplace &&
				((typeof additionalConditions === "function" && additionalConditions(branch)) ||
					typeof additionalConditions !== "function")
			) {
				found = true;
				updatedTree[index] = newBranch;
			} else if (Array.isArray(branch[iterativeBranchName]) && branch[iterativeBranchName].length > 0) {
				updatedTree[index][iterativeBranchName] = updateTreeBranch(
					{
						tree: updatedTree[index][iterativeBranchName],
						iterativeBranchName,
						idToReplace,
						newBranch,
						additionalConditions,
					},
					{ deepUpdate, sortKey }
				);
			}
		}
	});
	if (sortKey) {
		return updatedTree.sort((a, b) => (a[sortKey].toLowerCase() > b[sortKey].toLowerCase() ? 1 : -1));
	}
	return updatedTree;
};

export const searchInTree = (data, id, childrenKey = "children") => {
	let companyObject;

	for (const obj of data) {
		if (obj.id === id && (obj.type === "COMPANY" || obj.type === "BUSINESS_UNIT" || obj.type === "CONSORTIUM")) {
			companyObject = obj;
		} else if (obj[childrenKey]) {
			companyObject = searchInTree(obj[childrenKey], id, childrenKey);
		}
		if (companyObject !== undefined) {
			break;
		}
	}

	return companyObject;
};

export const mapTree = (tree, callback, childrenKey = "children") =>
	tree.map((node) => {
		const newNode = callback(node);
		if (newNode[childrenKey]?.length > 0) {
			return {
				...newNode,
				[childrenKey]: mapTree(newNode[childrenKey], callback, childrenKey),
			};
		}
		return newNode;
	});
