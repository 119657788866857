import { isNonEmptyArray } from "../utils";

const isVeolia = () => window.env.REACT_APP_ENV === "veolia";
const isSMAC = () => window.env.REACT_APP_ENV === "smac";
export const environmentSwitch = ({ veolia, main, smac }) => {
	if (isVeolia()) {
		return veolia;
	}
	if (isSMAC()) {
		return smac;
	}
	return main;
};
export const environmentAllowed = (environment) => {
	if (!environment) {
		return false;
	}
	if (isNonEmptyArray(environment)) {
		return environment.some((env) => env === window.env.REACT_APP_ENV);
	}
	return environment === window.env.REACT_APP_ENV;
};
