import React from "react";
import { useSelector } from "react-redux";
import { isNonEmptyArray } from "../../../../../../utils";
import styles from "./DocTree.module.css";
import { ProjectDocumentsService } from "../../../../../../../api";
import { useApi } from "../../../../../../hooks";
import ExpandableDocumentList from "../expandable-document-list/ExpandableDocumentList";
import { icon, IconComponent } from "../../../../../icon-component";
import { translate } from "../../../../../../providers";

// Get all documents in subtree when search used
const getSelectedDocumentIdsRecursive = (documents, result = []) => {
	if (!isNonEmptyArray(documents)) {
		return null;
	}
	let temp = result;
	documents.forEach((doc) => {
		if (isNonEmptyArray(doc.documents)) {
			temp = getSelectedDocumentIdsRecursive(doc.documents, temp);
		} else {
			temp = [...temp, doc.id];
		}
	});
	return temp;
};
// Gets all parents/grandparents of selected element
const getSelectedParentsRecursive = (documents, result = []) => {
	if (!isNonEmptyArray(documents)) {
		return null;
	}
	let temp = result;
	documents.forEach((doc) => {
		if (isNonEmptyArray(doc.documents) || isNonEmptyArray(doc.childrenIds)) {
			temp = [...temp, doc];
			if (isNonEmptyArray(doc.documents)) {
				temp = getSelectedParentsRecursive(doc.documents, temp);
			}
		}
	});
	return temp;
};
// Gets folders inside of selected folder
const getParentsInDir = (documents, childId, path = []) => {
	if (!isNonEmptyArray(documents)) {
		return null;
	}
	for (const doc of documents) {
		if (doc.id === childId) {
			return path;
		}
		if (doc.documents && doc.documents.length) {
			const result = getParentsInDir(doc.documents, childId, [...path, doc.id]);
			if (result) {
				return result;
			}
		}
	}
	return null;
};
// Gets precedence or role of selected document
const getParentInRoleOrPrec = (documents, childId) =>
	documents?.find((document) => document?.documents?.findIndex((d) => d.id === childId) > -1);

const DocByFolder = ({
	documents,
	hasSearch = false,
	mode,
	onAdjustCounters,
	onCheck,
	onToggleExpand,
	parentCounters = [],
	selectedIds = [],
}) => {
	const { call: getPrecedence } = useApi(ProjectDocumentsService.getPrecedence);
	const { call: getRole } = useApi(ProjectDocumentsService.getRole);
	const projectId = useSelector(({ context }) => context.project?.id);
	const handleGetParentId = (selected) => {
		let parentIds = 0;
		if (mode === "folder") {
			parentIds = getParentsInDir(documents, selected);
		} else {
			const parentElement = getParentInRoleOrPrec(documents, selected);
			parentIds = mode === "role" ? [parentElement.role] : [parentElement.precedenceId];
		}
		return parentIds;
	};
	const handleCheckWithChildren = ({ document, isChecked }) => {
		// Used when there is search and documentrs displayed by folder
		if (hasSearch && mode === "folder") {
			onCheck({
				selectedIds: getSelectedDocumentIdsRecursive(document.documents),
				parentIds: [document.id],
				isChecked,
			});
			getSelectedParentsRecursive(document.documents).forEach((parent) => {
				onAdjustCounters({
					parentIds: [parent.id],
					isChecked,
					selectedIds: getSelectedDocumentIdsRecursive(parent.documents),
				});
			});
			// Used when there is search and selected view is by precedence or role
		} else if (isNonEmptyArray(document.documents) && mode !== "folder") {
			const identifier = mode === "precedence" ? "precedenceId" : "role";
			onCheck({ selectedIds: document.documents.map((d) => d.id), parentIds: [document[identifier]], isChecked });
			// Used when there is no search and selected view is by folder
		} else if (mode === "folder") {
			onCheck({
				selectedIds: document.childrenIds,
				parentIds: [document.id, ...handleGetParentId(document.id)],
				isChecked,
			});
			getSelectedParentsRecursive(document.documents)?.forEach((parent) => {
				onAdjustCounters({
					parentIds: [parent.id],
					isChecked,
					selectedIds: parent.childrenIds,
				});
			});
			// Used when there is no search and selected view is by precedence
		} else if (mode === "precedence") {
			getPrecedence({ precedenceId: document.precedenceId })
				.then((data) => {
					onCheck({ selectedIds: data.map((d) => d.id), parentIds: [document.precedenceId], isChecked });
				})
				.catch((err) => console.error(err));
			// Used when there is no search and selected view is by role
		} else if (mode === "role") {
			getRole({ projectId, role: document.role })
				.then((data) => {
					onCheck({ selectedIds: data.map((d) => d.id), parentIds: [document.role], isChecked });
				})
				.catch((err) => console.error(err));
		}
	};
	const handleCheck = ({ document, hasChildren, isChecked }) => {
		if (hasChildren) {
			handleCheckWithChildren({ document, isChecked });
		} else {
			onCheck({ selectedIds: [document.id], parentIds: handleGetParentId(document.id), isChecked });
		}
	};
	return (
		(documents.filter((doc) => doc.count === undefined || doc.count < 1000).length === 0 && (
			<div className={styles.empty}>
				{hasSearch
					? translate("common:filters.document-tree.no-documents-search")
					: translate("common:filters.document-tree.no-documents")}
			</div>
		)) || (
			<>
				{documents.some((doc) => doc.count >= 1000) && (
					<div className={styles.infoBox}>
						<IconComponent className={styles.infoIcon} icon={icon.faInfoCircle} />
						<span>{translate("common:filters.document-tree.too-many-documents")}</span>
					</div>
				)}
				<div className={styles.container}>
					{isNonEmptyArray(documents) && (
						<ExpandableDocumentList
							documents={documents.filter((doc) => doc.count === undefined || doc.count < 1000)}
							mode={mode}
							parentCounters={parentCounters}
							selectedIds={selectedIds}
							onCheck={handleCheck}
							onToggleExpand={onToggleExpand}
						/>
					)}
				</div>
			</>
		)
	);
};

export default DocByFolder;
