import client from "../../client/client";

class QuickAccessService {
	filterRequirements({ documentId }, payload, cancelToken) {
		return client.put(`/quickaccess/documents/${documentId}/filter`, { payload, config: { cancelToken } });
	}

	getFilterResultsInDocumentPage({ documentId, pageNumber }, payload, cancelToken) {
		return client.post(`/quickaccess/documents/${documentId}/page/${pageNumber}/search`, {
			payload,
			config: { cancelToken },
		});
	}

	getInfoVersionList(cancelToken) {
		return client.get("/quickaccess/info-version", {
			config: { cancelToken },
		});
	}
}

export default new QuickAccessService();
