import client from "../../client/client";

class SmartViewService {
	getRedressment({ projectId, docId }, { page = 0 }, cancelToken) {
		return client.get(`/smartview/redressment/projects/${projectId}/documents/${docId}`, {
			params: { page },
			config: { cancelToken },
		});
	}

	getValidatedReqsCount({ documentId }, cancelToken) {
		return client.get(`/smartview/validator-counter/documents/${documentId}/validated`, {
			config: { cancelToken },
		});
	}

	getLockedUnlockedReqsCount({ documentId }, cancelToken) {
		return client.get(`/smartview/validator-counter/documents/${documentId}/freeze`, { config: { cancelToken } });
	}

	getOverwrittenRequirementsCountByReviewer({ documentId, reviewerId }, cancelToken) {
		return client.get(
			`smartview/validator-counter/documents/${documentId}/reviewerId/${reviewerId}/transfer-review`,
			{ config: { cancelToken } }
		);
	}

	getOverwrittenRequirementsCountByCompany({ documentId, companyId }, cancelToken) {
		return client.get(
			`/smartview/validator-counter/documents/${documentId}/companies/${companyId}/transfer-company`,
			{ config: { cancelToken } }
		);
	}

	getOverwrittenRequirementsCountByAllPartners({ documentId }, cancelToken) {
		return client.get(`/smartview/validator-counter/documents/${documentId}/transfer-partners`, {
			config: { cancelToken },
		});
	}

	getOverwrittenRequirementsCountInvalidated({ documentId }, cancelToken) {
		return client.get(`/smartview/validator-counter/documents/${documentId}/invalidated`, {
			config: { cancelToken },
		});
	}

	transferFromReviewer({ documentId }, { reviewerId, overwrite }, cancelToken) {
		return client.put(`/smartview/validator/documents/${documentId}/transfer`, {
			payload: {
				reviewerId,
				overwrite,
			},
			config: { cancelToken },
		});
	}

	transferFromCompany(
		{ documentId },
		{ companyId, overwritePositions, overwriteComments, transferComments },
		cancelToken
	) {
		return client.put(`/smartview/validator/documents/${documentId}/transfer-company`, {
			payload: {
				companyId,
				overwritePositions,
				overwriteComments,
				transferComments,
			},
			config: { cancelToken },
		});
	}

	transferFromMyCompany({ documentId }, { overwrite }, cancelToken) {
		return client.put(`/smartview/validator/documents/${documentId}/transfer-mycompany`, {
			payload: {
				overwrite,
			},
			config: { cancelToken },
		});
	}

	transferFromAllPartners({ documentId }, { overwrite }, cancelToken) {
		return client.put(`/smartview/validator/documents/${documentId}/transfer-partners`, {
			payload: {
				overwrite,
			},
			config: { cancelToken },
		});
	}

	validateUnanimouslyAccepted({ documentId }, cancelToken) {
		return client.post(`/smartview/validator/documents/${documentId}`, {
			config: { cancelToken },
		});
	}

	getQuestions({ documentId }, payload, { page = 0, limit = 25 }, cancelToken) {
		return client.post(`/smartview/documents/${documentId}/questions`, {
			payload,
			params: { page, limit },
			config: { cancelToken },
		});
	}

	getSelectedDocumentCountByRole({ projectId }, { values }, cancelToken) {
		return client.post(`/documents/projects/${projectId}/counter/role`, {
			payload: { values },
			config: { cancelToken },
		});
	}

	getSelectedDocumentCountByPrecedence({ projectId }, { values }, cancelToken) {
		return client.post(`/documents/projects/${projectId}/counter/precedence`, {
			payload: { values },
			config: { cancelToken },
		});
	}

	getQuestionsCounts({ documentId }, cancelToken) {
		return client.get(`/smartview/documents/${documentId}/questions-count`, { config: { cancelToken } });
	}

	getHistory({ informationId }, { page = 0, limit = 25, filter }, cancelToken) {
		return client.get(`/smartview/information/${informationId}/information-history`, {
			params: { page, limit, filter },
			config: { cancelToken },
		});
	}

	searchInDirectoryByDocumentName({ projectId }, { search }, cancelToken) {
		return client.get(`/smartview/projects/${projectId}/document-tabs/directories`, {
			params: { search },
			config: { cancelToken },
		});
	}

	searchInRoleByDocumentName({ projectId }, { search }, cancelToken) {
		return client.get(`/smartview/projects/${projectId}/document-tabs/roles`, {
			params: { search },
			config: { cancelToken },
		});
	}

	searchInPrecedenceByDocumentName({ projectId }, { search }, cancelToken) {
		return client.get(`/smartview/projects/${projectId}/document-tabs/precedences`, {
			params: { search },
			config: { cancelToken },
		});
	}
}
export default new SmartViewService();
