import React, { useRef, useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PdfViewService, SearchCardTemplateService } from "../../../../../api";
import { IconComponent, SearchInput, icon } from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import styles from "./SearchRightPanel.module.css";
import { useApi, useApiDebounced } from "../../../../../common/hooks";
import { SearchResult } from "./components";
import {
	setSelectedSearch as handleSelectedSearch,
	setHoveredBlock,
	setSelectedSortAlgo,
	setSortingAlgorithms,
} from "../../../slice/document/document-slice";
import { setScrollTo } from "../../../slice/pdf/pdf-slice";
import { setDisableKeyEvents } from "../../../../../common/slice";
import Sort from "../../../../../common/components/drop-down/sort-drop-down/Sort";
import { isNonEmptyArray } from "../../../../../common/utils";
import { SCORING_SORT_AVAILABLE } from "../../../../../common/constants";
import { KEY_SELECTED_SORTING_ALGORITHM } from "../../../../../common/providers/keys/keys";

export default function SearchRightPanel() {
	const [search, setSearch] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [results, setResults] = useState(null);
	const [selectedSearch, setSelectedSearch] = useState(null);
	const dispatch = useDispatch();
	const documentId = useSelector(({ srDocument }) => srDocument.documentId);
	const disableKeyEvents = useSelector(({ context }) => context.disableKeyEvents);
	const sortingAlgorithms = useSelector(({ srDocument }) => srDocument.sortingAlgorithms);
	const selectedSortAlgo = useSelector(({ srDocument }) => srDocument.selectedSortAlgo);
	const searchInputRef = useRef();
	const { call: debouncedSearch } = useApiDebounced(PdfViewService.search, 1000);
	const { call: getSortingAlgorithms } = useApi(SearchCardTemplateService.getSortingAlgorithms);
	useEffect(
		() => () => {
			if (disableKeyEvents) {
				dispatch(setDisableKeyEvents(false));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
	useEffect(() => {
		if (SCORING_SORT_AVAILABLE && !isNonEmptyArray(sortingAlgorithms)) {
			getSortingAlgorithms().then((data) => {
				dispatch(setSortingAlgorithms(data));
				if (isNonEmptyArray(data)) {
					const storedAlgo = window.sessionStorage.getItem(KEY_SELECTED_SORTING_ALGORITHM);
					if (storedAlgo && data.includes(storedAlgo)) {
						dispatch(setSelectedSortAlgo(storedAlgo));
					} else if (selectedSortAlgo && !data.includes(selectedSortAlgo)) {
						dispatch(setSelectedSortAlgo(data[0]));
					}
				}
			});
		}
	}, [dispatch, getSortingAlgorithms, selectedSortAlgo, sortingAlgorithms]);
	const handleLoading = (loading) => {
		setIsLoading(loading);
	};
	const handleSearch = useCallback(
		(text, algo) => {
			const params = { documentId };
			if (SCORING_SORT_AVAILABLE) {
				params.sort = algo;
			}
			if (text.length > 0) {
				handleLoading(true);
				const handleResponse = (data) => {
					handleLoading(false);
					setResults(data);
				};
				const handleError = (err) => console.error(err);
				debouncedSearch(handleResponse, handleError, params, text);
			}
			if (text.length === 0) {
				handleLoading(true);
				const handleResponse = () => {
					handleLoading(false);
					setResults(null);
				};
				const handleError = (err) => console.error(err);
				debouncedSearch(handleResponse, handleError, params, "");
			}
		},
		[debouncedSearch, documentId]
	);
	const handleChangeSearch = useCallback(
		(e) => {
			const text = e?.target?.value || "";
			setSearch(text);
			handleSearch(text, selectedSortAlgo);
		},
		[handleSearch, selectedSortAlgo]
	);
	const handleChangeSort = useCallback(
		(algo) => {
			dispatch(setSelectedSortAlgo(algo));
			window.sessionStorage.setItem(KEY_SELECTED_SORTING_ALGORITHM, algo);
			handleSearch(search, algo);
		},
		[dispatch, handleSearch, search]
	);
	const handleClearSearch = () => {
		searchInputRef.current.focus();
		setSearch("");
		setResults(null);
		setSelectedSearch(null);
	};
	const handleClick = (data, index) => {
		setSelectedSearch(index);
		dispatch(setHoveredBlock(null));
		dispatch(handleSelectedSearch({ ...data, fromUrl: true }));
		dispatch(setScrollTo({ page: data.pageStart, position: data.y1 }));
	};
	return (
		<div className={styles.main}>
			<div className={styles.filters__container}>
				<SearchInput
					ref={searchInputRef}
					autoFocus
					fullWidth
					className={styles.filters__search}
					id="search_in_pdf"
					isLoading={isLoading}
					label={translate("smart-review.search-space.search")}
					value={search}
					onBlur={() => dispatch(setDisableKeyEvents(false))}
					onChange={handleChangeSearch}
					onClearSearch={handleClearSearch}
					onFocus={() => dispatch(setDisableKeyEvents(true))}
				/>
			</div>
			{SCORING_SORT_AVAILABLE && (
				<div className={styles.sorter__container}>
					<Sort
						selectedSortAlgo={selectedSortAlgo}
						sortingAlgorithms={sortingAlgorithms}
						onChangeSort={handleChangeSort}
					/>
				</div>
			)}
			<div className={styles.totalResult}>
				{results?.totalResult &&
					translate("smart-review.search-space.search-results", { totalResult: results?.totalResult })}
			</div>
			<div className={styles.result__container}>
				{(!isLoading &&
					Array.isArray(results?.contentsPosition) &&
					results.totalResult > 0 &&
					results?.contentsPosition.map((x, index) => (
						<div
							key={x.infoId}
							role="presentation"
							onClick={() => {
								handleClick(x, index);
							}}
						>
							<SearchResult
								result={x}
								search={search}
								searchNumber={index}
								selectedSearch={selectedSearch}
							/>
						</div>
					))) || (
					<div className={styles.emptyStateContainer}>
						<IconComponent color="var(--color-blue)" icon={icon.faInfoCircle} size="md" />
						{translate("common:empty-state.no-results")}
					</div>
				)}
			</div>
		</div>
	);
}
