import React from "react";
import { connect } from "react-redux";
import { ApiService, ProjectTeamService } from "../../../../api";
import canvasService from "../../../../api/services/canvas-service";
import {
	FavoriteCategoriesDialog,
	ReadingList,
	ViewBanner,
	ViewTabPanel,
	ViewTabs,
	generateFilters,
} from "../../../../common/components";
import {
	AnalyticsProvider,
	Flags,
	isFeatureEnabled,
	isSegFeatureEnabled,
	SegFlags,
	translate,
	translateEnumDocumentUserRole,
} from "../../../../common/providers";
import { removeUnderscore } from "../../../../common/utils";
import styles from "./MyDashboard.module.css";
import { DashboardTable, DetailSidePanel } from "./components";
import { exportView } from "../../../../navigation";
import DashboardContext from "./utils/dashboard-context";
import {
	getCompanyDocuments,
	getDocumentDetails,
	getReviewDocuments,
	setAdditionalFilters,
	setCount,
	setDashboardType,
	setFilters,
	setTabs,
} from "./slice/dashboard-slice";
import { SEPARATOR } from "./utils/utils";

const mapStateToProps = ({ context, dashboard }) => ({
	project: context.project,
	company: context.company,
	userMe: context.user,
	filters: dashboard.filters,
	isLoading: dashboard.isLoadingDocuments,
	isLoadingDetails: dashboard.isLoadingDetails,
	myReviewRows: dashboard.myReviewRows,
	myCompanyRows: dashboard.myCompanyRows,
	tabs: dashboard.tabs,
	additionalFilters: dashboard.additionalFilters,
	errorDetails: dashboard.errorDetails,
	documentDetails: dashboard.documentDetails,
});
const mapDispatchToProps = (dispatch) => ({
	onGetReviewDocuments: ({ displayResult, userId, filters, token }) =>
		dispatch(getReviewDocuments({ displayResult, userId, filters, token })),
	onGetCompanyDocuments: ({ companyId, displayResult, filters, token }) =>
		dispatch(getCompanyDocuments({ companyId, displayResult, filters, token })),
	onSetTabs: (tabs) => dispatch(setTabs(tabs)),
	onSetAdditionalFilters: ({ key, data }) => dispatch(setAdditionalFilters({ key, data })),
	onSetDashboardType: (dashboardType) => dispatch(setDashboardType(dashboardType)),
	onGetDocumentDetails: ({ displayResult, documentId, filters, token }) =>
		dispatch(getDocumentDetails({ displayResult, documentId, filters, token })),
	onSetCount: ({ type, count }) => dispatch(setCount({ type, count })),
	onSetFilters: (filters) => dispatch(setFilters(filters)),
});

function listCompanies(companylist) {
	const finalData = [];
	function recurse(data) {
		if (!Array.isArray(data)) {
			return;
		}
		data.forEach((company) => {
			const { companies, ...rest } = company;
			finalData.push(rest);
			if (Array.isArray(company.companies)) {
				recurse(company.companies);
			}
		});
	}
	recurse(companylist);
	return finalData;
}
function isOnceReviewer(navigate, rol) {
	navigate(`?pt4=${rol}`);
}

class MyDashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			companies: [],
			selectedTab: 0,
			displaySidePanel: false,
			infiniteListRequest: null,
		};
		this.cancelTokenSource = ApiService.getCancelTokenSource();
	}

	componentDidMount() {
		const documentTitle = translate("dashboard.my-progress.document.title");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "Review progress" });
		const {
			project,
			userMe,
			company,
			onSetTabs,
			onSetDashboardType,
			onSetFilters,
			onSetAdditionalFilters,
			navigate,
		} = this.props;
		onSetTabs(
			isSegFeatureEnabled(SegFlags.FAVORITE_CATEGORIES)
				? [
						{
							label: translate("dashboard.my-dashboard.tabs.my-reading-list"),
						},
						{
							type: "user",
							label: translate("dashboard.my-dashboard.tabs.my-review"),
							count: 0,
						},
						{
							type: "company",
							label: translate("dashboard.my-dashboard.tabs.my-validation"),
							count: 0,
						},
				  ]
				: [
						{
							type: "user",
							label: translate("dashboard.my-dashboard.tabs.my-review"),
							count: 0,
						},
						{
							type: "company",
							label: translate("dashboard.my-dashboard.tabs.my-validation"),
							count: 0,
						},
				  ]
		);
		if (project) {
			const promise = new Promise((resolve) => {
				const contextFilters = DashboardContext.getContext();
				resolve(onSetFilters({ ...contextFilters, separator: contextFilters?.separator || SEPARATOR.AND }));
			});
			promise.then(() => {
				this.loadMyReviewDocuments(userMe.id);
				this.loadMyCompanyDocuments(company.id);
			});
			onSetDashboardType(0);
			if (isSegFeatureEnabled(SegFlags.PARTNERS)) {
				this.getCompanies(project.id);
			}
			this.setInfiniteListRequest();
		}
		onSetAdditionalFilters({ key: "user", data: userMe });
		if (isSegFeatureEnabled(SegFlags.RIGHTS_ON_DOCUMENTS)) {
			canvasService
				.getRoleOnDocument({ userId: userMe.id, projectId: project.id }, this.cancelTokenSource.token)
				.then((data) => {
					const hasRole = data.some((userRole) => userRole.role === "REVIEWER");
					isOnceReviewer(navigate, hasRole);
				})
				.catch((err) => console.error(err));
		}
	}

	componentWillUnmount() {
		ApiService.cancelTokens(this.cancelTokenSource);
	}

	componentDidUpdate(prevProps, prevState) {
		const { companies, selectedTab, displaySidePanel } = this.state;
		const { additionalFilters, project, company, onSetAdditionalFilters } = this.props;

		if (selectedTab !== prevState.selectedTab && !prevProps.additionalFilters.company) {
			onSetAdditionalFilters({ key: "company", data: companies.find((c) => c.id === company.id) });
		}
		if (
			(!!prevProps.additionalFilters.user &&
				!!additionalFilters.user &&
				prevProps.additionalFilters.user?.id !== additionalFilters.user?.id) ||
			(!!prevProps.additionalFilters.company &&
				!!additionalFilters.company &&
				prevProps.additionalFilters.company?.id !== additionalFilters.company?.id) ||
			prevState.selectedTab !== selectedTab ||
			(prevState.displaySidePanel !== displaySidePanel && !displaySidePanel)
		) {
			if (
				(!isSegFeatureEnabled(SegFlags.FAVORITE_CATEGORIES) && selectedTab === 0) ||
				(isSegFeatureEnabled(SegFlags.FAVORITE_CATEGORIES) && selectedTab === 1)
			) {
				this.loadMyReviewDocuments(additionalFilters.user.id);
			} else if (
				(!isSegFeatureEnabled(SegFlags.FAVORITE_CATEGORIES) && selectedTab === 1) ||
				(isSegFeatureEnabled(SegFlags.FAVORITE_CATEGORIES) && selectedTab === 2)
			) {
				this.loadMyCompanyDocuments(additionalFilters?.company?.id);
			}
		}
		if (
			displaySidePanel &&
			prevState.displaySidePanel !== displaySidePanel &&
			additionalFilters.selectedDocument.id !== prevProps.additionalFilters?.selectedDocument?.id
		) {
			this.loadDocumentDetails(project.id, additionalFilters.company.id, additionalFilters.selectedDocument.id);
		}
		if ((project !== prevProps.project && project.id) || (company !== prevProps.company && company.id)) {
			this.setInfiniteListRequest();
		}
	}

	setInfiniteListRequest = () => {
		const { project, company } = this.props;
		this.setState({
			infiniteListRequest: ({ limit, page }) =>
				ProjectTeamService.getUsersByCompany(
					{ projectId: project.id, companyId: company.id },
					{ limit, page, status: "ACTIVE" },
					this.cancelTokenSource.token
				),
		});
	};

	setStates = (data) => {
		const { userMe, onSetAdditionalFilters, additionalFilters } = this.props;
		if (!additionalFilters.user.status) {
			onSetAdditionalFilters({ key: "user", data: data.contents.find((u) => u.id === userMe.id) });
		}
	};

	formatDocuments = (documents, isDocument) => {
		const rows = [];
		documents.forEach((doc) => {
			const { document, documentId, total, done, users, role, ...info } = doc;
			rows.push({
				...info,
				name: isDocument ? removeUnderscore(document) : users.displayName,
				id: documentId,
				totalReqs: total,
				allReqs: done,
				role:
					(role && translateEnumDocumentUserRole(role)) || users?.map((u) => u.displayName)?.join(", ") || "",
			});
		});
		return rows;
	};

	loadMyReviewDocuments = (userId) => {
		const { filters, onGetReviewDocuments } = this.props;
		onGetReviewDocuments({
			displayResult: true,
			userId,
			filters: generateFilters(filters),
			token: this.cancelTokenSource.token,
		});
	};

	loadMyCompanyDocuments = (companyId) => {
		const { filters, onGetCompanyDocuments } = this.props;
		onGetCompanyDocuments({
			companyId,
			displayResult: true,
			filters: generateFilters(filters),
			token: this.cancelTokenSource.token,
		});
	};

	loadDocumentDetails = (projectId, companyId, documentId) => {
		const { filters, onGetDocumentDetails } = this.props;
		onGetDocumentDetails({
			displayResult: true,
			documentId,
			filters: generateFilters(filters),
			token: this.cancelTokenSource.token,
		});
	};

	getCompanies = (projectId) => {
		if (isSegFeatureEnabled(SegFlags.TEAM) && isFeatureEnabled(Flags.PARTNERS)) {
			ProjectTeamService.getCompanies({ projectId }, this.cancelTokenSource.token).then((data) =>
				this.setState({ companies: listCompanies(data) })
			);
		}
	};

	handleChangeTab = (event, newValue) => {
		const { onSetAdditionalFilters, onSetDashboardType } = this.props;
		this.setState({
			selectedTab: newValue,
		});
		if (isSegFeatureEnabled(SegFlags.FAVORITE_CATEGORIES)) {
			onSetDashboardType(newValue);
		} else {
			onSetDashboardType(newValue + 1);
		}

		onSetAdditionalFilters({ key: "name", data: "" });
	};

	handlePanelClose = () => {
		const { displaySidePanel } = this.state;
		if (displaySidePanel) {
			this.handleClearNameFilter({ details: true });
		}
		this.setState((prev) => ({ displaySidePanel: !prev.displaySidePanel }));
	};

	handleSelectDetail = (row) => {
		const { onSetAdditionalFilters } = this.props;
		onSetAdditionalFilters({ key: "selectedDocument", data: row });
		this.handlePanelClose();
	};

	handleChangeNameFilter = (e, { details = false }) => {
		const { onSetAdditionalFilters } = this.props;
		onSetAdditionalFilters({ key: details ? "details" : "name", data: e.target.value });
	};

	handleClearNameFilter = ({ details = false }) => {
		const { onSetAdditionalFilters } = this.props;
		onSetAdditionalFilters({ key: details ? "details" : "name", data: "" });
	};

	handleChangeGroupByCompany = (e) => {
		const { onSetAdditionalFilters } = this.props;
		onSetAdditionalFilters({ key: "company", data: e.target.value });
	};

	handleChangeGroupByUser = (user) => {
		const { onSetAdditionalFilters } = this.props;
		onSetAdditionalFilters({ key: "user", data: user });
	};

	getTabCount = (tab) => {
		if (tab?.count === undefined) {
			return "";
		}
		if (tab?.count?.documents) {
			return `(${tab?.count?.documents})`;
		}
		return "(0)";
	};

	render() {
		const { selectedTab, displaySidePanel, companies, infiniteListRequest } = this.state;
		const {
			loading,
			errorDetails,
			additionalFilters,
			myCompanyRows,
			myReviewRows,
			project,
			tabs,
			isLoadingDetails,
			documentDetails,
		} = this.props;
		return (
			<>
				<ViewBanner
					titles={[
						{ title: translate("navigation:project.dashboard"), key: "dashboard" },
						{ title: translate("navigation:project.my-progress") },
					]}
				/>
				<ViewTabs
					className={styles.stickyTabs}
					selectedTab={selectedTab}
					tabs={tabs.map((tab) => ({
						label: `${tab.label} ${this.getTabCount(tab)}`,
					}))}
					onChange={this.handleChangeTab}
				/>
				{isSegFeatureEnabled(SegFlags.FAVORITE_CATEGORIES) && (
					<ViewTabPanel noPadding className={styles.tabs__myReadfingList} index={0} value={selectedTab}>
						<ReadingList />
					</ViewTabPanel>
				)}
				<ViewTabPanel noPadding index={tabs.length - 2} value={selectedTab}>
					<DashboardTable
						filters={additionalFilters}
						infiniteListRequest={infiniteListRequest}
						loading={loading}
						projectId={project.id}
						rows={myReviewRows}
						setExternalStates={this.setStates}
						onChangeGroupByUser={this.handleChangeGroupByUser}
						onChangeNameFilter={this.handleChangeNameFilter}
						onClearNameFilter={this.handleClearNameFilter}
					/>
				</ViewTabPanel>
				<ViewTabPanel noPadding index={tabs.length - 1} value={selectedTab}>
					<DashboardTable
						companies={companies}
						filters={additionalFilters}
						loading={loading}
						projectId={project.id}
						rows={myCompanyRows}
						onChangeGroupByCompany={this.handleChangeGroupByCompany}
						onChangeNameFilter={this.handleChangeNameFilter}
						onClearNameFilter={this.handleClearNameFilter}
						onSelectDetail={this.handleSelectDetail}
					/>
				</ViewTabPanel>
				<DetailSidePanel
					company={additionalFilters.company?.name}
					companyId={additionalFilters.company?.id}
					displaySidePanel={displaySidePanel}
					documentId={additionalFilters?.selectedDocument?.id}
					documentName={additionalFilters?.selectedDocument?.name}
					errorLoading={errorDetails}
					loading={isLoadingDetails}
					rows={documentDetails}
					onChangeNameFilter={this.handleChangeNameFilter}
					onClearNameFilter={this.handleClearNameFilter}
					onPanelClose={this.handlePanelClose}
				/>
				{isSegFeatureEnabled(SegFlags.FAVORITE_CATEGORIES) && <FavoriteCategoriesDialog />}
			</>
		);
	}
}
export { default as dashboardSlice } from "./slice/dashboard-slice";
export default exportView({
	path: "/projects/:projectId/dashboard/review-progress",
	localesPath: "/dashboards/dashboard-project/locales",
	component: connect(mapStateToProps, mapDispatchToProps)(MyDashboard),
	segFlag: SegFlags.REVIEW_PROGRESS_DASHBOARD,
});
