import { Accordion, AccordionDetails, AccordionSummary, Checkbox } from "@mui/material";
import React, { useMemo, useState } from "react";
import styles from "./ExpandableDocumentRow.module.css";
import { CustomTooltip } from "../../../../../tooltip";
import { CircularLoader, icon, IconComponent } from "../../../../..";
import { isNonEmptyArray } from "../../../../../../utils";
import ExpandableDocumentList from "../expandable-document-list/ExpandableDocumentList";
import { translate } from "../../../../../../providers";

let allowExpand = true;

const getDocCountRecursively = (folders, counter = 0) => {
	if (!isNonEmptyArray(folders)) {
		return null;
	}
	let temp = counter;
	temp = folders.filter((d) => !isNonEmptyArray(d.documents))?.length || 0;
	folders.forEach((folder) => {
		temp += getDocCountRecursively(folder.documents, temp);
	});

	return temp;
};

const ExpandableDocumentRow = ({
	document,
	indentLevel = 0,
	parentCounters = [],
	mode,
	onCheck,
	onToggleExpand,
	readOnly,
	selectedIds = [],
}) => {
	const [isLoading, setIsLoading] = useState(false);
	// Gets count of all subtree documents(only)
	const childrenCount = useMemo(() => {
		if (document.childrenCount >= 0) {
			return document.childrenCount;
		}
		if (document.count >= 0) {
			return document.count;
		}
		if (isNonEmptyArray(document.documents)) {
			if (mode === "folder") {
				return getDocCountRecursively(document.documents);
			}

			return document.documents?.filter((d) => d.type !== "FOLDER")?.length;
		}
		return undefined;
	}, [document, mode]);
	// Displayed counter of inner selected elements
	const selectionCounters = useMemo(() => {
		if (document.type === "FOLDER") {
			const count = parentCounters.find((parent) => parent.parentId === document.id)?.counter || 0;
			return count > childrenCount ? childrenCount : count;
		}
		if (document.role) {
			const count = parentCounters.find((parent) => parent.parentName === document.role)?.counter || 0;
			return count > childrenCount ? childrenCount : count;
		}
		if (document.precedenceId) {
			const count = parentCounters.find((parent) => parent.parentId === document.precedenceId)?.counter || 0;
			return count > childrenCount ? childrenCount : count;
		}
		return null;
	}, [document, parentCounters, childrenCount]);
	// Checks if folder/role/precedence is partially selected
	const isIndeterminate = useMemo(
		() => selectionCounters > 0 && selectionCounters < childrenCount,
		[selectionCounters, childrenCount]
	);
	// checks if folder/role/precedence is selected
	const isChecked = useMemo(() => {
		if (childrenCount > 0) {
			return selectionCounters >= childrenCount;
		}
		return selectedIds?.includes(document.id);
	}, [document, selectionCounters, selectedIds, childrenCount]);
	// Checks if folder/role/precedence can be expanded
	const isExpandable = useMemo(
		() => childrenCount >= 0 || isNonEmptyArray(document.documents),
		[childrenCount, document]
	);

	const handleExpand = () => {
		if (!isExpandable || childrenCount === 0) {
			return;
		}
		setTimeout(() => {
			if (allowExpand) {
				setIsLoading(true);
				onToggleExpand(document, setIsLoading);
			} else {
				allowExpand = true;
			}
		}, 0);
	};
	const handleCheck = (e) => {
		if (!(childrenCount === undefined || childrenCount > 0)) {
			return;
		}
		if (typeof e.stopPropagation === "function") {
			e.stopPropagation();
		}
		onCheck({ document, isChecked: isChecked || isIndeterminate, hasChildren: isExpandable });
	};
	return (
		<Accordion
			classes={{
				root: styles.accordion__root,
			}}
			expanded={!!document.expanded || document.filtered !== undefined}
			style={{
				marginLeft: indentLevel > 0 ? 32 : 0,
				width: `calc(100% - ${32}px)`,
			}}
			onChange={handleExpand}
		>
			<CustomTooltip
				className={styles.emptyTooltip}
				placement="bottom-start"
				title={childrenCount === 0 ? translate("common:filters.document-tree.empty-folder") : ""}
			>
				<AccordionSummary
					classes={{
						root: styles.accordionSummary__root,
						content: `${styles.accordionSummary__content} ${
							childrenCount === 0 ? styles["accordionSummary__content--disabled"] : ""
						}`,
					}}
					style={{
						cursor: "pointer",
					}}
				>
					{isExpandable && (
						<>
							{(isLoading && <CircularLoader className={styles.row__loader} size={18} />) || (
								<IconComponent
									className={styles.expandIcon}
									color="var(--shades-grey-600)"
									data-testid="docTreeExpand"
									icon={
										((document.expanded || document.filtered !== undefined) &&
											icon.faChevronDown) ||
										icon.faChevronRight
									}
									size="lg"
								/>
							)}
						</>
					)}
					<div className={styles.row__text} role="presentation" onClick={handleCheck}>
						<Checkbox
							checked={isChecked}
							className={styles.checkBox}
							disabled={childrenCount === 0 || readOnly}
							indeterminate={isIndeterminate}
							size="small"
						/>
						{(isExpandable && (
							<CustomTooltip
								showWhenOverflow
								title={
									<>
										{document.name || document.label || document.precedence || document.role} (
										{selectionCounters}/{childrenCount})
									</>
								}
							>
								<span
									className={`${styles.documentName} ${styles["documentName--bold"]} ${
										(document.filtered === false || document.prevFilteredState === false) &&
										styles["documentName--greyed"]
									}`}
								>
									{document.name || document.label || document.precedence || document.role} (
									{selectionCounters}/{childrenCount})
								</span>
							</CustomTooltip>
						)) || (
							<CustomTooltip
								showWhenOverflow
								title={document.name || document.label || document.precedence || document.role}
							>
								<span className={styles.documentName}>
									{document.name || document.label || document.precedence || document.role}
								</span>
							</CustomTooltip>
						)}
					</div>
				</AccordionSummary>
			</CustomTooltip>
			<AccordionDetails
				classes={{
					root: styles.accordionDetails__root,
				}}
			>
				{isNonEmptyArray(document.documents) && (
					<ExpandableDocumentList
						documents={document.documents}
						indentLevel={indentLevel + 1}
						mode={mode}
						parentCounters={parentCounters}
						selectedIds={selectedIds}
						onCheck={onCheck}
						onToggleExpand={onToggleExpand}
					/>
				)}
			</AccordionDetails>
		</Accordion>
	);
};

export default ExpandableDocumentRow;
