import React from "react";
import styles from "./ViewTabPanel.module.css";
import { getFilteredDataAttributes } from "../../../utils";

export default function ViewTabPanel({
	children,
	className = "",
	index,
	isComments = false,
	noPadding = false,
	value,
	...dataAttributes
}) {
	const classNames = `${styles.tabPanel || ""} ${className} ${(noPadding && styles["tabPanel--no-padding"]) || ""} ${
		(isComments && styles["tabPanel--is-comments"]) || ""
	} `.trim();
	const filteredDataAttributes = getFilteredDataAttributes(dataAttributes);
	return (
		<div className={classNames} hidden={value !== index} {...filteredDataAttributes}>
			{value === index && children}
		</div>
	);
}
