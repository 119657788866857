import React from "react";
import { useSelector } from "react-redux";
import { translate } from "../../../../../common/providers";
import CustomMagicDropDown from "../../../../../common/components/ChatDropDown/CustomMagicDropDown";

export default function ReviewRedirectionCategory() {
	const currentCategory = useSelector(({ gonogo }) => gonogo.currentCategory);
	const questions = [
		{
			title: translate("gonogo.content.summarizes-extracts-keys.title"),
			text: translate("gonogo.content.summarizes-extracts-keys.question"),
			summary: translate("gonogo.content.summarizes", { currentCatName: currentCategory?.name }),
		},
		{
			title: translate("gonogo.content.identify-contradictions.title"),
			text: translate("gonogo.content.identify-contradictions.question"),
			summary: translate("gonogo.content.identify-contradiction", { currentCatName: currentCategory?.name }),
		},
		{ title: translate("gonogo.content.ask-another-question"), text: "" },
	];
	const handleGetRedirectToChatFilters = (value, summary) => ({
		state: {
			filters: {
				filter: {
					categories: {
						matchAll: false,
						is: true,
						CATEGORY: [currentCategory.id],
						FAVORITE: [],
					},
					separator: "AND",
					Type: "QuickAccessSearchFilterDTO",
				},
				value,
				summary,
			},
		},
	});

	return <CustomMagicDropDown questions={questions} onGetRedirectToChatFilters={handleGetRedirectToChatFilters} />;
}
