import { Backdrop, CircularProgress, Step, StepLabel, Stepper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { exportView } from "../../navigation/utils/utils";
import { AddCategoriesStep, AddMembersStep, DetailsAndDocumentsStep, SummaryStep } from "./steps";
import styles from "./NewProject.module.css";
import {
	createNotification,
	CustomButton,
	CustomDialog,
	HorizontalDivider,
	icon,
	ViewBanner,
} from "../../common/components";
import { setCurrentStep, setProjectCreation, setIsModified, setCurrentUser } from "./slice/newProjectSlice";
import { ApiService, ProjectCreationService } from "../../api";
import { AnalyticsProvider, environmentSwitch, Flags, Permissions, translate } from "../../common/providers";
import { NavUtils } from "../../navigation";

const mapStateToProps = (state) => ({
	currentStep: state.newProject.currentStep,
	projectCreation: state.newProject.projectCreation,
	isModified: state.newProject.isModified,
	isUploading: state.newProject.isUploading,
	user: state.context.user,
	docsFailed: state.newProject.docsFailed,
	checkedCategoriesIds: state.newProject.checkedCategoriesIds,
	packages: state.newProject.packages,
});

const mapDispatchToProps = (dispatch) => ({
	onChangeCurrentStep: (token) => dispatch(setCurrentStep(token)),
	onChangeProject: (token) => dispatch(setProjectCreation(token)),
	onChangeIsModified: (token) => dispatch(setIsModified(token)),
	onChangeCurrentUser: (token) => dispatch(setCurrentUser(token)),
});

function getStepsNames() {
	return [
		translate("new-project.step.details-and-documents"),
		translate("new-project.step.categories"),
		translate("new-project.step.members"),
		translate("new-project.step.summary"),
	];
}

function getStepContent({ stepIndex, onAddCheckedCategories }) {
	switch (stepIndex) {
		case 0:
			return <DetailsAndDocumentsStep onAddCheckedCategories={onAddCheckedCategories} />;
		case 1:
			return <AddCategoriesStep />;
		case 2:
			return <AddMembersStep />;
		case 3:
			return <SummaryStep />;
		default:
			return <DetailsAndDocumentsStep />;
	}
}

class NewProject extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			stepsNames: getStepsNames(),
			openConfirmationDialog: false,
			openCancellationDialog: false,
			openUploadingDialog: false,
			validationLoading: false,
			openDocsFailedDialog: false,
		};
		this.cancelTokenSource = ApiService.getCancelTokenSource();
	}

	componentDidMount() {
		const { onChangeCurrentUser, user } = this.props;
		const documentTitle = translate("new-project.document.title");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "New project" });
		this.getProject();
		onChangeCurrentUser({ email: user.email, role: "LEADER" });
	}

	componentWillUnmount() {
		ApiService.cancelTokens(this.cancelTokenSource);
	}

	getProject = () => {
		const { onChangeProject, packages } = this.props;
		ProjectCreationService.getOngoingProject(this.cancelTokenSource.token)
			.then((data) => {
				if (data) {
					onChangeProject(
						(data?.featPackage && {
							...data,
							project: {
								...data.project,
								package: packages.find((pack) => pack.name === data.featPackage),
							},
						}) ||
							data
					);
				} else {
					this.createProject();
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	handleCloseConfirmationDialog = () => {
		const { createdProjectId } = this.state;
		this.setState({
			openConfirmationDialog: false,
		});
		window.location.href = NavUtils.goToDocumentCenter(createdProjectId);
	};

	handleCloseCancellationDialog = () => {
		this.setState({
			openCancellationDialog: false,
		});
	};

	handleCloseUploadingDialog = () => {
		this.setState({
			openUploadingDialog: false,
		});
	};

	handleCloseDocsFailedDialog = () => {
		this.setState({
			openDocsFailedDialog: false,
		});
	};

	createProject = () => {
		const { onChangeProject } = this.props;
		ProjectCreationService.createProject(this.cancelTokenSource.token)
			.then((data) => {
				onChangeProject({ project: data });
			})
			.catch((err) => console.error(err));
	};

	updateProjectAndContinue = (ignoreFails = false) => {
		const { isModified, docsFailed, onChangeIsModified, onChangeCurrentStep, currentStep } = this.props;
		if (docsFailed && !ignoreFails) {
			this.setState({
				openDocsFailedDialog: true,
			});
		} else if (isModified) {
			const { projectCreation } = this.props;
			ProjectCreationService.updateProjectDetails(
				{ projectId: projectCreation.project.id },
				{
					name: projectCreation.project.name,
					type: projectCreation.project.type,
					packageId: projectCreation.project.package.id,
					reqDetection: projectCreation.project.reqDetection,
				},
				this.cancelTokenSource.token
			)
				.then(() => {
					onChangeIsModified(false);
					onChangeCurrentStep(currentStep + 1);
				})
				.catch((err) => {
					console.error(err);
				});
		} else {
			onChangeCurrentStep(currentStep + 1);
		}
	};

	addCheckedCategories = () => {
		const { projectCreation, checkedCategoriesIds } = this.props;
		ProjectCreationService.addCategories(
			{ projectId: projectCreation.project.id },
			{ ids: checkedCategoriesIds },
			this.cancelTokenSource.token
		)
			.then()
			.catch((err) => console.error(err));
	};

	handleNext = () => {
		const { currentStep, docsFailed, onChangeCurrentStep, projectCreation, isUploading } = this.props;
		switch (currentStep) {
			case 0:
				if (isUploading) {
					this.setState({
						openUploadingDialog: true,
					});
				} else if (docsFailed) {
					this.setState({
						openDocsFailedDialog: true,
					});
				} else {
					this.updateProjectAndContinue(projectCreation.project.id);
				}
				break;
			case 1:
				this.addCheckedCategories();
				onChangeCurrentStep(currentStep + 1);
				break;
			case 3:
				this.validateProject();
				break;
			default:
				onChangeCurrentStep(currentStep + 1);
				break;
		}
	};

	validateProject = () => {
		const { projectCreation } = this.props;
		this.setState({ validationLoading: true });
		ProjectCreationService.validateProject({ projectId: projectCreation.project.id }, this.cancelTokenSource.token)
			.then((data) => {
				this.setState({
					openConfirmationDialog: true,
					createdProjectId: data.id,
					validationLoading: false,
				});
			})
			.catch((err) => {
				this.setState({ validationLoading: false });
				console.error(err);
			});
	};

	handleBack = () => {
		const { onChangeCurrentStep, currentStep } = this.props;
		onChangeCurrentStep(currentStep - 1);
	};

	handleReset = () => {
		const { onChangeCurrentStep } = this.props;
		onChangeCurrentStep(0);
	};

	handleCancelDialog = () => {
		const { projectCreation, onChangeProject } = this.props;
		this.handleCloseCancellationDialog();
		this.handleReset();
		const projectId = projectCreation?.project?.id;
		if (!projectId) {
			return;
		}
		ProjectCreationService.abortProjectCreation({ projectId }, this.cancelTokenSource.token)
			.then(() => {
				onChangeProject({});
				createNotification({
					type: "success",
					message: translate("new-project.success-message.project-abort"),
				});
				window.location.href = NavUtils.goToHome();
			})
			.catch((err) => {
				console.error(err);
			});
	};

	handleOpenCancellationDialog = () => {
		this.setState({
			openCancellationDialog: true,
		});
	};

	isContinueButtonDisabled = () => {
		const { currentStep, projectCreation } = this.props;
		return (
			currentStep === 0 &&
			(!projectCreation.project?.name ||
				!projectCreation.project?.type ||
				!(projectCreation.project?.package?.id || projectCreation?.featPackage))
		);
	};

	handleConfirmUploadingDialog = () => {
		this.handleCloseUploadingDialog();
		this.updateProjectAndContinue();
	};

	handleConfirmDocsFailedDialog = () => {
		this.handleCloseDocsFailedDialog();
		this.updateProjectAndContinue(true);
	};

	render() {
		const {
			stepsNames,
			openConfirmationDialog,
			openCancellationDialog,
			validationLoading,
			openUploadingDialog,
			openDocsFailedDialog,
		} = this.state;
		const { currentStep, projectCreation } = this.props;
		return (
			<>
				<ViewBanner titles={[{ title: translate("new-project.document.banner") }]} />
				<Backdrop className={styles.backdrop} open={validationLoading}>
					<div className={styles.container__backdrop}>
						<CircularProgress color="inherit" />
						{translate("new-project.validation-in-progress")}
					</div>
				</Backdrop>
				<div className={styles["main-container"]}>
					<Stepper alternativeLabel activeStep={currentStep}>
						{stepsNames.map((label) => (
							<Step key={label}>
								<StepLabel classes={{ label: styles.stepLabel }}>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
					<div className={styles.content}>
						{getStepContent({ stepIndex: currentStep, onAddCheckedCategories: this.addCheckedCategories })}
					</div>
					<HorizontalDivider />
					<div className={styles.buttons__container}>
						<CustomButton
							color="primary"
							data-testid="new-project.previousStep"
							disabled={currentStep === 0}
							variant="outlined"
							onClick={this.handleBack}
						>
							{translate("common:btn.back")}
						</CustomButton>
						<div className={styles["buttons__container--right"]}>
							<CustomButton
								color="secondary"
								disabled={!projectCreation?.project?.id}
								variant="outlined"
								onClick={this.handleOpenCancellationDialog}
							>
								{translate("common:btn.cancel")}
							</CustomButton>
							<CustomButton
								color="primary"
								data-testid="new-project.nextStep"
								disabled={this.isContinueButtonDisabled()}
								variant="contained"
								onClick={this.handleNext}
							>
								{currentStep === stepsNames.length - 1
									? translate("new-project.button.validate-project")
									: translate("new-project.button.continue")}
							</CustomButton>
						</div>
					</div>
				</div>
				<CustomDialog
					iconColor="var(--shades-grey-v2-30)"
					iconModel={icon.faCheckCircle}
					open={openConfirmationDialog}
					submitLabel={translate("new-project.confirmation-dialog.button-text")}
					subTitle={translate("new-project.confirmation-dialog.content")}
					title={translate("new-project.confirmation-dialog.title")}
					onSubmit={this.handleCloseConfirmationDialog}
				/>
				<CustomDialog
					closeLabel={translate("new-project.cancellation-dialog.button.continue-edition")}
					iconColor="var(--color-red)"
					iconModel={icon.faExclamationTriangle}
					open={openCancellationDialog}
					submitLabel={translate("new-project.cancellation-dialog.button.abort")}
					subTitle={translate("new-project.cancellation-dialog.content")}
					title={translate("new-project.cancellation-dialog.title")}
					onClose={this.handleCloseCancellationDialog}
					onSubmit={this.handleCancelDialog}
				/>
				<CustomDialog
					closeLabel={translate("new-project.uploading-dialog.button.go-to-next-step")}
					iconColor="var(--color-red)"
					iconModel={icon.faExclamationTriangle}
					open={openUploadingDialog}
					submitLabel={translate("new-project.uploading-dialog.button.stay")}
					subTitle={translate("new-project.uploading-dialog.content")}
					title={translate("new-project.uploading-dialog.title")}
					onClose={this.handleConfirmUploadingDialog}
					onSubmit={this.handleCloseUploadingDialog}
				/>
				<CustomDialog
					iconColor="var(--color-red)"
					iconModel={icon.faExclamationTriangle}
					open={openDocsFailedDialog}
					submitLabel={translate("new-project.uploading-dialog.button.go-to-next-step")}
					subTitle={translate("new-project.docs-failed-dialog.content")}
					title={translate("new-project.docs-failed-dialog.title")}
					onClose={this.handleCloseDocsFailedDialog}
					onSubmit={this.handleConfirmDocsFailedDialog}
				/>
			</>
		);
	}
}

export { default as newProjectSlice } from "./slice/newProjectSlice";
export default exportView({
	path: "/new-project",
	localesPath: "/new-project/locales",
	component: connect(mapStateToProps, mapDispatchToProps)(NewProject),
	role: environmentSwitch({
		veolia: [Permissions.COMPANY_ADMIN, Permissions.COMPANY_MANAGER],
		main: [Permissions.COMPANY_ADMIN, Permissions.COMPANY_MANAGER],
		smac: [],
	}),
	flag: Flags.PROJECT_CREATION_ONBOARDING,
});
