import React, { useEffect, useState } from "react";
import styles from "./Resizer.module.css";
import { VerticalDivider } from "../dividers";
import { getFilteredDataAttributes } from "../../utils";

const Resizer = ({
	active = true,
	additionalPixels = 0,
	className = "",
	idAddition = "",
	isOnLeft = false,
	isGlobalWidth = false,
	onResize = null,
	max = 40,
	min = 20,
	style = {},
	...dataAttributes
}) => {
	// Position and width
	let x = 0;
	let previousWidth = 0;
	const [previousSide, setPreviousSide] = useState(null);
	const [resize, setResize] = useState(null);
	const filteredDataAttributes = getFilteredDataAttributes(dataAttributes);
	useEffect(() => {
		if (active && !resize) {
			setResize(document.getElementById(`resizable${idAddition}`));
		}
		if (active && resize && !previousSide) {
			setPreviousSide(isOnLeft ? resize?.nextElementSibling : resize?.previousElementSibling);
		}
		if (!active && resize) {
			setResize(null);
			setPreviousSide(null);
		}
	}, [active, resize, previousSide, idAddition, isOnLeft]);

	useEffect(() => {
		// For resizing after a closeable panel
		if (!active && previousSide?.style) {
			previousSide.style.width = "fit-content";
		}
	}, [previousSide?.style, active]);

	const getAdjustedWidth = (w) => {
		if (w > max) {
			return max;
		}
		if (w < min) {
			return min;
		}
		return w;
	};

	const mouseMoveHandler = (e) => {
		// Size modification
		const dx = e.clientX - x;
		let w = 0;
		if (isOnLeft) {
			if (isGlobalWidth) {
				w = ((previousWidth - dx) * 100) / window.innerWidth;
			} else {
				w = ((previousWidth - dx) * 100) / resize.parentNode.getBoundingClientRect().width;
			}
		} else if (isGlobalWidth) {
			w = ((previousWidth + dx) * 100) / window.innerWidth;
		} else {
			w = ((previousWidth + dx) * 100) / resize.parentNode.getBoundingClientRect().width;
		}
		const adjustedWidth = getAdjustedWidth(w);
		previousSide.style.width = isGlobalWidth
			? `calc(${adjustedWidth}vw + ${additionalPixels}px)`
			: `calc(${adjustedWidth}% + ${additionalPixels}px)`;
		document.body.style.cursor = "col-resize";
		document.body.style.userSelect = "none";
		document.body.style.pointerEvents = "none";
		if (typeof onResize === "function") {
			onResize(adjustedWidth);
		}
	};

	const mouseUpHandler = () => {
		// Remove the handlers and style
		resize.style.removeProperty("cursor");
		document.body.style.removeProperty("cursor");
		document.body.style.removeProperty("user-select");
		document.body.style.removeProperty("pointer-events");
		document.removeEventListener("mousemove", mouseMoveHandler);
		document.removeEventListener("mouseup", mouseUpHandler);
	};

	const mouseDownHandler = (e) => {
		// Get the current mouse position
		x = e.clientX;
		previousWidth = previousSide?.getBoundingClientRect().width;
		// Attach the listeners to `document`
		document.addEventListener("mousemove", mouseMoveHandler);
		document.addEventListener("mouseup", mouseUpHandler);
	};

	useEffect(() => {
		if (active && resize && previousSide) {
			resize?.addEventListener("mousedown", mouseDownHandler);
		}
	});
	return (
		<>
			{active ? (
				<div
					className={`${styles.resizer__horizontal} ${className}`}
					id={`resizable${idAddition}`}
					style={style}
					{...filteredDataAttributes}
				/>
			) : (
				<VerticalDivider />
			)}
		</>
	);
};
export default Resizer;
