import { Checkbox } from "@mui/material";
import { useSelector } from "react-redux";
import React, { useState } from "react";
import {
	CategoryRowRiskLevel,
	CustomIconButton,
	CustomTooltip,
	Hierarchy,
	icon,
	IconComponent,
	VerticalDivider,
} from "../..";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../providers";
import { dangerousSetInnerHTML, isNonEmptyArray } from "../../../utils";
import styles from "./SearchRow.module.css";
import { SENTENCE_TYPE } from "../../../../views/my-review/constants/constants";
import { useApi } from "../../../hooks";
import { DocumentService, ProjectDirectoryService } from "../../../../api";
import { NavUtils } from "../../../../navigation";

const DISPLAY_SCORE = false;
export default function SearchRow({
	categories,
	content,
	documentId,
	documentName,
	hasFolderIcon,
	informationId,
	isCheckboxDisplayed,
	isChecked,
	isTemplate,
	location,
	onCheck,
	onDisplay,
	page,
	row,
	score,
	informationType,
}) {
	const [hierarchy, setHierarchy] = useState("");
	const [folderId, setFolderId] = useState("");
	const { call: getDocumentDetails } = useApi(DocumentService.getInformation);
	const { call: getLocation } = useApi(ProjectDirectoryService.getLocation);
	const projectId = useSelector(({ context }) => context?.project?.id);
	const handleClickDisplay = () => {
		onDisplay(informationId, documentId, page, informationType, row?.rectangleDTO || row?.coordinates);
	};
	const handleRetrieveHierarchy = () => {
		if (!hierarchy) {
			getDocumentDetails({ docId: documentId })
				.then(({ directoryId }) => {
					const dirId = (!directoryId && "root") || directoryId;
					setFolderId(dirId);
					getLocation({ projectId, directoryId: dirId })
						.then((data) => {
							if (isNonEmptyArray(data)) {
								const constructedHierarchy = data.map(({ label }) => `${label}/`);
								setHierarchy(`/${constructedHierarchy}${documentName}`);
							} else {
								setHierarchy("/");
							}
						})
						.catch(console.error);
				})
				.catch(console.error);
		}
	};
	const handleOpenDocumentCenter = () => {
		if (folderId) {
			window.open(NavUtils.goToDocumentCenter(projectId, folderId));
		}
	};
	return (
		<div className={styles.row}>
			<div className={styles.row__information}>
				<div className={styles["row__information--leftSide"]}>
					{isCheckboxDisplayed && (
						<Checkbox
							checked={isChecked}
							color="primary"
							data-testid={`check_information_row_${informationId}`}
							size="medium"
							onChange={(e) => {
								onCheck(e.target.checked, row);
							}}
						/>
					)}
					<span className={styles.row__title}>
						{informationType === SENTENCE_TYPE.INFORMATION ||
						(!isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS) && !isTemplate) ? (
							<>
								<IconComponent
									className={styles.infoIcon}
									color="var(--color-light-grey-1)"
									icon={icon.faStream}
								/>
								{`INFO_${informationId}`}
							</>
						) : (
							<>
								<IconComponent
									className={styles.requiredIcon}
									color="var(--color-red)"
									icon={icon.faAsterisk}
								/>
								{`REQ_${informationId}`}
							</>
						)}
					</span>
					<VerticalDivider className={styles.divider} />
					{hasFolderIcon && (
						<CustomTooltip title={hierarchy}>
							<CustomIconButton
								data-size="xs"
								icon={icon.faFolderTree}
								size="xs"
								variant="outlined"
								onClick={handleOpenDocumentCenter}
								onMouseOver={handleRetrieveHierarchy}
							/>
						</CustomTooltip>
					)}
					<Hierarchy
						enableFirstLocationCut
						isDangerousSetInnerHTML
						className={styles.breadcrumbContainer}
						documentName={documentName}
						hierarchy={location}
					/>
				</div>
				<div className={styles["row__information--actions"]}>
					{DISPLAY_SCORE && (
						<span className={styles["row__information--score"]}>
							{(score === 0 || score) && `${score}%`}
						</span>
					)}
					<CustomIconButton
						data-testid={`file.icon.btn_${informationId}`}
						icon={icon.faFile}
						size="sm"
						tooltip={translate("common:btn.display")}
						variant="outlined"
						onClick={handleClickDisplay}
					/>
				</div>
			</div>
			{isNonEmptyArray(categories) && (
				<div className={styles.row__attributes}>
					<CategoryRowRiskLevel categories={categories} containerId={informationId} />
				</div>
			)}
			<p className={styles.row__description}>
				{content ? (
					dangerousSetInnerHTML({ text: content })
				) : (
					<>{translate("common:searchcard.image-matched")}</>
				)}
			</p>
		</div>
	);
}
