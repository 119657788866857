import React, { useState, useEffect, useRef } from "react";
import { InputAdornment, MenuItem } from "@mui/material";
import { ApiService, CategoryService, CategoryTemplateService, MyService } from "../../../api";
import {
	OptionsPanelContainer,
	SidePanel,
	SidePanelContent,
	CategoryTemplateSelectionList,
	CategorySelectionList,
	InfiniteListSelector,
	IconComponent,
	icon,
} from "../../../common/components";
import { Permissions, translate } from "../../../common/providers";
import styles from "./ImportTransferCategorySidepanel.module.css";

export default function ImportTransferCategorySidePanel({ importing = false, open, onClose, onSubmit, title }) {
	const [checkedIds, setCheckedIds] = useState([]);
	const [project, setProject] = useState(null);
	const cancelTokenSourceRef = useRef(null);
	const [projectRequest, setProjectRequest] = useState(null);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	useEffect(() => {
		setProjectRequest(
			() =>
				({ page }) =>
					MyService.getProjects(cancelTokenSourceRef.current.token, { page })
		);
	}, []);

	const handleClickAdd = () => {
		if (!importing) {
			CategoryTemplateService.importIntoProject(
				{ projectId: project.projectId },
				{ ids: checkedIds },
				cancelTokenSourceRef.current.token
			)
				.then(() => {
					onSubmit();
				})
				.catch(console.error);
		} else {
			CategoryService.transferProjectThematicSelection(
				{ thematicList: checkedIds },
				cancelTokenSourceRef.current.token
			)
				.then(() => {
					onSubmit();
				})
				.catch(console.error);
		}
	};

	const handleClose = () => {
		setCheckedIds([]);
		setProject(null);
		onClose();
	};
	const handleChangeProject = (pr) => {
		if (importing) {
			setCheckedIds([]);
		}
		setProject(pr);
	};
	const selectorRowRenderer = ({ loadedRow, onClick }) =>
		(loadedRow.roles || []).includes(Permissions.PROJECT_LEADER.role) && (
			<MenuItem key={loadedRow?.id} onClick={() => onClick(loadedRow)}>
				<span>{loadedRow?.projectName}</span>
			</MenuItem>
		);
	const endAdornmentRenderer = (openProjects) => (
		<InputAdornment position="end">
			<IconComponent color="var(--color-dark-grey-1)" icon={openProjects ? icon.faCaretUp : icon.faCaretDown} />
		</InputAdornment>
	);
	return (
		<SidePanel open={open} size={50} onClose={handleClose}>
			<SidePanelContent title={title} onClose={handleClose}>
				<OptionsPanelContainer
					buttonText={translate(
						checkedIds.length > 1
							? "common:categories.import-panel.add-categories-btn"
							: "common:categories.import-panel.add-categorie-btn",
						{ categoriesCount: checkedIds.length }
					)}
					className={styles.panelContainer}
					disabledPrimaryButton={checkedIds.length === 0 || !project?.projectId}
					onClick={handleClickAdd}
				>
					<div className={styles.title}>
						{translate("category-template.import-transfer.side-panel.project-select")}
					</div>
					<InfiniteListSelector
						classes={{ input: styles.infiniteSelector }}
						classNameInfiniteList={styles.infiniteSelector__list}
						endAdornmentRenderer={endAdornmentRenderer}
						infiniteListRequest={projectRequest}
						rowRenderer={selectorRowRenderer}
						value={
							project?.projectName ||
							translate("category-template.import-transfer.side-panel.placeholder")
						}
						onClick={handleChangeProject}
					/>
					<div className={styles.categorySelection}>
						{(!importing || project?.projectId) && (
							<>
								<div className={styles.title}>
									{translate(
										(importing &&
											"category-template.import-transfer.side-panel.import-description") ||
											"category-template.import-transfer.side-panel.transfer-description"
									)}
								</div>
							</>
						)}
						{(!importing && (
							<CategoryTemplateSelectionList
								allowDefaultChanges
								allowPreExpand
								checkChildren
								canSelectAll={!!project?.projectId}
								projectId={project?.projectId}
								onChangeCheckedIds={setCheckedIds}
							/>
						)) ||
							(project?.projectId && (
								<CategorySelectionList
									allowDefaultChanges
									canSelectAll
									checkChildren
									isEditable
									defaultCheckedIds={checkedIds}
									projectId={project?.projectId}
									onChangeCheckedIds={setCheckedIds}
								/>
							))}
					</div>
				</OptionsPanelContainer>
			</SidePanelContent>
		</SidePanel>
	);
}
