import client from "../../client/client";

class ProjectDirectoryService {
	getTree({ projectId }, cancelToken) {
		return client.get(`/projects/${projectId}/directories/tree`, { config: { cancelToken } });
	}

	getLocation({ projectId, directoryId }, cancelToken) {
		return client.get(`/projects/${projectId}/directories/${directoryId}/location`, { config: { cancelToken } });
	}

	getInformation({ projectId, directoryId }, cancelToken) {
		return client.get(`/projects/${projectId}/directories/${directoryId}/information`, { config: { cancelToken } });
	}

	getRootContentFiltered(
		{ projectId },
		{ sort = "", direction = "", page = 0, limit = 15 },
		{ filters },
		cancelToken
	) {
		return client.post(`/projects/${projectId}/documents`, {
			params: { sort, direction, page, limit },
			payload: filters,
			config: { cancelToken },
		});
	}

	getDirectoryContent(
		{ projectId, directoryId },
		{ sort = "", direction = "", searchDocumentId = null, page = 0, limit = 15 },
		cancelToken
	) {
		return client.get(`/projects/${projectId}/directories/${directoryId}/content`, {
			params: { sort, direction, searchDocumentId, page, limit },
			config: { cancelToken },
		});
	}

	getStatusCounters({ projectId }, cancelToken) {
		return client.get(`/projects/${projectId}/directories/documents-status`, {
			config: { cancelToken },
		});
	}

	moveDirectoryContent({ projectId }, { toDirectoryId, directoryIds, documentIds, fromDirectoryId }, cancelToken) {
		return client.patch(`/projects/${projectId}/directories/move-content`, {
			payload: { toDirectoryId, directoryIds, documentIds, fromDirectoryId: fromDirectoryId.toString() },
			config: { cancelToken },
		});
	}

	create({ projectId }, payload, cancelToken) {
		return client.post(`/projects/${projectId}/directories`, { payload, config: { cancelToken } });
	}

	rename({ projectId, directoryId }, name, cancelToken) {
		return client.put(`/projects/${projectId}/directories/${directoryId}`, {
			payload: { value: name },
			config: { cancelToken },
		});
	}

	deleteSelection({ projectId }, { fromDirectoryId, directoryIds, documentIds, deleteContent = false }, cancelToken) {
		return client.patch(`/projects/${projectId}/directories/deletion`, {
			payload: {
				directoryIds,
				documentIds,
				fromDirectoryId: fromDirectoryId.toString(),
				deleteContent,
			},
			config: { cancelToken },
		});
	}

	download({ projectId }, { fromDirectoryId, directoryIds, documentIds }, cancelToken) {
		return client.downloadPatch(`/projects/${projectId}/directories/download`, {
			payload: {
				fromDirectoryId: fromDirectoryId.toString(),
				directoryIds,
				documentIds,
			},
			config: { cancelToken },
		});
	}
}

export default new ProjectDirectoryService();
