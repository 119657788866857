import React from "react";
import { Collapse, Paper } from "@mui/material";
import styles from "./Section.module.css";
import { CustomIconButton, CustomTooltip, icon } from "../../../../../../../common/components";
import { isNonEmptyArray } from "../../../../../../../common/utils";

export default function Section({
	children,
	className = "",
	collapsed = true,
	elevation = 1,
	options = [],
	sectionTag = "",
	title,
	onCollapse,
}) {
	const handleCollaspe = () => {
		if (typeof onCollapse === "function") {
			onCollapse(sectionTag);
		}
	};
	return (
		<section className={`${styles.section} ${className}`}>
			<CustomTooltip showWhenOverflow title={title}>
				<div className={styles.section__title}>
					<span>
						<CustomIconButton
							icon={collapsed ? icon.faChevronDown : icon.faChevronRight}
							onClick={handleCollaspe}
						/>
						{title}
					</span>
					{isNonEmptyArray(options) && options.map((option) => option)}
				</div>
			</CustomTooltip>
			<Collapse unmountOnExit in={collapsed} timeout="auto">
				<Paper square className={styles.section__content} elevation={elevation}>
					{children}
				</Paper>
			</Collapse>
		</section>
	);
}
