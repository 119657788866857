import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FavoriteCategoryService, FeatureFlagService, MentionsService, MyService, ProjectInfoService } from "../../api";
import KeycloakService from "../../services/KeycloakService";
import { languages } from "../constants";
import { ContextProvider, setCompanyRoles, setFlags, setSegFlags, setUserRoles } from "../providers";
import { setCompanyType } from "../providers/permission-provider";

function getUserLanguage(lang) {
	if (lang && languages.some((l) => l.value === lang)) {
		document.documentElement.lang = lang;
		return languages.find((l) => l.value === lang);
	}
	document.documentElement.lang = "en";
	return { value: "en", name: "English" };
}
const initialState = {
	isLogged: false,
	shouldReconnectWebsocket: false,
	flags: null,
	roles: null,
	companyRoles: null, // Company roles in project
	company: null,
	companyType: null,
	companies: null,
	project: null,
	user: null,
	teamMembers: [],
	language: getUserLanguage(ContextProvider.getLang()),
	notificationsFrequency: null,
	cautionBannerDisabled: ContextProvider.getCautionBannerDisabled() || false,
	loggedPhysicalComp: ContextProvider.getloggedPhysicalCompany() || null,
	loadingCompanies: false,
	loadingProject: false,
	loadingFlags: false,
	loadingSegFlags: false,
	loadingUser: false,
	loadingTeamMembers: false,
	crossFilters: null,
	loadingFavoriteCategories: false,
	favoriteCategories: { open: false, categories: [] },
	segFlags: null,
	hasEntered: false,
	disableKeyEvents: false,
	preventNavigation: { active: false, message: "" },
	firstTimeLogin: true,
	commentsRowsPerPage: 25,
	updateRelogCompanyList: true,
	questionsSelectedTab: { tab: 0, index: 0 },
	isEditBu: false,
};

export const getProject = createAsyncThunk(
	"context/getProject",
	({ projectId, token }) => MyService.getProjectDetails({ projectId }, token),
	{ condition: () => KeycloakService.isLoggedIn() }
);
export const getFlags = createAsyncThunk("context/getFlags", (token) => FeatureFlagService.getAll(token), {
	condition: () => KeycloakService.isLoggedIn(),
});
export const getSegFlags = createAsyncThunk(
	"context/getSegFlags",
	({ projectId, token }) => ProjectInfoService.getActiveFeatures({ projectId }, token),
	{ condition: () => KeycloakService.isLoggedIn() }
);
export const getAllFavoriteCategories = createAsyncThunk(
	"context/getAllFavoriteCategories",
	({ projectId, token }) => FavoriteCategoryService.getAllFavoriteCategories({ projectId }, token),
	{ condition: () => KeycloakService.isLoggedIn() }
);
export const getUser = createAsyncThunk("context/getUser", (token) => MyService.getDetails(token), {
	condition: () => KeycloakService.isLoggedIn(),
});
export const getPreferedLanguage = createAsyncThunk(
	"context/getPreferedLanguage",
	(token) => MyService.getPreferedLanguage(token),
	{ condition: () => KeycloakService.isLoggedIn() }
);
export const getUserNotificationsFrequency = createAsyncThunk(
	"context/getUserNotificationsFrequency",
	(token) => MyService.getUserNotificationsFrequency(token),
	{ condition: () => KeycloakService.isLoggedIn() }
);
export const getTeamMembers = createAsyncThunk(
	"context/getTeamMembers",
	({ projectId, token }) => MentionsService.getTeamMembers({ projectId }, {}, token),
	{ condition: () => KeycloakService.isLoggedIn() }
);
const contextSlice = createSlice({
	name: "context",
	initialState,
	reducers: {
		resetProject: (state) => {
			const userRoles = { ...state.roles, PROJECT: [] };
			const companyRoles = { ...state.companyRoles, PROJECT: [] };
			setCompanyRoles(companyRoles);
			return {
				...state,
				project: null,
				loadingFlags: true,
				roles: userRoles,
				companyRoles,
			};
		},
		setCompany: (state, { payload }) => ({ ...state, company: payload }),
		setUpdateRelogCompanyList: (state, { payload }) => ({ ...state, updateRelogCompanyList: payload }),
		setIsEditBu: (state, { payload }) => ({ ...state, isEditBu: payload }),
		setLanguage: (state, { payload }) => {
			const { value, cancelToken } = payload;
			ContextProvider.setLang(value);
			MyService.changeLanguage({ value }, cancelToken.token).catch((err) => console.error(err));
			return { ...state, language: getUserLanguage(value) };
		},
		setUserNotificationsFrequency: (state, { payload }) => {
			const { value, cancelToken } = payload;
			MyService.changeUserNotificationsFrequency({ value }, cancelToken.token).catch((err) => console.error(err));
			return { ...state, notificationsFrequency: value };
		},
		setToken: (state, { payload }) => {
			const logged = ContextProvider.login(payload);
			const needsToLoadFlags = !state.isLogged && logged;
			return { ...state, isLogged: logged, loadingFlags: needsToLoadFlags, shouldReconnectWebsocket: logged };
		},
		setSegmentationFlags: (state, { payload }) => {
			setSegFlags(payload);
			return { ...state, segFlags: payload };
		},
		subscribeToCategory: (state, { payload }) => {
			const tempCat = [...state.favoriteCategories.categories];
			let index = tempCat.findIndex((cat) => cat.id > payload.id);
			if (index === -1) {
				index = tempCat.length;
			}
			tempCat.splice(index, 0, payload);
			return { ...state, favoriteCategories: { ...state.favoriteCategories, categories: tempCat } };
		},
		unsubscribeToCategory: (state, { payload }) => {
			const tempCat = state.favoriteCategories.categories.filter((cat) => cat.id !== payload);
			return { ...state, favoriteCategories: { ...state.favoriteCategories, categories: tempCat } };
		},
		checkFavoriteCategories: (state, { payload }) => {
			const tempCat = state.favoriteCategories.categories.map((favCat) => {
				if (payload.includes(favCat.id)) {
					return { ...favCat, checked: true };
				}
				return { ...favCat, checked: false };
			});
			return { ...state, favoriteCategories: { ...state.favoriteCategories, categories: tempCat } };
		},
		openFavoriteCategories: (state) => ({
			...state,
			favoriteCategories: { ...state.favoriteCategories, open: true, isManaging: true },
		}),
		closeFavoriteCategories: (state) => ({
			...state,
			favoriteCategories: { ...state.favoriteCategories, open: false, isManaging: false },
		}),
		removeFavoriteCategory: (state, { payload }) => ({
			...state,
			favoriteCategories: {
				...state.favoriteCategories,
				categories: state.favoriteCategories.categories.filter((cat) => cat.id !== payload),
			},
		}),
		setCautionBannerDisabled: (state, { payload }) => {
			ContextProvider.setCautionBannerDisabled(payload);
			return { ...state, cautionBannerDisabled: payload };
		},
		setloggedPhysicalComp: (state, { payload }) => {
			ContextProvider.setloggedPhysicalCompany(payload);
			return { ...state, loggedPhysicalComp: payload };
		},
		setShouldReconnectWebsocket: (state, { payload }) => ({ ...state, shouldReconnectWebsocket: payload }),
		setCrossFilters: (state, { payload }) => ({ ...state, crossFilters: payload }),
		setCompanies: (state, { payload }) => ({ ...state, companies: payload }),
		setHasEntered: (state) => ({ ...state, hasEntered: true }),
		setDisableKeyEvents: (state, { payload }) => ({ ...state, disableKeyEvents: payload }),
		setPreventNavigation: (state, { payload }) => ({ ...state, preventNavigation: payload }),
		setFirstTimeLogin: (state, { payload }) => ({ ...state, firstTimeLogin: payload }),
		setCommentsRowsPerPage: (state, { payload }) => ({ ...state, commentsRowsPerPage: payload }),
		setQuestionsSelectedTab: (state, { payload }) => ({ ...state, questionsSelectedTab: payload }),
	},
	extraReducers: (builder) => {
		builder.addCase(getProject.pending, (state) => ({
			...state,
			loadingProject: true,
		}));
		builder.addCase(getProject.rejected, (state) => ({
			...state,
			loadingProject: false,
		}));
		builder.addCase(getProject.fulfilled, (state, { payload }) => {
			if (payload) {
				const {
					projectId,
					projectName,
					roles: projectRoles,
					companyRoles: companyRolesInProject,
					companyType,
					companyName: companyLoggedToProject,
					hasRoleOnDocument,
					teams,
				} = payload;
				const roles = { ...state.roles, PROJECT: projectRoles };
				const companyRoles = { ...state.companyRoles, PROJECT: companyRolesInProject };
				setUserRoles(roles);
				setCompanyRoles(companyRoles);
				setCompanyType(companyType);
				return {
					...state,
					project: { teams, id: projectId, name: projectName, hasRoleOnDocument, companyLoggedToProject },
					roles,
					companyRoles,
					loadingProject: false,
				};
			}
			return state;
		});
		builder.addCase(getFlags.pending, (state) => ({
			...state,
			loadingFlags: true,
		}));
		builder.addCase(getFlags.rejected, (state) => ({
			...state,
			loadingFlags: false,
		}));
		builder.addCase(getFlags.fulfilled, (state, { payload }) => {
			if (payload) {
				setFlags(payload);
				return { ...state, flags: payload, loadingFlags: false };
			}
			return state;
		});
		builder.addCase(getSegFlags.pending, (state) => ({
			...state,
			loadingSegFlags: true,
		}));
		builder.addCase(getSegFlags.rejected, (state) => ({
			...state,
			loadingSegFlags: false,
		}));
		builder.addCase(getSegFlags.fulfilled, (state, { payload }) => {
			const newState = { ...state };
			if (payload) {
				setSegFlags(payload);
				newState.segFlags = payload;
				newState.loadingSegFlags = false;
			}
			return newState;
		});
		builder.addCase(getAllFavoriteCategories.pending, (state) => ({
			...state,
			loadingFavoriteCategories: true,
		}));
		builder.addCase(getAllFavoriteCategories.rejected, (state) => ({
			...state,
			loadingFavoriteCategories: false,
		}));
		builder.addCase(getAllFavoriteCategories.fulfilled, (state, { payload }) => ({
			...state,
			favoriteCategories: { open: payload.length === 0, categories: payload },
		}));
		builder.addCase(getPreferedLanguage.fulfilled, (state, { payload }) => {
			const { value } = payload;
			const preferedLang = state.firstTimeLogin ? KeycloakService.userLanguage() : value;
			ContextProvider.setLang(preferedLang);
			return { ...state, language: getUserLanguage(preferedLang) };
		});
		builder.addCase(getUserNotificationsFrequency.fulfilled, (state, { payload }) => ({
			...state,
			notificationsFrequency: payload.value,
		}));
		builder.addCase(getUser.pending, (state) => ({
			...state,
			loadingUser: true,
		}));
		builder.addCase(getUser.rejected, (state) => ({
			...state,
			loadingUser: false,
		}));
		builder.addCase(getUser.fulfilled, (state, { payload }) => {
			if (payload) {
				const {
					companyId,
					companyName,
					companyType,
					displayName,
					email,
					firstName,
					lastName,
					role,
					roles,
					userId: id,
				} = payload;
				ContextProvider.setUserDetails({ companyId, email, displayName, userId: id });
				const initials = `${firstName[0]}${lastName[0]}`;
				const user = {
					displayName,
					email,
					firstName,
					id,
					initials,
					lastName,
					role,
				};
				let company = null;
				if (companyId) {
					company = {
						id: companyId,
						name: companyName,
						roles,
					};
				}
				const storedRoles = { ...state.roles, PLATFORM: role, COMPANY: roles };
				setCompanyType(companyType);
				setUserRoles(storedRoles);
				return {
					...state,
					user,
					company,
					companyType,
					roles: storedRoles,
					loadingUser: false,
				};
			}
			return state;
		});
		builder.addCase(getTeamMembers.pending, (state) => ({
			...state,
			loadingTeamMembers: true,
		}));
		builder.addCase(getTeamMembers.rejected, (state) => ({
			...state,
			loadingTeamMembers: false,
		}));
		builder.addCase(getTeamMembers.fulfilled, (state, { payload }) => {
			if (payload) {
				const { contents } = payload;
				const tmpMembers = [];
				for (const member of contents) {
					if (member.id && member.displayName) {
						tmpMembers.push({ id: member.id, display: member.displayName });
					}
				}

				return {
					...state,
					teamMembers: tmpMembers,
					loadingTeamMembers: false,
				};
			}
			return state;
		});
		builder.addMatcher(
			({ type }) => typeof type === "string" && type.endsWith("rejected"),
			(_, { error }) => {
				console.error(error.message);
			}
		);
	},
});
export const {
	resetProject,
	setLanguage,
	setUserNotificationsFrequency,
	setCompany,
	setToken,
	subscribeToCategory,
	unsubscribeToCategory,
	checkFavoriteCategories,
	openFavoriteCategories,
	closeFavoriteCategories,
	removeFavoriteCategory,
	setCautionBannerDisabled,
	setloggedPhysicalComp,
	setShouldReconnectWebsocket,
	setCrossFilters,
	setCompanies,
	setHasEntered,
	setSegmentationFlags,
	setDisableKeyEvents,
	setPreventNavigation,
	setFirstTimeLogin,
	setUpdateRelogCompanyList,
	setIsEditBu,
	setCommentsRowsPerPage,
	setQuestionsSelectedTab,
} = contextSlice.actions;
export default contextSlice.reducer;
