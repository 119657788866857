import { Checkbox, Radio } from "@mui/material";
import React from "react";
import { CustomIconButton, CustomTooltip, icon } from "../..";
import { hasPermission, isSegFeatureEnabled, Permissions, SegFlags, translate } from "../../../providers";
import CustomAccordion from "../../accordion/CustomAccordion";
import styles from "./CategoryList.module.css";
import { isNonEmptyArray } from "../../../utils";

export default function CategoryList({
	attachedCategoryIds,
	categoryList,
	checkedIds,
	disabledIds = [],
	editable = false,
	expandedIds,
	forceExpanded = false,
	includeNone = false,
	indentLevel = 0,
	informationId = 0,
	singleSelection = false,
	onAttachInformationCategory,
	onCheck,
	onExpand,
	showSelected = false,
	disableFiltered = true,
}) {
	const handleKeyPress = (event, checked, category) => {
		if (event.key === "Enter" && onCheck) {
			onCheck(checked, category);
		}
	};
	const checkable = typeof onCheck === "function";
	const handleExpand = (expanded, category) => {
		if (typeof onExpand === "function") {
			onExpand(expanded, category);
		}
	};
	const handleAttachInformationCategory = (e, categoryId, isAttached) => {
		if (typeof onAttachInformationCategory === "function") {
			e.stopPropagation();
			onAttachInformationCategory(categoryId, isAttached);
		}
	};
	return (
		Array.isArray(categoryList) &&
		[...(includeNone ? [{ id: null, name: translate("common:empty-state.none") }] : []), ...categoryList].map(
			(category) => {
				const isChecked = Array.isArray(checkedIds) && checkedIds.includes(category.id);
				const isDisabled = Array.isArray(disabledIds) && disabledIds.includes(category.id);
				const isExpanded = Array.isArray(expandedIds) && expandedIds.includes(category.id);
				const isAttached = attachedCategoryIds?.includes(category.id);
				return (
					(!showSelected || isChecked) && (
						<div key={category.id} className={styles.lineContainer}>
							<CustomAccordion
								onlyArrowExpand
								className={styles.accordionContent}
								customAccordionClass={styles.accordation}
								customAccordionDetailsClass={styles.accordationDetails}
								data-testid={`catTree_${category.id}`}
								defaultOpen={forceExpanded || isExpanded}
								header={
									<div
										className={styles.line}
										data-checkable={checkable && editable}
										data-disabled={isDisabled}
										role="button"
										tabIndex={0}
										onClick={
											(checkable &&
												!isDisabled &&
												editable &&
												(() => onCheck(!isChecked, category))) ||
											null
										}
										onKeyPress={
											(!isDisabled && ((event) => handleKeyPress(event, !isChecked, category))) ||
											null
										}
									>
										{checkable && (
											<CustomTooltip
												title={
													!editable
														? translate(
																"smart-review.cmp-details.option-panel.categories-tab.not-allowed"
														  )
														: ""
												}
											>
												{(singleSelection && (
													<Radio
														checked={isChecked}
														className={styles.checkbox}
														color="primary"
														disabled={isDisabled || !editable}
													/>
												)) || (
													<Checkbox
														checked={isChecked}
														className={styles.checkbox}
														color="primary"
														disabled={isDisabled || !editable}
													/>
												)}
											</CustomTooltip>
										)}
										<CustomTooltip title={category.description || ""}>
											<span
												className={`${styles.name} ${
													(disableFiltered && category.filtered === false && styles.greyed) ||
													""
												} ${
													((Array.isArray(category.subThematic) &&
														category.subThematic.length > 0) ||
														category.childrenCount > 0) &&
													styles.bold
												}`}
												data-has-flag={isChecked && !!informationId}
											>
												{category.name}
											</span>
										</CustomTooltip>
										{!!informationId &&
											isChecked &&
											hasPermission([Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER]) &&
											isSegFeatureEnabled(SegFlags.REVIEW_BY_CATEGORY) && (
												<CustomIconButton
													btnClassName={(isAttached && styles["flagged--button"]) || ""}
													className={styles.flagButton}
													icon={icon.faFlag}
													iconClassName={(isAttached && styles["flagged--icon"]) || ""}
													size="sm"
													tooltip={
														(isAttached &&
															translate(
																"common:components.category-row.remove-from-important"
															)) ||
														translate("common:components.category-row.add-to-important")
													}
													variant="outlined"
													onClick={(e) =>
														handleAttachInformationCategory(e, category.id, isAttached)
													}
												/>
											)}
									</div>
								}
								onExpand={(expanded) => handleExpand(expanded, category)}
							>
								{((isNonEmptyArray(category.subThematic) || !!category.childrenCount) && (
									<CategoryList
										attachedCategoryIds={attachedCategoryIds}
										categoryList={category.subThematic}
										checkedIds={checkedIds}
										disabledIds={disabledIds}
										disableFiltered={disableFiltered}
										editable={editable}
										expandedIds={expandedIds}
										forceExpanded={forceExpanded}
										indentLevel={indentLevel + 1}
										informationId={informationId}
										showSelected={showSelected}
										singleSelection={singleSelection}
										onAttachInformationCategory={onAttachInformationCategory}
										onCheck={onCheck}
										onExpand={onExpand}
									/>
								)) ||
									null}
							</CustomAccordion>
						</div>
					)
				);
			}
		)
	);
}
