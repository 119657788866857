import React from "react";
import { SidePanelContainer } from "../../../../../../common/components";
import FolderInformation from "./FolderInformation";
import FileInformation from "./FileInformation";
import styles from "./DocumentCenterInformationTab.module.css";
import { translate } from "../../../../../../common/providers";
import { compareInsensitiveCaseString } from "../../../../../../common/utils";
import { FILE_TYPES } from "../../../../../../common/constants/file-types";
import { DOCUMENT_STATUS } from "../../../../constants/constants";

export default function DocumentCenterInformationTab({ id, onSetDirectoryId, type, status }) {
	return (
		<SidePanelContainer className={styles.tab}>
			<span className={styles.tab__title}>{translate("document-center.side-panel.information")}</span>
			{(type === "folder" && <FolderInformation directoryId={id} />) || (
				<FileInformation
					docId={id}
					isOngoing={DOCUMENT_STATUS.IN_PROGRESS === status || DOCUMENT_STATUS.PENDING === status}
					unsupported={compareInsensitiveCaseString(type, FILE_TYPES.UNSUPPORTED)}
					onSetDirectoryId={onSetDirectoryId}
				/>
			)}
		</SidePanelContainer>
	);
}
