import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { useSelector } from "react-redux";
import styles from "./ReqDetails.module.css";
import ReqDetailsEmptyState from "./ReqDetailsEmptyState";
import ReqDetailsLoader from "./ReqDetailsLoader";
import { ReqInfoTable, Section, ReqReviewsTable, ReqPositionsTable, RelatedDeliverables } from "./components";
import { Flags, isFeatureEnabled, translate } from "../../../../../common/providers";
import { SppRequirementService } from "../../../../../api";
import { useApi } from "../../../../../common/hooks";
import { CustomIconButton, icon, Preview, SearchRow } from "../../../../../common/components";

export default function ReqDetails({ isLoadingDetails, onClickEdit, requirement }) {
	const { call: getDeliverablesForRequirement } = useApi(SppRequirementService.getDeliverablesForRequirement);
	const projectId = useSelector(({ context }) => context.project.id || 0);
	const [deliverables, setDeliverables] = useState([]);
	const [openedSections, setOpenedSections] = useState([]);
	const [preview, setPreview] = useState(null);
	const {
		attributes,
		category,
		clause,
		clientRef,
		content,
		criticality,
		documentId,
		documentName,
		documentOuts,
		id,
		negotiability,
		page,
		positions,
		reviews,
		type,
		coordinates,
	} = requirement || {};
	useEffect(() => {
		const defaultDeliverables = (isFeatureEnabled(Flags.COVERAGE_PLUGIN) && ["DELIVERABLES"]) || [];
		setOpenedSections(["POSITIONS", "REVIEWS", "INFORMATION", ...defaultDeliverables]);
	}, []);
	useEffect(() => {
		if (projectId && requirement?.id && openedSections.includes("DELIVERABLES")) {
			getDeliverablesForRequirement({ projectId, requirementId: requirement.id })
				.then((data) => {
					setDeliverables(data);
				})
				.catch(console.error);
		}
		setDeliverables([]);
	}, [projectId, requirement?.id, openedSections, getDeliverablesForRequirement]);
	const handleCollapse = (keyToOpen) => {
		if (keyToOpen) {
			setOpenedSections((prev) => {
				if (prev.includes(keyToOpen)) {
					return prev.filter((k) => k !== keyToOpen);
				}
				return [...prev, keyToOpen];
			});
		}
	};
	const handleCloseDisplay = () => setPreview(null);
	const handleDisplay = (infoId, docId, pageStart, infoType, rec) =>
		setPreview({
			coordinates: rec || null,
			documentId: docId,
			page: pageStart,
			informationId: infoId,
			informationType: infoType,
		});

	return (
		<div className={styles["req-details"]}>
			{isLoadingDetails && <ReqDetailsLoader />}
			{!isLoadingDetails && !requirement && <ReqDetailsEmptyState />}
			{!isLoadingDetails && !!requirement && (
				<>
					<Paper square className={styles.details__header}>
						<SearchRow
							hasFolderIcon
							attributes={attributes}
							categories={category}
							clause={clause}
							content={content}
							documentId={documentId}
							documentName={documentName}
							informationId={id}
							informationType={type}
							isCheckboxDisplayed={false}
							page={page}
							reqId={id}
							row={{ coordinates }}
							onDisplay={handleDisplay}
						/>
					</Paper>
					<br />
					<Section
						collapsed={openedSections.includes("POSITIONS")}
						sectionTag="POSITIONS"
						title={translate("requirement-matrix.req-details.section.company-position")}
						onCollapse={handleCollapse}
					>
						<ReqPositionsTable positions={positions} />
					</Section>
					<Section
						collapsed={openedSections.includes("REVIEWS")}
						sectionTag="REVIEWS"
						title={translate("requirement-matrix.req-details.section.reviewer")}
						onCollapse={handleCollapse}
					>
						<ReqReviewsTable reviews={reviews} />
					</Section>
					<Section
						collapsed={openedSections.includes("INFORMATION")}
						options={[
							<CustomIconButton
								icon={icon.faPen}
								tooltip={translate("requirement-matrix.req-details.header.edit-req")}
								onClick={onClickEdit}
							/>,
						]}
						sectionTag="INFORMATION"
						title={translate("requirement-matrix.req-details.section.information")}
						onCollapse={handleCollapse}
					>
						<ReqInfoTable
							attributes={attributes}
							category={category}
							clause={clause}
							clientRef={clientRef}
							criticality={criticality}
							documentName={documentName}
							documentOuts={documentOuts}
							id={id}
							negotiability={negotiability}
							type={type}
						/>
					</Section>
					{isFeatureEnabled(Flags.COVERAGE_PLUGIN) && (
						<Section
							collapsed={openedSections.includes("DELIVERABLES")}
							elevation={0}
							sectionTag="DELIVERABLES"
							title={translate("requirement-matrix.req-details.section.related-deliverables")}
							onCollapse={handleCollapse}
						>
							<RelatedDeliverables
								deliverables={deliverables}
								projectId={projectId}
								requirementId={requirement.id}
							/>
						</Section>
					)}
				</>
			)}
			<Preview
				coordinates={preview?.coordinates}
				docId={preview?.documentId}
				infoId={preview?.informationId}
				infoType={preview?.informationType}
				open={!!preview}
				page={preview?.page}
				projectId={projectId}
				onClose={handleCloseDisplay}
			/>
		</div>
	);
}
