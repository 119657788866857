import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ApiService, BackOfficeService, MyService, SearchCardTemplateService } from "../../../api";
import { hasPermission, Permissions, translate } from "../../../common/providers";
import {
	AutomationDialog,
	CustomButton,
	CustomButtonLink,
	EmptyState,
	HorizontalDivider,
	I18nTranslate,
	icon,
	SideDrawerMain,
	ViewBanner,
} from "../../../common/components";
import {
	setSelectedProject,
	setFilters,
	setCurrentSearch,
	getTotalResults,
	search,
	setTotalMatches,
	getCriticalityParameters,
	getTypeParameters,
	getNegotiabilityParameters,
	setIsLoadingSearch,
	setIsUpdating,
	setIsFilterTocOut,
	getSortingAlgorithms,
} from "./slice/company-search-template-slice";
import { SearchTemplateResult, SearchTemplateFilters, SearchTemplateDrawer } from "../components";
import { SCORING_SORT_AVAILABLE, VISIBILITY_TEMPLATE_AUTOMATION } from "../../../common/constants";
import { NavUtils } from "../../../navigation";
import styles from "./CompanySearchTemplate.module.css";

export default function CompanySearchTemplate() {
	const [openDrawer, setOpenDrawer] = useState(true);
	const [openAddAutomation, setOpenAddAutomation] = useState(false);
	const [totalProjects, setTotalProjects] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const cancelTokenSourceRef = useRef(null);
	const crossFilterTimeout = null;
	const modified = useSelector(({ companySearchTemplate }) => companySearchTemplate.modified);
	const isDsi = useSelector(({ companySearchTemplate }) => companySearchTemplate.isDsi);
	const currentSearch = useSelector(({ companySearchTemplate }) => companySearchTemplate.currentSearch);
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const isAdminOrManager = useMemo(() => hasPermission([Permissions.COMPANY_ADMIN, Permissions.COMPANY_MANAGER]), []);
	const initSearch = useCallback(() => {
		if (isDsi) {
			BackOfficeService.getTemplateProjects(cancelTokenSourceRef.current.token, { page: 0, limit: 1 })
				.then((data) => {
					if (Array.isArray(data) && data.length > 0) {
						dispatch(setSelectedProject(data[0]) || null);
					}
				})
				.catch((err) => {
					console.error(err);
				});
		} else {
			const initialProjectId = searchParams.get("projectId");
			if (initialProjectId) {
				MyService.getProjectDetails({ projectId: initialProjectId }, cancelTokenSourceRef.current.token)
					.then((data) => {
						dispatch(setSelectedProject(data));
					})
					.catch((err) => {
						console.error(err);
					});
			} else {
				SearchCardTemplateService.getProjects(cancelTokenSourceRef.current.token, { page: 0, limit: 1 })
					.then((data) => {
						if (Array.isArray(data.contents) && data.contents.length > 0) {
							dispatch(setSelectedProject(data.contents[0]) || null);
						}
						setTotalProjects(data.totalElements);
						setIsLoading(false);
					})
					.catch((err) => {
						console.error(err);
					});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDsi]);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		if (SCORING_SORT_AVAILABLE) {
			dispatch(getSortingAlgorithms(cancelTokenSourceRef.current.token))
				.then(() => {
					initSearch();
				})
				.catch(console.error);
		} else {
			initSearch();
		}
		return () => {
			clearInterval(crossFilterTimeout);
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initSearch]);
	const handleOpenAddAutomation = () => {
		setOpenAddAutomation((prev) => !prev);
	};
	const handleOpenDrawer = () => setOpenDrawer((prev) => !prev);
	const newAutomationButton = (
		<CustomButton disabled={currentSearch?.isNew || modified} variant="contained" onClick={handleOpenAddAutomation}>
			{translate("search-template.banner.new-automation")}
		</CustomButton>
	);
	const newProjectButton = (
		<CustomButtonLink color="primary" startIcon={icon.faPlus} to={NavUtils.goToNewProject()} variant="contained">
			{translate("search-templates.no-project-btn")}
		</CustomButtonLink>
	);
	const noProjectRender = (
		<EmptyState
			btn1={isAdminOrManager ? newProjectButton : null}
			content={<I18nTranslate translationKey="search-templates.no-project-text" />}
			link={translate("search-templates.no-project-link")}
			linkAddress={translate("search-templates.no-project-link-address")}
			title={translate("search-templates.no-project-title")}
		/>
	);
	return (
		<>
			<ViewBanner
				options={!isDsi ? [newAutomationButton] : []}
				titles={[
					{ title: translate("navigation:common.templates"), key: "templates" },
					{ title: translate("navigation:common.templates.searches-for-automation-templates") },
				]}
			/>
			{!isLoading && totalProjects === 0 ? (
				<>{noProjectRender}</>
			) : (
				<SideDrawerMain
					contentClassName={styles.sideDrawer__content}
					DrawerComponent={SearchTemplateDrawer}
					openDrawer={openDrawer}
					search={search}
					setCurrentSearch={setCurrentSearch}
					setFilters={setFilters}
					setIsLoadingSearch={setIsLoadingSearch}
					setIsUpdating={setIsUpdating}
					slice="companySearchTemplate"
					VISIBILITY_CONSTANTS={VISIBILITY_TEMPLATE_AUTOMATION}
					onOpenDrawer={handleOpenDrawer}
				>
					<SearchTemplateFilters
						getCriticalityParameters={getCriticalityParameters}
						getNegotiabilityParameters={getNegotiabilityParameters}
						getTypeParameters={getTypeParameters}
						setCurrentSearch={setCurrentSearch}
						setFilters={setFilters}
						setIsLoadingSearch={setIsLoadingSearch}
						setIsUpdating={setIsUpdating}
						slice="companySearchTemplate"
					/>
					<HorizontalDivider />
					<SearchTemplateResult
						getTotalResults={getTotalResults}
						search={search}
						setIsFilterTocOut={setIsFilterTocOut}
						setIsLoadingSearch={setIsLoadingSearch}
						setSelectedProject={setSelectedProject}
						setTotalMatches={setTotalMatches}
						slice="companySearchTemplate"
					/>
					<AutomationDialog
						isMultiProject
						isNew
						open={openAddAutomation}
						preSelectedSearchCards={currentSearch.id}
						onClose={handleOpenAddAutomation}
						onSubmit={handleOpenAddAutomation}
					/>
				</SideDrawerMain>
			)}
		</>
	);
}
