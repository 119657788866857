import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./ContentLayer.module.css";
import { checkReq, checkReqValMode } from "../../../../../utils/utils";
import RenderRectangle from "./RenderRectangle";
import { renderPositionStyle } from "../../../utils/utils";
import { isDeepEqualComplex, isNonEmptyArray } from "../../../../../../../common/utils";
import { useApi, usePrevious } from "../../../../../../../common/hooks";
import { QuickAccessService } from "../../../../../../../api";
import { generateFilters } from "../../../../../../../common/components";
import { setQuickAccessResults } from "../../../../../slice/document/document-slice";

export default function ContentLayer({
	additionalData,
	height,
	metadata,
	onChangeVisibility,
	openedQuickAccess,
	tableHeight,
	width,
	page,
	selectionRectangleRef,
	hoveredContent,
	onMouseEnterBlock,
}) {
	const [isLoadingQuickAccessSearch, setIsLoadingQuickAccessSearch] = useState(false);
	const redressingInformation = useSelector(({ srDocument }) => srDocument.redressingInformation);
	const selectedSearch = useSelector(({ srDocument }) => srDocument.selectedSearch);
	const documentId = useSelector(({ srDocument }) => srDocument.documentId);
	const selectedRectangles = useSelector(({ srDocument }) => srDocument.selectedRectangles);
	const transverseFilters = useSelector(({ srProjectDocument }) => srProjectDocument.transverseFilters);
	const quickAccessResults = useSelector(({ srDocument }) => srDocument.quickAccessResults);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const isThereAnotherTransverseFilter = useSelector(
		({ srProjectDocument }) => srProjectDocument.isThereAnotherTransverseFilter
	);
	const { call: getSearchResults } = useApi(QuickAccessService.getFilterResultsInDocumentPage);
	const prevTransverseFilters = usePrevious(transverseFilters);
	const prevMetadata = usePrevious(metadata);
	const dispatch = useDispatch();
	const pageResults = useMemo(
		() => quickAccessResults?.find((pr) => pr.page === page)?.results || [],
		[quickAccessResults, page]
	);
	const renderSearchFocus = (rec) => {
		const isReqContent = checkReq(rec);
		return (
			<>
				{isNonEmptyArray(rec?.rectangles) &&
					rec.rectangles.map((rectangle) => {
						const coordinates = renderPositionStyle(rectangle, width, height);
						const { top, height: focusHeight } = coordinates;
						return (
							<area
								key={rectangle.order}
								alt={rectangle.order}
								className={`${styles["search-indicator"]}`}
								data-hovered={rec.infoId === hoveredContent?.informationId}
								data-is-selected-result={selectedSearch && rec?.infoId === selectedSearch?.infoId}
								data-req-content={isReqContent && !redressingInformation}
								role="presentation"
								style={{
									top,
									left: width - 24,
									height: focusHeight,
								}}
							/>
						);
					})}
			</>
		);
	};
	useEffect(() => {
		if (
			((!isDeepEqualComplex(transverseFilters, prevTransverseFilters) && metadata) || !prevMetadata) &&
			!isLoadingQuickAccessSearch &&
			isThereAnotherTransverseFilter
		) {
			setIsLoadingQuickAccessSearch(true);
			getSearchResults({ documentId, pageNumber: page }, generateFilters(transverseFilters))
				.then(({ values }) => {
					dispatch(
						setQuickAccessResults({
							page,
							results: metadata.filter((rec) => values.some((reqId) => reqId === rec.informationId)),
						})
					);
				})
				.catch(console.error)
				.finally(() => setIsLoadingQuickAccessSearch(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [quickAccessResults, isLoadingQuickAccessSearch, documentId, page, transverseFilters, prevTransverseFilters]);
	return (
		<>
			{isNonEmptyArray(metadata) &&
				metadata.map((area) => {
					if (isNonEmptyArray(area?.rectangles)) {
						const { rectangle: rec, ...rest } = area;
						const { contentId } = area.rectangles ? area.rectangles[0] : 0;
						return (
							<RenderRectangle
								key={contentId}
								height={height}
								hoveredBlock={hoveredContent}
								isInSelection={
									(additionalData?.inSelection || []).some(
										(r) =>
											r.informationId === area.informationId || r.informationId === area.info_id
									) ||
									(selectedRectangles || []).some(
										(r) =>
											r.informationId === area.informationId || r.informationId === area.info_id
									)
								}
								openedQuickAccess={openedQuickAccess}
								page={page}
								rectangle={{ ...rec, contentId, ...rest }}
								selectionRectangleRef={selectionRectangleRef}
								tableHeight={tableHeight}
								width={width}
								onChangeVisibility={onChangeVisibility}
								onEnterArea={onMouseEnterBlock}
							/>
						);
					}
					return (
						<RenderRectangle
							height={height}
							hoveredBlock={hoveredContent}
							isInSelection={
								(additionalData?.inSelection || []).some(
									(r) => r.informationId === area.informationId || r.informationId === area.info_id
								) ||
								(selectedRectangles || []).some(
									(r) => r.informationId === area.informationId || r.informationId === area.info_id
								)
							}
							openedQuickAccess={openedQuickAccess}
							page={page}
							rectangle={area}
							selectionRectangleRef={selectionRectangleRef}
							tableHeight={tableHeight}
							width={width}
							onChangeVisibility={onChangeVisibility}
							onEnterArea={onMouseEnterBlock}
						/>
					);
				})}
			{isThereAnotherTransverseFilter &&
				checkReqValMode(mode) &&
				(pageResults || []).map((sd) => renderSearchFocus(sd))}
		</>
	);
}
