import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { exportView } from "../../navigation";
import { Resizer, ViewBanner, cleanFilters } from "../../common/components";
import { AnalyticsProvider, Flags, SegFlags, isSegFeatureEnabled, translate } from "../../common/providers";
import styles from "./ChatProject.module.css";
import { ChatList, CurrentConversation, Search } from "./components";
import { SEPARATOR } from "../smart-search/utils/utils";
import { ChatProjectService, RequirementMatrixService } from "../../api";
import { useApi } from "../../common/hooks";

const ChatProject = () => {
	const { call: askNewQuestion } = useApi(ChatProjectService.askNewQuestion);
	const { call: getChat } = useApi(ChatProjectService.getChat);
	const { call: getCompaniesSharedPrivacyInProject } = useApi(
		RequirementMatrixService.getCompaniesSharedPrivacyInProject
	);
	const { call: getParametersForInformation } = useApi(RequirementMatrixService.getParametersForInformation);
	const cautionBannerDisabled = useSelector(({ context }) => context.cautionBannerDisabled);
	const project = useSelector(({ context }) => context.project);
	const [chatPayload, setChatPayload] = useState({
		value: "",
		filter: { separator: SEPARATOR.AND, Type: "QuickAccessSearchFilterDTO" },
		summary: "",
	});
	const [selectedChat, setSelectedChat] = useState(0);
	const [toRefresh, setToRefresh] = useState(true);
	const [toRefreshConversation, setToRefreshConversation] = useState(true);
	const [textMsg, setTextMsg] = useState("");
	const [cancelAnswer, setCancelAnswer] = useState(false);
	const [partners, setPartners] = useState([]);
	const [parameters, setParameters] = useState({});
	const handleResetFilters = useCallback(() => {
		setChatPayload({ ...chatPayload, filter: { separator: SEPARATOR.AND, Type: "QuickAccessSearchFilterDTO" } });
	}, [setChatPayload, chatPayload]);

	useEffect(() => {
		const documentTitle = translate("chat-project.title");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "ChatProject" });
	}, []);

	useEffect(() => {
		if (isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS)) {
			getCompaniesSharedPrivacyInProject({ projectId: project.id })
				.then((data) => {
					setPartners(data.map((d) => ({ ...d, display: true })));
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}, [project.id, getCompaniesSharedPrivacyInProject]);
	useEffect(() => {
		if (isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS)) {
			getParametersForInformation()
				.then(setParameters)
				.catch((err) => {
					console.error(err);
				});
		}
	}, [getParametersForInformation, setParameters]);
	const handleChangeSelectedChat = (chatId) => {
		setTextMsg("");
		setSelectedChat(chatId);
		if (chatId) {
			setToRefreshConversation(true);
		}
	};
	const handleApply = (newFilters) => {
		setChatPayload({ ...chatPayload, filter: cleanFilters(newFilters) });
	};
	const handleChangeTextFilter = (value, summary = null) => {
		setChatPayload((prev) => ({ ...prev, value, summary }));
	};

	const handleResponseOfConversation = () => {
		setToRefreshConversation(false);
	};
	const handleResponse = () => {
		setToRefresh(false);
	};
	return (
		<>
			<ViewBanner
				titles={[
					{
						title: (
							<>
								{translate("navigation:project.chat-project")}
								<span className={styles.betaText}> [Beta]</span>
							</>
						),
					},
				]}
			/>
			<div className={styles.mainDisplay} data-caution-banner-disabled={cautionBannerDisabled}>
				<div className={styles.sideDrawer__main}>
					<ChatList
						selectedChat={selectedChat}
						toRefresh={toRefresh}
						onChangeSelectedChat={handleChangeSelectedChat}
						onListUpdate={handleResponse}
						onResetFilters={handleResetFilters}
						onSetToRefresh={setToRefresh}
					/>
				</div>
				<Resizer active className={styles.resizer} max={30} min={15} />
				<div className={styles.siderDrawer__content}>
					<CurrentConversation
						parameters={parameters}
						partners={partners}
						selectedChat={selectedChat}
						textValue={textMsg}
						toRefreshConversation={toRefreshConversation}
						onCancelAnswer={cancelAnswer}
						onChangeTextFilter={handleChangeTextFilter}
						onConversationUpdate={handleResponseOfConversation}
						onGetChat={getChat}
					/>
					<Search
						askNewQuestion={askNewQuestion}
						chatPayload={chatPayload}
						parameters={parameters}
						partners={partners}
						selectedChat={selectedChat}
						setChatPayload={setChatPayload}
						onApply={handleApply}
						onChangeSelectedChat={handleChangeSelectedChat}
						onChangeTextFilter={handleChangeTextFilter}
						onSetCancelAnswer={setCancelAnswer}
						onSetTextMsg={setTextMsg}
						onSetToRefresh={setToRefresh}
						onSetToRefreshConversation={setToRefreshConversation}
					/>
				</div>
			</div>
		</>
	);
};
export default exportView({
	path: "/projects/:projectId/chat-project",
	localesPath: "/chat-project/locales",
	component: ChatProject,
	flag: Flags.PROJECT_CHAT,
	segFlag: SegFlags.CHAT_PROJECT,
});
