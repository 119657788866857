import {
	KEY_SMART_VIEW,
	KEY_SMART_VIEW_FILTER,
	MY_REVIEW_LEFT_PANEL_WIDTH,
	MY_REVIEW_OPEN_TOC,
	MY_REVIEW_RIGHT_PANEL_WIDTH,
} from "../../../common/providers/keys/keys";
import { ROLES } from "../constants/constants";

class SmartReviewContext {
	constructor() {
		this.storage = window.sessionStorage;
		this.localStorage = window.localStorage;
	}

	getContext() {
		try {
			return JSON.parse(this.storage.getItem(KEY_SMART_VIEW));
		} catch (e) {
			console.error(e);
			return {};
		}
	}

	setContext(payload = {}) {
		let context = null;
		if (payload) {
			if (payload.project || payload.document !== null || payload.page || payload.clause || payload.mode) {
				context = {
					...(JSON.parse(this.storage.getItem(KEY_SMART_VIEW)) || {}),
					project: !Number.isNaN(parseInt(payload.project, 10))
						? parseInt(payload.project, 10)
						: payload.project,
					document: (!Number.isNaN(parseInt(payload.document, 10)) && parseInt(payload.document, 10)) || null,
					page: !Number.isNaN(parseInt(payload.page, 10)) ? parseInt(payload.page, 10) : 1,
					clause: (!Number.isNaN(parseInt(payload.clause, 10)) && parseInt(payload.clause, 10)) || null,
					role:
						(Object.values(ROLES).some((r) => r.mode === payload.role) && payload.role) ||
						ROLES.REVIEWER.mode,
				};
				this.storage.setItem(KEY_SMART_VIEW, JSON.stringify(context));
			}
		}
	}

	setPartners(payload = {}) {
		if (payload) {
			if (Array.isArray(payload.partners)) {
				this.storage.setItem(
					KEY_SMART_VIEW,
					JSON.stringify({
						...(JSON.parse(this.storage.getItem(KEY_SMART_VIEW)) || {}),
						partners: payload.partners,
					})
				);
			}
		}
	}

	setPreferences(payload = {}) {
		if (payload?.preferences) {
			this.storage.setItem(
				KEY_SMART_VIEW,
				JSON.stringify({
					...(JSON.parse(this.storage.getItem(KEY_SMART_VIEW)) || {}),
					preferences: payload.preferences,
				})
			);
		}
	}

	setDocumentSelections(selectedDocuments) {
		if (Array.isArray(selectedDocuments)) {
			this.storage.setItem(
				KEY_SMART_VIEW,
				JSON.stringify({
					...(JSON.parse(this.storage.getItem(KEY_SMART_VIEW)) || {}),
					selectedDocuments,
				})
			);
		}
	}

	setContentSelection(selectedContent) {
		this.storage.setItem(
			KEY_SMART_VIEW,
			JSON.stringify({
				...(JSON.parse(this.storage.getItem(KEY_SMART_VIEW)) || {}),
				selectedContent,
			})
		);
	}

	setSidePanelInfo(sidePanelInfo) {
		this.storage.setItem(
			KEY_SMART_VIEW,
			JSON.stringify({
				...(JSON.parse(this.storage.getItem(KEY_SMART_VIEW)) || {}),
				sidePanelInfo,
			})
		);
	}

	updateFilterInStorage(filters) {
		if (typeof filters === "object" && filters !== null) {
			this.storage.setItem(KEY_SMART_VIEW_FILTER, JSON.stringify(filters));
		}
	}

	getMyReviewRightPanel() {
		return this.localStorage.getItem(MY_REVIEW_RIGHT_PANEL_WIDTH);
	}

	setMyReviewRightPanel(width) {
		this.localStorage.setItem(MY_REVIEW_RIGHT_PANEL_WIDTH, width);
	}

	getMyReviewLeftPanel() {
		return this.localStorage.getItem(MY_REVIEW_LEFT_PANEL_WIDTH);
	}

	setMyReviewLeftPanel(width) {
		this.localStorage.setItem(MY_REVIEW_LEFT_PANEL_WIDTH, width);
	}

	setOpenMultiTOC(boolean) {
		this.localStorage.setItem(MY_REVIEW_OPEN_TOC, boolean);
	}

	getOpenMultiTOC() {
		const value = this.localStorage.getItem(MY_REVIEW_OPEN_TOC);
		return value === "true" || value === null;
	}
}

export default new SmartReviewContext();
