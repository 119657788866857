import { createSearchParams } from "react-router-dom";
import { SOURCE_TYPES } from "../../common/constants";
import { Flags, isFeatureEnabled } from "../../common/providers";

const hasSource = (source) => Object.entries(SOURCE_TYPES).some((entry) => source === entry[1]);

class NavUtils {
	getQueryParams(location) {
		return new URLSearchParams(location?.search);
	}

	goToHome() {
		return "/";
	}

	goToForgottenPassword() {
		return "/forgotten-password";
	}

	goToSmartReview({
		projectId,
		documentId,
		pageStart = null,
		infoId = null,
		infoType = null,
		source = null,
		role = null,
		companyId = null,
		isOpenForDiscussion = null,
		commentId = null,
	}) {
		let url = `/projects/${projectId}/review/my-review/`;
		if (documentId) {
			url = `${url + documentId}`;
		}
		if (pageStart || role || hasSource(source)) {
			url = `${url}?`;
			if (pageStart) {
				url = `${url}page=${pageStart}&`;
			}
			if (infoId) {
				url = `${url}infoId=${infoId}&`;
			}
			if (infoType) {
				url = `${url}infoType=${infoType}&`;
			}
			if (role) {
				url = `${url}role=${role}&`;
			}
			if (companyId) {
				url = `${url}companyId=${companyId}&`;
			}
			if (hasSource(source)) {
				url = `${url}origin=${source}&`;
			}
			if (isOpenForDiscussion !== null) {
				url = `${url}isOpenForDiscussion=${!!isOpenForDiscussion}&`;
			}
			if (commentId) {
				url = `${url}commentId=${commentId}&`;
			}
		}
		return url;
	}

	goToProject(projectId) {
		return `/projects/${projectId}/dashboard/project`;
	}

	goToReviewProgress(projectId) {
		return `/projects/${projectId}/dashboard/review-progress`;
	}

	goToTasks(projectId) {
		return `/projects/${projectId}/dashboard/tasks`;
	}

	goToDocumentCenter(projectId, directoryId) {
		if (!isFeatureEnabled(Flags.DOCUMENT_CENTER)) {
			return this.goToProject(projectId);
		}
		let url = `/projects/${projectId}/documents`;
		if (directoryId && directoryId !== "root") {
			url = `${url}?directory=${directoryId}`;
		}
		return url;
	}

	goToRightsOnDocuments(projectId, documentId = null) {
		if (!documentId) {
			return `/projects/${projectId}/rights-on-documents`;
		}
		return `/projects/${projectId}/rights-on-documents#${documentId}`;
	}

	goToDSI() {
		return "/dsi";
	}

	goToNewProject() {
		return "/new-project";
	}

	goToMySearch(projectId, searchCardId) {
		let url = `/projects/${projectId}/search/my-search`;
		if (searchCardId) {
			const params = createSearchParams({ searchCardId });
			url += `?${params}`;
		}
		return url;
	}

	goToSearchForAutomation(projectId, searchCardId) {
		let url = `/projects/${projectId}/search/search-for-automation`;
		if (searchCardId) {
			const params = createSearchParams({ searchCardId });
			url += `?${params}`;
		}
		return url;
	}

	goToTeam(projectId) {
		return `/projects/${projectId}/team`;
	}

	goToAdmin() {
		return "/admin";
	}

	goToProjectCategories(projectId) {
		return `/projects/${projectId}/project-categories`;
	}

	goToReviewByCategory({ projectId, categoryId, attached }) {
		if (categoryId && attached) {
			return `/projects/${projectId}/synthesis-by-category?categoryId=${categoryId}&attached=${attached}`;
		}
		return `/projects/${projectId}/synthesis-by-category`;
	}

	goToProjectRequirements(projectId) {
		return `/projects/${projectId}/review/project-requirements`;
	}

	goToVerificationOfDetection(projectId) {
		return `/projects/${projectId}/review/verification-of-detections`;
	}

	goToMySearchTemplate(templateId, projectId) {
		const params = createSearchParams({ templateId, projectId });

		if (templateId || projectId) {
			return `/search-templates/search-templates?${params}`;
		}
		return "/search-templates/search-templates";
	}

	goToCompanySearchTemplate(templateId, projectId) {
		const params = createSearchParams({ templateId, projectId });

		if (templateId || projectId) {
			return `/search-templates/search-for-automation-templates?${params}`;
		}
		return "/search-templates/search-for-automation-templates";
	}

	goToQA(projectId = "") {
		return `/projects/${projectId}/follow-up/question-to-the-client`;
	}

	goToProjectStandards(projectId = "") {
		return `/projects/${projectId}/follow-up/project-standards`;
	}

	goToAssignToDeliverables(projectId = "") {
		return `/projects/${projectId}/follow-up/deliverables/assign-to-deliverables`;
	}

	goToDeliverablesToBeProduced(projectId = "") {
		return `/projects/${projectId}/follow-up/deliverables/deliverables-to-be-produced`;
	}

	goToCategoryTemplate() {
		return "/category-template";
	}

	goToOpportunity() {
		return "/opportunity";
	}

	goToChatProject(projectId) {
		return `/projects/${projectId}/chat-project`;
	}
}
export default new NavUtils();
