import { Accordion, AccordionDetails, AccordionSummary, Checkbox } from "@mui/material";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomIconButton, CustomTooltip, icon, CustomButton } from "..";
import { setCrossFilters } from "../../slice";
import { NavUtils } from "../../../navigation";
import { generateGreyShade } from "../../../views/admin-categories/utils/utils";
import styles from "./ExpandableCategoryRow.module.css";
import { isSegFeatureEnabled, SegFlags, translate } from "../../providers";
import CategoryContents from "./CategoryContents";
import { isNonEmptyArray } from "../../utils";

const getRedirectState = (catId, documentIds) => ({
	state: {
		filters: {
			categories: {
				matchAll: false,
				is: true,
				CATEGORY: [catId],
				FAVORITE: [],
			},
			isFilterTocOut: false,
			documents: {
				is: true,
				matchAll: true,
				elements: documentIds,
			},
			separator: "AND",
			Type: "InformationSearchFilterDTO",
		},
	},
});

const getChildrensCounts = (category, favCategories, counters = { selected: 0, total: 0 }) => {
	let tempCounters = { ...counters };
	if (!Array.isArray(category.subThematic) || category.subThematic.length === 0) {
		return counters;
	}
	category.subThematic.forEach((cat) => {
		tempCounters.total++;
		if (favCategories?.some((favCat) => favCat.id === cat.id)) {
			tempCounters.selected += 1;
		}
		tempCounters = getChildrensCounts(cat, favCategories, tempCounters);
	});
	return tempCounters;
};

export default function ExpandableCategoryRow({
	category,
	className = "",
	disableCheckboxes = false,
	expanded = false,
	indentLevel = 0,
	isAdminCategories = false,
	isEditable = false,
	isManaging = false,
	isRowChecked,
	isSubscription = false,
	onCheck,
	onClickAdd,
	onClickAddAction,
	onClickDelete,
	onClickEdit,
	onOpenUsersList,
	onSubscribe,
	onToggleExpand,
	onUnsubscribe,
	projectId,
}) {
	const favoriteCategories = useSelector(({ context }) => context.favoriteCategories.categories);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isSubscribed = useMemo(() => {
		if (!isSubscription) {
			return false;
		}
		return favoriteCategories.some((favCategory) => favCategory.id === category.id);
	}, [isSubscription, favoriteCategories, category.id]);
	const categoryCounter = useMemo(
		() => getChildrensCounts(category, favoriteCategories),
		[category, favoriteCategories]
	);
	let allowExpand = true;
	const handleChange = () => {
		setTimeout(() => {
			if (allowExpand && (category.childrenCount > 0 || category.subThematic?.length > 0)) {
				onToggleExpand(category);
			} else {
				allowExpand = true;
			}
		}, 0);
	};
	const preventExpand = () => {
		allowExpand = false;
	};
	const handleClickAdd = () => {
		preventExpand();
		onClickAdd(category.id);
	};
	const handleClickEdit = () => {
		preventExpand();
		onClickEdit(category);
	};
	const handleClickDelete = () => {
		preventExpand();
		onClickDelete(category);
	};
	const handleClickAddContent = () => {
		preventExpand();
		if (isSegFeatureEnabled(SegFlags.SMART_SEARCH)) {
			navigate(NavUtils.goToMySearch(projectId));
		}
	};
	const handleCheck = (event) => {
		preventExpand();
		onCheck(category, event.target.checked);
	};
	const handleClickCounts = () => {
		preventExpand();
		dispatch(
			setCrossFilters({
				categories: {
					elements: [category.id],
					is: true,
					matchAll: false,
				},
			})
		);
		if (isSegFeatureEnabled(SegFlags.SMART_SEARCH)) {
			navigate(NavUtils.goToMySearch(projectId), getRedirectState(category.id, category.documentIds));
		}
	};
	const handleOpenUsersList = () => {
		preventExpand();
		onOpenUsersList(category);
	};
	const handleSubscribtion = (e) => {
		if (isSubscribed) {
			onUnsubscribe(e, category);
		} else {
			onSubscribe(e, category);
		}
	};
	const hasSubCategories = isNonEmptyArray(category.subThematic) || category.childrenCount > 0;
	const disabledTextColor = generateGreyShade(180, indentLevel);
	return (
		<Accordion
			classes={{
				root: styles.accordion__root,
			}}
			className={className}
			expanded={expanded}
			style={{
				marginLeft: indentLevel > 0 ? 32 : 0,
			}}
			onChange={handleChange}
		>
			<AccordionSummary
				classes={{
					root: styles.accordionSummary__root,
					content: styles.accordionSummary__content,
				}}
				style={{
					cursor: hasSubCategories ? "pointer" : "default",
				}}
			>
				<div className={styles.row__leftItems}>
					<div className={styles.expandButtonContainer}>
						{hasSubCategories && (
							<CustomIconButton icon={expanded ? icon.faChevronDown : icon.faChevronRight} />
						)}
					</div>
					{!disableCheckboxes && isRowChecked && (
						<Checkbox checked={isRowChecked(category)} color="primary" onChange={handleCheck} />
					)}
					<CustomTooltip showWhenOverflow title={category.name}>
						<div
							className={`${styles.row__name} ${
								isSubscription && !hasSubCategories && styles["row__name--regular"]
							}`}
							data-testid={`cat.tree.${category.id}`}
							style={{
								width: `${250 - 32 * indentLevel}px`,
								color:
									category.filtered === undefined || category.filtered
										? "var(--color-black)"
										: disabledTextColor,
							}}
						>
							{category.name}&nbsp;
							{hasSubCategories && isManaging && (
								<>
									({categoryCounter.selected}/{categoryCounter.total})
								</>
							)}
						</div>
					</CustomTooltip>
				</div>
				<div className={styles.row__rightItems} data-issubscription={isSubscription}>
					{(!isSubscription && (
						<>
							<div
								className={styles.rightItems__description}
								style={{
									color:
										category.filtered === undefined || category.filtered
											? "var(--color-black)"
											: disabledTextColor,
								}}
							>
								{category.description}
							</div>
							{isAdminCategories && isSegFeatureEnabled(SegFlags.FAVORITE_CATEGORIES) && (
								<div className={styles.rightItems__subscriptions}>
									<CustomButton onClick={handleOpenUsersList}>
										<CustomTooltip
											showWhenOverflow
											title={translate("common:expandable-category-row.subscribed-employees", {
												userCount: category.usersSubscribed,
											})}
										>
											<div className={styles.subscriptionsButton__text}>
												{translate("common:expandable-category-row.subscribed-employees", {
													userCount: category.usersSubscribed,
												})}
											</div>
										</CustomTooltip>
									</CustomButton>
								</div>
							)}

							<div className={styles.counterContainer} data-testid={`cat.tree.${category.id}.status`}>
								{translate((category.actionCount > 0 && "common:btn.yes") || "common:btn.no")}
							</div>

							{!!projectId && (
								<div
									className={styles.rightItems__contents}
									data-testid={`cat.tree.${category.id}.content`}
								>
									<CategoryContents
										contentsCount={category.contentsCount}
										documentsCount={category.documentsCount}
										hierarchyLevel={indentLevel}
										onClickAddContent={handleClickAddContent}
										onClickCounts={handleClickCounts}
									/>
								</div>
							)}

							{isEditable && (
								<div
									className={styles.rightItems__buttonsGroup}
									data-testid={`cat.tree.${category.id}.btn`}
								>
									<div className={styles.buttonsGroup__plusButtonContainer}>
										{indentLevel < 4 && (
											<CustomIconButton
												data-testid={`addSubCategory_${category.id}`}
												icon={icon.faPlus}
												size="sm"
												tooltip={translate("common:expandable-category-row.add")}
												variant="outlined"
												onClick={handleClickAdd}
											/>
										)}
									</div>
									<CustomIconButton
										data-testid={`editCategory_${category.id}`}
										icon={icon.faPenToSquare}
										size="sm"
										tooltip={translate("common:expandable-category-row.edit")}
										variant="outlined"
										onClick={handleClickEdit}
									/>
									<CustomIconButton
										data-testid={`deleteCategory_${category.id}`}
										icon={icon.faTrash}
										size="sm"
										tooltip={translate("common:expandable-category-row.delete")}
										variant="outlined"
										onClick={handleClickDelete}
									/>
								</div>
							)}
						</>
					)) || (
						<CustomButton
							color={isSubscribed ? "primary" : "secondary"}
							variant={isSubscribed ? "contained" : "outlined"}
							onClick={handleSubscribtion}
						>
							{isSubscribed
								? translate("common:expandable-category-row.subscribed")
								: translate("common:expandable-category-row.subscribe")}
						</CustomButton>
					)}
				</div>
			</AccordionSummary>
			{hasSubCategories && (
				<AccordionDetails
					classes={{
						root: styles.accordionDetails__root,
					}}
				>
					{Array.isArray(category.subThematic) &&
						category.subThematic.map((subCategory) => (
							<ExpandableCategoryRow
								key={subCategory.id}
								category={subCategory}
								disableCheckboxes={disableCheckboxes}
								expanded={
									(subCategory.filtered === false && subCategory.expanded === undefined) ||
									subCategory.expanded
								}
								indentLevel={indentLevel + 1}
								isAdminCategories={isAdminCategories}
								isEditable={isEditable}
								isRowChecked={isRowChecked}
								isSubscription={isSubscription}
								projectId={projectId}
								onCheck={onCheck}
								onClickAdd={onClickAdd}
								onClickAddAction={onClickAddAction}
								onClickAddContent={handleClickAddContent}
								onClickDelete={onClickDelete}
								onClickEdit={onClickEdit}
								onOpenUsersList={onOpenUsersList}
								onSubscribe={onSubscribe}
								onToggleExpand={onToggleExpand}
								onUnsubscribe={onUnsubscribe}
							/>
						))}
				</AccordionDetails>
			)}
		</Accordion>
	);
}
