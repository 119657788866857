import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { ApiService, CategoryService, CategoryTemplateService } from "../../../api";
import {
	OptionsPanelContainer,
	SidePanel,
	SidePanelContent,
	CategoryTemplateSelectionList,
	CategorySelectionList,
} from "../../../common/components";
import { translate } from "../../../common/providers";
import styles from "./ImportTransferCategorySidePanel.module.css";

export default function ImportTransferCategorySidePanel({ importing = false, open, onClose, onSubmit, title }) {
	const [checkedIds, setCheckedIds] = useState([]);
	const cancelTokenSourceRef = useRef(null);
	const project = useSelector(({ context }) => context.project);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	const handleClickAdd = () => {
		if (importing) {
			CategoryTemplateService.importIntoProject(
				{ projectId: project.id },
				{ ids: checkedIds },
				cancelTokenSourceRef.current.token
			)
				.then(() => {
					onSubmit();
				})
				.catch(console.error);
		} else {
			CategoryService.transferProjectThematicSelection(
				{ thematicList: checkedIds },
				cancelTokenSourceRef.current.token
			)
				.then(() => {
					onSubmit();
				})
				.catch(console.error);
		}
	};

	const handleClose = () => {
		setCheckedIds([]);
		onClose();
	};

	return (
		<SidePanel open={open} size={50} onClose={handleClose}>
			<SidePanelContent title={title} onClose={handleClose}>
				<OptionsPanelContainer
					buttonText={translate(
						checkedIds.length > 1
							? "common:categories.import-panel.add-categories-btn"
							: "common:categories.import-panel.add-categorie-btn",
						{ categoriesCount: checkedIds.length }
					)}
					className={styles.panelContainer}
					disabledPrimaryButton={checkedIds.length === 0}
					title={translate("common:categories.import-panel.categories")}
					onClick={handleClickAdd}
				>
					{(importing && (
						<CategoryTemplateSelectionList checkChildren onChangeCheckedIds={setCheckedIds} />
					)) || (
						<CategorySelectionList
							canSelectAll
							checkChildren
							isEditable
							projectId={project.id}
							onChangeCheckedIds={setCheckedIds}
						/>
					)}
				</OptionsPanelContainer>
			</SidePanelContent>
		</SidePanel>
	);
}
