import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomButtonLink, CustomIconButton, icon, IconComponent } from "..";
import { ApiService } from "../../../api";
import projectTeamService from "../../../api/services/project/project-team-service";
import { NavUtils } from "../../../navigation";
import { hasPermission, isSegFeatureEnabled, SegFlags, translate } from "../../providers";
import permissions from "../../providers/permissions/permissions";
import { setCautionBannerDisabled } from "../../slice";
import styles from "./NoRoleOnDocumentBanner.module.css";

export default function NoRoleOnDocumentBanner() {
	const cautionBannerDisabled = useSelector(({ context }) => context.cautionBannerDisabled);
	const [hasRole] = useState(hasPermission(permissions.PROJECT_LEADER) || hasPermission(permissions.PROJECT_MANAGER));
	const [isThereNoDocRole, setIsThereNoDocRole] = useState(false);
	const companyId = useSelector(({ context }) => context.company?.id);
	const projectId = useSelector(({ context }) => context.project.id);
	const dispatch = useDispatch();
	useEffect(() => {
		const cancelToken = ApiService.getCancelTokenSource();
		if (projectId && companyId && hasRole && isSegFeatureEnabled(SegFlags.RIGHTS_ON_DOCUMENTS)) {
			projectTeamService
				.getUsersCountNoRole({ projectId }, cancelToken.token)
				.then(({ value }) => {
					setIsThereNoDocRole(value);
					dispatch(setCautionBannerDisabled(!value));
				})
				.catch((err) => console.error(err));
		}
		return () => {
			ApiService.cancelTokens(cancelToken);
		};
	}, [companyId, dispatch, projectId, hasRole]);
	const disableBanner = () => {
		dispatch(setCautionBannerDisabled(true));
	};
	return (
		(!cautionBannerDisabled &&
			hasRole &&
			isThereNoDocRole &&
			isSegFeatureEnabled(SegFlags.RIGHTS_ON_DOCUMENTS) &&
			isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS) && (
				<header className={styles.container}>
					<div>
						<IconComponent color="var(--color-dark-grey-2)" icon={icon.faExclamationTriangle} />
						<span>{translate("common:components.caution-banner.no-review-role.description")}</span>
						<CustomButtonLink
							buttonClassName={styles.configureButton}
							to={NavUtils.goToRightsOnDocuments(projectId)}
							onClick={() => disableBanner()}
						>
							{translate("common:components.caution-banner.no-review-role.link")}
						</CustomButtonLink>
					</div>
					<span>
						<CustomIconButton icon={icon.faTimes} onClick={() => disableBanner()} />
					</span>
				</header>
			)) ||
		null
	);
}
