import { createSlice } from "@reduxjs/toolkit";
import { updateToc, updateTocNode } from "../utils";
import { getRootTransverse, getClauseTransverse } from "./project-document-thunks";
import { OPERATORS } from "../../../../common/constants";
import smartReviewContext from "../../context/smart-review-context";

export const ROOT_DIR = "root";

const updateMultiDoc = (action, state) => ({
	multiDocs: {
		...action.payload,
		...((action.payload.pageNumber > state.multiDocs.pageNumber && {
			contents: [...(state.multiDocs?.contents || []), ...action.payload.contents],
		}) ||
			{}),
	},
});

const initFilter = { separator: OPERATORS.AND, Type: "QuickAccessSearchFilterDTO" };

const initialState = {
	location: [{ id: ROOT_DIR, label: null }],
	documents: {
		precedence: [],
	},
	multiDocs: {},
	loadingMultiDocs: false,
	temporaryQaSearchIndex: { index: 0, scrollTo: false },
	isThereAnotherFilter: false,
	isThereAnotherTransverseFilter: false,
	filters: initFilter,
	transverseFilters: initFilter,
	mode: "precedence" /* [role, folder, precedence] */,
	clauseId: null,
	isPdfScrolling: true,
	isHandleScroll: true,
	isContextLoadedToRedux: false,
	expandedIds: [],
	selectedDocuments: [],
	isFilterLoadedFromLocal: false,
	projectCommentFilters: {},
	myReviewLeftPanelWidth: smartReviewContext.getMyReviewLeftPanel() || 20,
	myReviewRightPanelWidth: smartReviewContext.getMyReviewRightPanel() || 37,
	openMultiToc: smartReviewContext.getOpenMultiTOC(),
};

const srProjectDocumentsSlice = createSlice({
	name: "srProjectDocument",
	initialState,
	reducers: {
		setProjectCommentFilters: (state, { payload }) => ({ ...state, projectCommentFilters: payload }),
		setIsFilterLoadedFromLocal: (state, { payload }) => ({ ...state, isFilterLoadedFromLocal: payload }),
		setTemporaryQaSearchIndex: (state, { payload }) => ({ ...state, temporaryQaSearchIndex: payload }),
		setFilters: (state, { payload }) => ({
			...state,
			filters: payload || initFilter,
			isThereAnotherFilter: Object.keys(payload || initFilter).some(
				(key) => key !== "documents" && key !== "Type" && key !== "separator"
			),
		}),
		setTransverseFilters: (state, { payload }) => ({
			...state,
			transverseFilters: payload || initFilter,
			isThereAnotherTransverseFilter: Object.keys(payload || initFilter).some(
				(key) => key !== "documents" && key !== "Type" && key !== "separator"
			),
		}),
		setMode: (state, { payload }) => ({ ...state, mode: payload }),
		setSelectedDocuments: (state, { payload }) => ({ ...state, selectedDocuments: payload }),
		setUserDocumentDetails: (state, { payload }) => ({ ...state, currentDocDetails: payload }),
		setClauseId: (state, { payload }) => ({ ...state, clauseId: payload }),
		setIsPdfScrolling: (state, { payload }) => ({ ...state, isPdfScrolling: payload }),
		setIsHandleScroll: (state, { payload }) => ({ ...state, isHandleScroll: payload }),
		setExpandedIds: (state, { payload }) => ({ ...state, expandedIds: payload }),
		setIsContextLoadedToRedux: (state, { payload }) => ({ ...state, isContextLoadedToRedux: payload }),
		setAllPrecedences: (state, { payload }) => ({
			...state,
			documents: { ...state.documents, precedence: payload },
		}),
		setMyReviewLeftPanel: (state, { payload }) => {
			smartReviewContext.setMyReviewLeftPanel(payload);
			return { ...state, myReviewLeftPanelWidth: payload };
		},
		setOpenMultiToc: (state, { payload }) => {
			smartReviewContext.setOpenMultiTOC(payload);
			return { ...state, openMultiToc: payload };
		},
		setMyReviewRightPanel: (state, { payload }) => {
			smartReviewContext.setMyReviewRightPanel(payload);
			return { ...state, myReviewRightPanelWidth: payload };
		},
		setOpenDocumentNode: (state, { payload }) => ({
			...state,
			multiDocs: {
				...state.multiDocs,
				contents: state.multiDocs.contents.map(
					(md) =>
						(md.id === payload && {
							...md,
							open: !md.open,
						}) ||
						md
				),
			},
		}),
		setMultiDocs: (state, { payload }) => ({
			...state,
			...updateMultiDoc({ payload }, state),
		}),
		setUpdateTocNode: (state, { payload }) => {
			const { documentId, clauseId, propName, value } = payload;
			return {
				...state,
				multiDocs: {
					...state.multiDocs,
					contents: state.multiDocs.contents.map(
						(md) =>
							(md.id === documentId && {
								...md,
								subClauses: md.subClauses.map(
									(clause) =>
										(clause.id === clauseId && { ...clause, open: !clause.open }) || {
											...clause,
											...((Array.isArray(clause.subClauses) && {
												subClauses: updateTocNode(clause.subClauses, clauseId, {
													propName,
													value,
												}),
											}) ||
												{}),
										}
								),
							}) ||
							md
					),
				},
			};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getRootTransverse.fulfilled, (state, action) => {
			const { documentId } = action.meta.arg;
			return {
				...state,
				multiDocs: {
					...state.multiDocs,
					contents: state.multiDocs.contents.map(
						(md) => (md.id === documentId && { ...md, subClauses: action.payload }) || md
					),
				},
			};
		});
		builder.addCase(getClauseTransverse.fulfilled, (state, action) => {
			const { documentId, clauseId } = action.meta.arg;
			return {
				...state,
				multiDocs: {
					...state.multiDocs,
					contents: state.multiDocs.contents.map(
						(md) =>
							(md.id === documentId && {
								...md,
								subClauses: updateToc(md.subClauses, action.payload, clauseId),
							}) ||
							md
					),
				},
			};
		});
		builder.addMatcher(
			({ type }) => typeof type === "string" && type.endsWith("rejected"),
			(_, { error }) => {
				console.error(error.message);
			}
		);
	},
});

export { getRootTransverse, getClauseTransverse };
export const {
	setProjectCommentFilters,
	setTransverseFilters,
	setFilters,
	setSelectedDocuments,
	setAllPrecedences,
	setMode,
	setUserDocumentDetails,
	setOpenDocumentNode,
	setUpdateTocNode,
	setMultiDocs,
	setOpenMultiToc,
	setTemporaryQaSearchIndex,
	setClauseId,
	setIsPdfScrolling,
	setIsHandleScroll,
	setExpandedIds,
	setIsContextLoadedToRedux,
	setIsFilterLoadedFromLocal,
	setMyReviewLeftPanel,
	setMyReviewRightPanel,
} = srProjectDocumentsSlice.actions;
export default srProjectDocumentsSlice.reducer;
