import React, { useState } from "react";
import { Paper, Fab, CircularProgress } from "@mui/material";
import { CustomButton, icon, IconComponent } from "../../../../../../common/components";
import styles from "./RedressMove.module.css";

export default function RedressMove({ handleMove, loading, REDRESS_ACTION, tocList }) {
	const [select, setSelected] = useState("");
	const [contentId, setContentId] = useState(null);

	const handleMoveContent = (action) => {
		if (contentId != null) {
			handleMove(contentId, action);
		}
		setSelected("");
		setContentId(null);
	};

	const handleClick = (id, key) => {
		if (id === null || key === null) {
			return;
		}
		if (select === key) {
			setSelected("");
			setContentId(null);
		} else {
			setSelected(key);
			setContentId(id);
		}
	};

	const runSubClauses = (subClauses, parentKey, level) => {
		if (subClauses === null || !Array.isArray(subClauses) || subClauses.length < 1) {
			return null;
		}

		return subClauses.map((toc) => {
			const childKey = `${parentKey + toc.id}-`;
			return (
				<div key={childKey} className={styles.container} style={{ paddingLeft: `${level * 10}px` }}>
					<CustomButton
						color="secondary"
						data-testid="toc.subContent"
						variant={select && (childKey.startsWith(select) || select === parentKey) ? "contained" : "text"}
						onClick={() => handleClick(toc.id, childKey)}
					>
						{toc.content}
					</CustomButton>
					{runSubClauses(toc.subClauses, childKey, level + 1)}
				</div>
			);
		});
	};

	return (
		<Paper className={styles.container}>
			{loading ? (
				<CircularProgress className={styles.circularProgress} size={80} />
			) : (
				<>
					{select && (
						<Fab
							aria-label="redress-left"
							className={styles.fabLeft}
							color="primary"
							data-testid="redress-left"
							size="medium"
							onClick={() => handleMoveContent(REDRESS_ACTION[0])}
						>
							<IconComponent icon={icon.faCaretLeft} />
						</Fab>
					)}
					{Array.isArray(tocList) &&
						tocList.map((toc) => {
							const key = `root${toc.id}-`;
							return (
								<div key={key} className={styles.container}>
									<CustomButton
										color="secondary"
										data-testid="toc.content"
										variant={select && select === key ? "contained" : "text"}
										onClick={() => handleClick(toc.id, key)}
									>
										{toc.content}
									</CustomButton>
									{runSubClauses(toc.subClauses, key, 1)}
								</div>
							);
						})}
					{select && (
						<Fab
							aria-label="redress-right"
							className={styles.fabRight}
							color="primary"
							data-testid="redress-right"
							size="medium"
							onClick={() => handleMoveContent(REDRESS_ACTION[1])}
						>
							<IconComponent icon={icon.faCaretRight} />
						</Fab>
					)}
				</>
			)}
		</Paper>
	);
}
