import React from "react";
import { CustomIconButton } from "../../buttons";
import { icon } from "../../icon-component";
import styles from "./SideDrawerMain.module.css";
import Resizer from "../../resizer/Resizer";

export default function SideDrawerMain({
	DrawerComponent,
	DrawerHeader = null,
	children,
	className = "",
	contentClassName = "",
	onOpenDrawer = null,
	openDrawer = false,
	wildCardClassName = "",
	wildcard = null,
	...props
}) {
	return (
		<div className={`${styles.layout} ${className}`}>
			{typeof onOpenDrawer === "function" ? (
				<div className={styles.layout__drawer} data-open={openDrawer}>
					{openDrawer && wildcard && <div className={wildCardClassName}>{wildcard()}</div>}
					<div className={styles.drawer__header}>
						<CustomIconButton
							className={styles.drawer__iconBtn}
							icon={(openDrawer && icon.faChevronLeft) || icon.faBars}
							size="sm"
							variant="outlined"
							onClick={onOpenDrawer}
						/>
						{openDrawer && DrawerHeader && <DrawerHeader />}
					</div>
					{openDrawer && <DrawerComponent {...props} />}
				</div>
			) : (
				<div data-open className={styles.layout__drawer}>
					<DrawerComponent {...props} />
				</div>
			)}
			<Resizer active={openDrawer} className={styles.resizer} max={30} min={20} />
			<div className={`${styles.layout__main} ${contentClassName}`}>{children}</div>
		</div>
	);
}
