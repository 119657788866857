import React, { useEffect, useRef } from "react";
import { MenuItem, MenuList } from "@mui/material";
import { useDispatch } from "react-redux";
import styles from "./DocumentTableRowStatus.module.css";
import { DOCUMENT_STATUS } from "../../constants/constants";
import { translate } from "../../../../common/providers";
import {
	CircularLoaderWithBackground,
	CustomIconButton,
	CustomMenuButton,
	icon,
	IconComponent,
} from "../../../../common/components";
import { ApiService, DocumentService } from "../../../../api";
import { useApi } from "../../../../common/hooks";
import { changeFailedDocumentStatus } from "../../slice/document-center-slice";

const DocumentTableRowStatus = ({ docId, docStatus, progression, onDelete }) => {
	const { call: callRetryExtract } = useApi(DocumentService.retryExtract);
	const failedRef = useRef(null);
	const cancelTokenSourceRef = useRef(null);
	const dispatch = useDispatch();
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const handleRetry = () => callRetryExtract({ docId });
	const handleChangeStatus = () => {
		dispatch(changeFailedDocumentStatus({ docId, token: cancelTokenSourceRef.current.token }));
	};
	const displayStatusIcon = () => {
		if (docStatus === DOCUMENT_STATUS.IN_PROGRESS) {
			return (
				<>
					<div className={`${styles.file__loader} ${styles["file__loader--visible"]}`}>
						<CircularLoaderWithBackground
							size={16}
							thickness={10}
							value={progression || 0}
							variant="determinate"
						/>
					</div>
					<div className={`${styles.file__loader} ${styles["file__loader--hidden"]}`}>
						<CustomIconButton
							disableHovering
							btnClassName={styles.file__iconBtn}
							icon={icon.faTimes}
							iconColor="var(--color-black)"
							tooltip={translate("document-center.upload-dialog.file.delete")}
							onClick={() => {
								onDelete(docId);
							}}
						/>
					</div>
				</>
			);
		}
		if (docStatus === DOCUMENT_STATUS.TIMEOUT || docStatus === DOCUMENT_STATUS.FAILED) {
			return (
				<>
					<CustomMenuButton ref={failedRef} icon={icon.faRedoAlt} size="sm" variant="outlined">
						<MenuList ref={failedRef} className={styles.menuList}>
							<MenuItem className={styles.menuItem} onClick={handleRetry}>
								<IconComponent
									className={styles.menuIcon}
									color="var(--color-light-grey-1)"
									icon={icon.faRedoAlt}
								/>
								<span>{translate("document-center.upload-dialog.file.retry")}</span>
							</MenuItem>
							<MenuItem className={styles.menuItem} onClick={handleChangeStatus}>
								<IconComponent
									className={styles.menuIcon}
									color="var(--color-light-grey-1)"
									icon={icon.faFileRegular}
								/>
								<span>{translate("document-center.upload-dialog.file.change-type")}</span>
							</MenuItem>
						</MenuList>
					</CustomMenuButton>
				</>
			);
		}
		return null;
	};
	return (
		<div
			className={styles.container}
			role="presentation"
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			{displayStatusIcon()}
		</div>
	);
};

export default DocumentTableRowStatus;
