import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { FavoriteCategoriesDialog, Resizer, ViewBanner } from "../../common/components";
import { AnalyticsProvider, Flags, translate, SegFlags, isSegFeatureEnabled } from "../../common/providers";
import { exportView } from "../../navigation";
import { MainHeader, ReviewHeader, Dialogs, EmptyState } from "./components";
import {
	getLinkTypes,
	getTransverseInformation,
	resetDocument,
	setHideFusionDialogDisplay,
	setTemporaryDocument,
} from "./slice/document/document-slice";
import styles from "./MyReview.module.css";
import { ApiService } from "../../api";
import MyReviewContent from "./MyReviewContent";
import { debounce, isDeepEqual, isNonEmptyArray } from "../../common/utils";
import { generatePayload } from "./utils/utils";
import { resetPdf } from "./slice/pdf/pdf-slice";
import { QA_FILTER_LIMIT } from "./constants/constants";
import Drawer from "./components/drawer/Drawer";
import { usePrevious } from "../../common/hooks";
import {
	setClauseId,
	setExpandedIds,
	setMyReviewLeftPanel,
	setOpenMultiToc,
	setTemporaryQaSearchIndex,
} from "./slice/project-document/project-document-slice";
import { OPERATORS } from "../../common/constants";
import { KEY_SMART_VIEW } from "../../common/providers/keys/keys";
import MyReviewRedressmentModal from "./components/modals/MyReviewRedressmentModal";

const debounceFunc = debounce((func) => func(), 1000);

const clearDocumentsIds = (obj) => {
	const { documentsIds, ...rest } = obj;
	if (isNonEmptyArray(documentsIds)) {
		return obj;
	}
	return rest;
};

const MyReview = () => {
	const [isRedressmentModalOpen, setIsRedressmentModalOpen] = useState(false);
	const leftPanelWidth = useSelector(({ srProjectDocument }) => srProjectDocument.myReviewLeftPanelWidth);
	const openDrawer = useSelector(({ srProjectDocument }) => srProjectDocument.openMultiToc);
	const selectedMenu = useSelector(({ srPdf }) => srPdf.selectedMenu);
	const cautionBannerDisabled = useSelector(({ context }) => context.cautionBannerDisabled);
	const precedences = useSelector(({ srProjectDocument }) => srProjectDocument.documents.precedence);
	const checkedNoDoc = useSelector(({ srDocument }) => srDocument.checkedNoDoc);
	const filters = useSelector(({ srProjectDocument }) => srProjectDocument.filters);
	const projectId = useSelector(({ context }) => context?.project?.id);
	const qaTotalResult = useSelector(({ srDocument }) => srDocument.qaMetadata?.totalElements);
	const isThereAnyDocument = useMemo(() => precedences.some((precedence) => precedence.count > 0), [precedences]);
	const cancelTokenSourceRef = useRef();
	const dispatch = useDispatch();
	const params = useParams();
	const location = useLocation();

	useEffect(() => {
		const documentTitle = translate("my-review.document.title");
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "My review" });
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	useEffect(() => {
		dispatch(setHideFusionDialogDisplay(false));
	}, [dispatch]);

	/*
	 * Handling Quick Access and Multi Toc
	 */
	const prevFilters = usePrevious(filters);
	useEffect(
		() => {
			if (
				projectId &&
				prevFilters &&
				filters &&
				!isDeepEqual(clearDocumentsIds(filters), clearDocumentsIds(prevFilters))
			) {
				const urlDocId = Number.parseInt(params?.docId, 10);
				const urlPage = Number.parseInt(new URLSearchParams(location.search).get("page"), 10);
				const isUrlIn = (filters?.documentsIds || []).some((doc) => doc.id === urlDocId);
				debounceFunc(() => {
					if (Object.keys(filters).length === 1 && isNonEmptyArray(filters?.documentsIds)) {
						dispatch(resetPdf());
						dispatch(resetDocument());
						dispatch(
							setTemporaryDocument({
								id: (isUrlIn && urlDocId) || filters.documentsIds[0].id,
								fromToc: true,
								coordinates: { pageStart: urlPage || 1, y1: 0.01 },
							})
						);
					} else {
						dispatch(setClauseId(null));
						dispatch(setExpandedIds([]));
						const contextItems = JSON.parse(window.sessionStorage.getItem(KEY_SMART_VIEW)) || {};
						const selectedContentFromLocal =
							(contextItems?.selectedContent ?? 0) - 1 <= 0
								? 0
								: (contextItems?.selectedContent ?? 0) - 1;
						const finalF =
							!filters || Object.keys(filters).length === 0
								? { separator: OPERATORS.AND, Type: "REQ" }
								: filters;
						if (Object.keys(filters).some((key) => key !== "separator" && key !== "Type")) {
							const redirection = Object.keys(filters).length !== Object.keys(prevFilters).length - 1;
							dispatch(
								getTransverseInformation({
									projectId,
									page: 0,
									limit: QA_FILTER_LIMIT,
									payload: generatePayload(finalF),
									token: cancelTokenSourceRef.current.token,
									currentSearchIndex: selectedContentFromLocal || 0,
									redirection,
								})
							)
								.then((data) => {
									dispatch(
										setTemporaryQaSearchIndex({
											index: contextItems?.selectedContent,
											scrollTo: true,
										})
									);
									const { contents } = data.payload;
									if (
										!urlDocId &&
										contents?.[0] &&
										Object.keys(finalF).some((key) => key !== "documentsIds")
									) {
										dispatch(setTemporaryDocument({ id: contents[0]?.documentId }));
									}
								})
								.catch((err) => console.error(err));
						}
					}
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch, projectId, prevFilters, filters]
	);
	const handleOpenDrawer = () => {
		dispatch(setOpenMultiToc(!openDrawer));
	};

	const HeaderOptions = isThereAnyDocument && <MainHeader />;

	const linkCancelTokenSourceRef = useRef(null);

	useEffect(
		() => {
			linkCancelTokenSourceRef.current = ApiService.getCancelTokenSource();
			if (isSegFeatureEnabled(SegFlags.LINKS)) {
				dispatch(getLinkTypes({ projectId, token: linkCancelTokenSourceRef.current.token }));
			}
			return () => {
				ApiService.cancelTokens(linkCancelTokenSourceRef.current);
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const toggleRedressmentModal = () => {
		setIsRedressmentModalOpen(!isRedressmentModalOpen);
	};
	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.repeat) {
				return;
			}
			if (event.ctrlKey && event.key === "0") {
				setIsRedressmentModalOpen(!isRedressmentModalOpen);
			}
		};

		document.addEventListener("keydown", handleKeyDown);
		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [isRedressmentModalOpen]);
	const handleResize = (newWidth) => {
		dispatch(setMyReviewLeftPanel(newWidth));
	};
	return (
		<div className={styles.myReview} data-caution-banner-disabled={cautionBannerDisabled}>
			<ViewBanner
				className={styles.banner}
				options={[HeaderOptions]}
				titles={[
					{ title: translate("navigation:project.review"), key: "review" },
					{ title: translate("navigation:project.my-review") },
				]}
			/>
			<ReviewHeader selectedMenu={selectedMenu} />
			<div className={styles.mainDisplay} data-caution-banner-disabled={cautionBannerDisabled}>
				<div
					className={styles.sideDrawer__main}
					data-open={openDrawer}
					style={{ width: `${openDrawer && leftPanelWidth}vw` }}
				>
					<Drawer open={openDrawer} onOpen={handleOpenDrawer} />
				</div>
				<Resizer
					isGlobalWidth
					active={openDrawer}
					className={styles.resizer}
					max={25}
					min={15}
					onResize={handleResize}
				/>
				<div
					className={styles.siderDrawer__content}
					data-open={openDrawer}
					style={
						openDrawer
							? { width: `calc(${100 - (openDrawer ? leftPanelWidth : 0)}vw - ${openDrawer ? 0 : 50}px)` }
							: {}
					}
				>
					{((!checkedNoDoc || qaTotalResult > 0) && <MyReviewContent />) || <EmptyState />}
				</div>
			</div>
			<Dialogs />
			<MyReviewRedressmentModal isOpen={isRedressmentModalOpen} onClose={toggleRedressmentModal} />
			{isSegFeatureEnabled(SegFlags.FAVORITE_CATEGORIES) && <FavoriteCategoriesDialog />}
		</div>
	);
};

export { default as srProjectDocumentsSlice } from "./slice/project-document/project-document-slice";
export { default as srPdfSlice } from "./slice/pdf/pdf-slice";
export { default as srDocumentSlice } from "./slice/document/document-slice";

export default exportView({
	path: [{ path: "/projects/:projectId/review/my-review/" }, { path: ":docId/*" }],
	component: MyReview,
	localesPath: "/my-review/locales",
	flag: Flags.REQ_REVIEW,
	segFlag: SegFlags.SMART_REVIEW,
});
