import React, { useState } from "react";
import { TableContainer, TablePagination } from "@mui/material";
import { SearchInput, CustomButtonLink } from "../../../../common/components";
import styles from "./DashPortfolio.module.css";
import { NavUtils } from "../../../../navigation";
import { CompanyType, environmentSwitch, isCompanyTypeAllowed, translate } from "../../../../common/providers";
import { debounce } from "../../../../common/utils";
import DashPortfolioTable from "./dash-portfolio-table/DashPortfolioTable";

const debounceFunc = debounce((func) => func(), 200);
export default function DashPortfolio({
	isAdminOrManager,
	onChangeCompany,
	onChangePage,
	onChangeRowsPerPage,
	onChangeTeam,
	onSetSearch,
	onJoinProject,
	onOpenSidePanel,
	onSelectProject,
	onSetIndex,
	page,
	projects,
	rowsPerPage,
	selectedRow,
	selectedTeams,
	onClickSort,
	sortDirection,
	sortKey,
	setCurrentProjectsPage,
}) {
	const [search, setSearch] = useState("");
	const handleChangeSearchValue = (e) => {
		const { value } = e.target;
		setSearch(value);
		setCurrentProjectsPage(0);
		debounceFunc(() => {
			onSetSearch(value);
		});
	};
	const handleClearSearchValue = () => {
		setSearch("");
		debounceFunc(() => {
			onSetSearch("");
		});
	};
	return (
		<div className={styles.container}>
			<div className={styles.newProjectButtonContainer}>
				<div>
					<SearchInput
						className={styles.searchContainer__textfield}
						label={translate("dashboards.dash-portfolio.textfield.search.label")}
						value={search}
						onChange={handleChangeSearchValue}
						onClearSearch={handleClearSearchValue}
					/>
				</div>
				{environmentSwitch({
					main: isAdminOrManager,
					veolia: isAdminOrManager,
					smac: true,
				}) &&
					isCompanyTypeAllowed([CompanyType.BUSINESS_UNIT, CompanyType.COMPANY]) && (
						<div>
							<CustomButtonLink
								buttonClassName={styles.newProjectButtonContainer__button}
								color="primary"
								data-testid="portfolio.newProjectBtn"
								size="md"
								to={NavUtils.goToNewProject()}
								variant="contained"
							>
								{translate("dashboards.dash-portfolio.button.new-project")}
							</CustomButtonLink>
						</div>
					)}
			</div>
			<div className={styles.superTableContainer}>
				<TableContainer className={styles.tableContainer}>
					<DashPortfolioTable
						projectList={projects.contents}
						selectedRow={selectedRow}
						selectedTeams={selectedTeams}
						sortDirection={sortDirection}
						sortKey={sortKey}
						onChangeCompany={onChangeCompany}
						onChangeTeam={onChangeTeam}
						onClickSort={onClickSort}
						onJoinProject={onJoinProject}
						onOpenSidePanel={onOpenSidePanel}
						onSelectRow={onSelectProject}
						onSetIndex={onSetIndex}
					/>
				</TableContainer>
				<TablePagination
					component="div"
					count={projects.totalElements}
					page={page}
					rowsPerPage={rowsPerPage}
					rowsPerPageOptions={[50, 100, 200]}
					onPageChange={onChangePage}
					onRowsPerPageChange={onChangeRowsPerPage}
				/>
			</div>
		</div>
	);
}
