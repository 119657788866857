import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styles from "./VerticalNavbar.module.css";
import { CustomIconButton, Resizer } from "..";
import { setMyReviewRightPanel } from "../../../views/my-review/slice/project-document/project-document-slice";

const VerticalNavbar = ({
	cautionBannerDisabled = false,
	className = "",
	displayContent = null,
	menus = [],
	menuContentClassName = null,
	onSelectMenu = null,
	open = false,
	position = "right",
	selectedMenu = 0,
}) => {
	const rightPanelWidth = useSelector(({ srProjectDocument }) => srProjectDocument.myReviewRightPanelWidth);
	const dispatch = useDispatch();
	const handleResize = (newWidth) => {
		dispatch(setMyReviewRightPanel(newWidth));
	};
	return (
		<>
			{open && (
				<Resizer
					isGlobalWidth
					isOnLeft
					additionalPixels={-114}
					className={styles.resizer}
					data-caution-banner-disabled={cautionBannerDisabled}
					idAddition="right"
					max={60}
					min={37}
					style={{
						right: `calc(${rightPanelWidth}vw - 114px)`,
					}}
					onResize={handleResize}
				/>
			)}
			<div
				className={`${styles.container} ${className}`}
				data-caution-banner-hidden={cautionBannerDisabled}
				data-open={open}
				data-position={position}
				style={(open && { width: `calc(${rightPanelWidth}vw - 114px)` }) || { width: "49px" }}
			>
				{open && typeof displayContent === "function" && (
					<div className={`${styles.container__menuContent} ${menuContentClassName}`}>{displayContent()}</div>
				)}
				<div className={styles.container__menus}>
					{Array.isArray(menus) &&
						menus.length > 0 &&
						menus
							.filter((m) => !m.disabled)
							.map((menu) => (
								<CustomIconButton
									key={menu.index}
									disableRipple
									badgeClassName={styles.button__badge}
									badgeContent={menu.counter}
									color={selectedMenu === menu.index ? "primary" : "secondary"}
									icon={menu.icon}
									tooltip={menu.name}
									tooltipPlacement="left"
									onClick={() => onSelectMenu(menu.index)}
								/>
							))}
				</div>
			</div>
		</>
	);
};

VerticalNavbar.propTypes = {
	position: PropTypes.oneOf(["right", "left"]),
};
VerticalNavbar.defaultProps = {
	position: "right",
};

export default VerticalNavbar;
