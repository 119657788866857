import React, { useEffect, useRef } from "react";
import { CellMeasurerCache, List } from "react-virtualized";
import ExpandableDocumentRow from "../expandable-document-row/ExpandableDocumentRow";
import { isNonEmptyArray } from "../../../../../../utils";
import { translate } from "../../../../../../providers";

const cache = new CellMeasurerCache({
	fixedWidth: true,
	defaultHeight: 32,
	minHeight: 32,
});
const getDocumentCountRecursive = (documents) => {
	let totalCount = documents.length;
	documents.forEach((document) => {
		if (isNonEmptyArray(document.documents) && document.expanded) {
			totalCount += getDocumentCountRecursive(document.documents);
		}
	});
	return totalCount;
};
const ExpandableDocumentList = ({
	documents,
	mode,
	parentCounters,
	selectedIds,
	onCheck,
	onToggleExpand,
	indentLevel = 0,
}) => {
	const listRef = useRef();
	const refreshRefCache = () => {
		cache.clearAll();
		listRef?.current?.recomputeRowHeights();
		listRef?.current?.forceUpdate();
		listRef?.current?.forceUpdateGrid();
	};
	useEffect(() => {
		window.addEventListener("resize", refreshRefCache);
		return () => {
			window.removeEventListener("resize", refreshRefCache);
		};
	}, []);
	useEffect(() => {
		refreshRefCache();
	}, [documents]);
	const rowRenderer = ({ index }) => {
		const document = documents[index];
		return (
			<ExpandableDocumentRow
				key={document.id || document.precedenceId || document.value}
				document={document}
				indentLevel={indentLevel}
				mode={mode}
				parentCounters={parentCounters}
				selectedIds={selectedIds}
				onCheck={onCheck}
				onToggleExpand={onToggleExpand}
			/>
		);
	};
	const noRowRenderer = () => <div>{translate("common:filters.document-tree.no-documents")}</div>;
	// Alternating row heights
	const getRowHeight = ({ index }) => {
		const currentDoc = documents[index];
		return currentDoc.expanded ? getDocumentCountRecursive([currentDoc]) * 32 : 32;
	};
	return (
		<List
			ref={(ref) => {
				listRef.current = ref;
			}}
			data-testid="gonogo.content.list"
			deferredMeasurementCache={cache}
			height={getDocumentCountRecursive(documents) * 32}
			noRowsRenderer={noRowRenderer}
			overscanRowCount={5}
			rowCount={documents?.length || 0}
			rowHeight={getRowHeight}
			rowRenderer={rowRenderer}
			scrollToAlignment="start"
			width={500}
		/>
	);
};

export default ExpandableDocumentList;
