import React from "react";
import { BlockTitle } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import ProjectsListTable from "./projects-list-table/ProjectsListTable";

export default function ProjectsList({
	isCanvas = false,
	onAddPartner,
	onAddUser,
	onClickRow,
	onDownload,
	onRelaunchDocs,
	onResetElastic,
	projects = [],
}) {
	return (
		<>
			<BlockTitle>{translate("dsi.project-list.title")}</BlockTitle>
			<ProjectsListTable
				isCanvas={isCanvas}
				projects={projects}
				onAddPartner={onAddPartner}
				onAddUser={onAddUser}
				onDownload={onDownload}
				onRelaunchDocs={onRelaunchDocs}
				onResetElastic={onResetElastic}
				onSelectRow={onClickRow}
			/>
		</>
	);
}
