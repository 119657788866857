import React from "react";
import {
	CustomButton,
	GenericDialog,
	GenericDialogContent,
	GenericDialogFooter,
	GenericDialogHeader,
} from "../../../../common/components";
import { translate } from "../../../../common/providers";

const DeleteLastVersionDialog = ({ deletingAllVersions = false, onClose, onConfirm, open }) => {
	const handleDelete = () => {
		onClose();
		onConfirm(false);
	};
	return (
		<GenericDialog open={open}>
			<GenericDialogHeader>{translate("document-center.delete-last-version-dialog.header")}</GenericDialogHeader>
			<GenericDialogContent dividers>
				<span>
					{deletingAllVersions
						? translate("document-center.delete-all-versions-dialog.content")
						: translate("document-center.delete-last-version-dialog.content")}
				</span>
			</GenericDialogContent>
			<GenericDialogFooter>
				<CustomButton variant="outlined" onClick={onClose}>
					{translate("common:btn.cancel")}
				</CustomButton>
				<CustomButton variant="contained" onClick={handleDelete}>
					{translate("common:btn.confirm")}
				</CustomButton>
			</GenericDialogFooter>
		</GenericDialog>
	);
};
export default DeleteLastVersionDialog;
