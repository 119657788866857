import React, { useCallback, useState, useRef, useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DashPortfolio } from "../../components";
import { Widgets, WidgetSidePanel } from "./components";
import {
	ApiService,
	DashboardService,
	MentionsService,
	MyService,
	ProjectCreationService,
	ProjectInfoService,
	UserService,
} from "../../../../api";
import styles from "./PortfolioUser.module.css";
import { setCompany, setloggedPhysicalComp, setSegmentationFlags, setToken } from "../../../../common/slice";
import {
	CompanyType,
	environmentSwitch,
	Flags,
	hasPermission,
	isCompanyTypeAllowed,
	isFeatureEnabled,
	Permissions,
	SegFlags,
	translate,
} from "../../../../common/providers";
import { CustomButtonLink } from "../../../../common/components";
import { NavUtils } from "../../../../navigation";
import { setPackages } from "../../../new-project/slice/newProjectSlice";
import projectTeamService from "../../../../api/services/project/project-team-service";
import KeycloakService from "../../../../services/KeycloakService";

export default function PortfolioUser() {
	const [projects, setProjects] = useState({});
	const [currentProjectsPage, setCurrentProjectsPage] = useState(0);
	const [isLoadingProjects, setIsLoadingProjects] = useState(true);
	const [openProjectId, setOpenProjectId] = useState(null);
	const [project, setProject] = useState(null);
	const [rowsPerPage, setRowsPerPage] = useState(50);
	const [selectedTeams, setSelectedTeams] = useState([]);
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [openSidePanel, setOpenSidePanel] = useState(false);
	const [sortKey, setSortKey] = useState("");
	const [sortDirection, setSortDirection] = useState("desc");
	const [search, setSearch] = useState("");
	const [hasProjects, setHasProjects] = useState(false);
	const [currentCompany, setCurrentCompany] = useState(null);
	const company = useSelector(({ context }) => context.company);
	const companies = useSelector(({ context }) => context.companies);
	const loggedPhysicalCompId = useSelector(({ context }) => context.loggedPhysicalComp);
	const cancelTokenSourceRef = useRef();
	let preventGetProjectInformation = false;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleStateProjectItem = (data, type) => {
		setProject((prev) => ({ ...prev, [type]: data }));
	};
	const loginWithCompany = (cid) => {
		if (cid !== currentCompany) {
			return UserService.loginWithCompany({ companyId: cid }, cancelTokenSourceRef.current.token)
				.then(() => {
					setCurrentCompany(cid);
				})
				.catch((err) => console.error(err));
		}
		return Promise.resolve();
	};
	const getProjectInformation = (projectId, companyId) => {
		if (preventGetProjectInformation) {
			preventGetProjectInformation = false;
			return;
		}
		if (!companyId || !projectId) {
			return;
		}
		DashboardService.getProjectDocuments({ projectId, companyId }, cancelTokenSourceRef.current.token)
			.then((data) => handleStateProjectItem(data, "documents"))
			.catch((err) => {
				console.error(err);
				handleStateProjectItem(null, "documents");
			});
		DashboardService.getProjectRequirements({ projectId, companyId }, cancelTokenSourceRef.current.token)
			.then((data) => handleStateProjectItem(data, "projectReq"))
			.catch((err) => {
				console.error(err);
				handleStateProjectItem(null, "projectReq");
			});
		DashboardService.getProjectRequirementsValidation({ projectId, companyId }, cancelTokenSourceRef.current.token)
			.then((data) => handleStateProjectItem(data, "userReqValid"))
			.catch((err) => {
				console.error(err);
				handleStateProjectItem(null, "userReqValid");
			});

		DashboardService.getProjectPartners({ projectId, companyId }, cancelTokenSourceRef.current.token)
			.then((data) => handleStateProjectItem(data, "partners"))
			.catch((err) => console.error(err));
	};
	const handleProject = (pr, prs, ste) => {
		const { projectId } = pr || {};
		if (openProjectId === projectId || projectId === null) {
			return;
		}
		if (projectId) {
			const newSelectedProjectIndex = (prs || projects).contents.findIndex((x) => x.projectId === projectId);
			if (newSelectedProjectIndex < 0) {
				return;
			}
			setOpenProjectId(projectId);
			setSelectedRow(pr);
			const newCompanyId = (ste || selectedTeams)[newSelectedProjectIndex]?.id;
			getProjectInformation(projectId, newCompanyId);
			handleStateProjectItem(pr, "projectInfo");
		}
	};
	const getProjects = useCallback(
		({ page = 0, limit = 50 }) => {
			setSelectedTeams([]);
			setIsLoadingProjects(true);
			let payload = null;
			if (search && search.length > 0) {
				payload = { name: search };
			}
			MyService.getProjects(cancelTokenSourceRef.current.token, {
				page,
				limit,
				payload,
				sort: sortKey,
				direction: sortDirection.toUpperCase(),
			})
				.then((data) => {
					const newSelectedTeams = data.contents.map((pr) => pr.teams?.[0] || null);
					const newProjects = data;
					setSelectedTeams(newSelectedTeams);
					setProjects(newProjects);
					setSelectedRow(data.totalElements > 0 ? data.contents[0] : null);
					setProject(null);
					handleProject(
						data.totalElements === 0 || data.contents[0].userStatusInProject !== "ACTIVE"
							? null
							: data.contents[0],
						newProjects,
						newSelectedTeams
					);
					if (!search && data.totalElements > 0) {
						setHasProjects(true);
					}
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(setIsLoadingProjects(false));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[search, sortKey, sortDirection]
	);

	useEffect(() => {
		document.title = "Projects Dashboard";
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		if (
			environmentSwitch({
				veolia: hasPermission([Permissions.COMPANY_ADMIN, Permissions.COMPANY_MANAGER]),
				main: hasPermission([Permissions.COMPANY_ADMIN, Permissions.COMPANY_MANAGER]),
				smac: true,
			})
		) {
			ProjectCreationService.getCompanyPackages(cancelTokenSourceRef.current.token)
				.then((data) => {
					dispatch(setPackages(data));
				})
				.catch((err) => console.error(err));
		}
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, [dispatch]);
	useEffect(
		() => {
			getProjects({ page: 0, limit: rowsPerPage });
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getProjects, search, sortKey, sortDirection]
	);

	const handleLoadMoreProjects = () => {
		if (currentProjectsPage < projects.totalPages - 1) {
			const page = currentProjectsPage + 1;
			setCurrentProjectsPage(page);
			return MyService.getProjects(cancelTokenSourceRef.current.token, { page, limit: rowsPerPage })
				.then((data) => {
					setSelectedTeams((prev) => [...prev, ...data.contents.map((pr) => pr.teams[0])]);
					setProjects((prev) => ({ ...data, contents: [...prev.projects.contents, ...data.contents] } || []));
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(setIsLoadingProjects(false));
		}
		return null;
	};
	const handleCurrentProjectsPage = () => {
		setCurrentProjectsPage(0);
	};
	const handleChangePage = (event, newPage) => {
		setCurrentProjectsPage(newPage);
		getProjects({ page: newPage, limit: rowsPerPage });
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(event.target.value);
		setCurrentProjectsPage(0);
		getProjects({ page: 0, limit: event.target.value });
	};
	const handleChangeTeam = (index, e) => {
		const tempSelected = [...selectedTeams];
		const { projectId } = projects.contents[index];
		tempSelected[index] = e.target.value;
		setSelectedTeams(tempSelected);
		getProjectInformation(projectId, tempSelected[index]?.id || 0);
		if (projectId !== openProjectId) {
			preventGetProjectInformation = true;
		}
		if (isFeatureEnabled(Flags.MENTIONS)) {
			cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
			const companyId = e.target.value.id;

			MentionsService.getUserUnreadMentionsCountByProjectAndCompany(
				{ projectId, companyId },
				cancelTokenSourceRef.current.token
			)
				.then((data) => {
					projects.contents[index].unreadMentions = data.value;
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};
	const handleChangeCompany = (index) => {
		const tempCompany = selectedTeams[index];
		const tempProject = projects.contents[index];
		if (!tempCompany.id) {
			return;
		}
		loginWithCompany(tempCompany.id).then(() => {
			ProjectInfoService.getActiveFeatures(
				{ projectId: tempProject.projectId },
				cancelTokenSourceRef.current.token
			).then((flags) => {
				batch(() => {
					dispatch(setCompany(companies.find((cp) => cp.id === tempCompany.id)));
					dispatch(
						setloggedPhysicalComp(
							companies.find((c) => c.id === tempCompany?.id)?.id || loggedPhysicalCompId
						)
					);
					dispatch(setSegmentationFlags());
					dispatch(setToken(KeycloakService.getToken()));
					navigate(
						(tempProject.hasRoleOnDocument &&
							flags.some((flag) => flag.featureKey === SegFlags.REVIEW_PROGRESS_DASHBOARD) &&
							NavUtils.goToReviewProgress(tempProject.projectId)) ||
							(flags.some((flag) => flag.featureKey === SegFlags.PROJECT_DASHBOARD) &&
								NavUtils.goToProject(tempProject.projectId)) ||
							NavUtils.goToDocumentCenter(tempProject.projectId)
					);
				});
			});
		});
	};
	const handleSetIndex = (index) => {
		setSelectedIndex(index);
	};
	const handleChangeUrl = (url) => {
		const tempCompany = selectedTeams[selectedIndex];
		if (!tempCompany.id) {
			return;
		}
		if (tempCompany.id !== company.id) {
			loginWithCompany(tempCompany.id).then(() => {
				window.history.pushState({}, null, url);
				window.location.reload();
			});
		} else {
			window.location.reload();
		}
	};
	const handleJoinProject = (projectId) => {
		projectTeamService
			.joinProject({ projectId }, cancelTokenSourceRef.current.token)
			.then(() => {
				window.location.href = NavUtils.goToDocumentCenter(projectId);
			})
			.catch((err) => console.error(err));
	};
	const handleCloseSidePanel = () => {
		setOpenSidePanel((prev) => !prev);
	};
	const handleClickSort = (newKey) => {
		if (newKey === sortKey) {
			setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
		} else {
			setSortKey(newKey);
			setSortDirection("asc");
		}
	};
	const handleSetSearch = (text) => {
		setSearch(text);
	};
	const isAdminOrManager = hasPermission([Permissions.COMPANY_ADMIN, Permissions.COMPANY_MANAGER]);
	const isCompTypeAllowed = isCompanyTypeAllowed([CompanyType.BUSINESS_UNIT, CompanyType.COMPANY]);
	return (
		<div className={styles.mainContainer}>
			{!hasProjects ? (
				<div className={styles.noProject}>
					<span className={styles.noProject__text}>
						{translate("dashboards.dashboard-portfolio-user.no-projects")}
					</span>
					{isAdminOrManager && isCompTypeAllowed && (
						<CustomButtonLink color="primary" to={NavUtils.goToNewProject()} variant="contained">
							{translate("dashboards.dash-portfolio.button.new-project")}
						</CustomButtonLink>
					)}
				</div>
			) : (
				<div className={styles.contentContainer}>
					<div className={`${styles.projectList} ${styles.screenPart}`}>
						<DashPortfolio
							isAdminOrManager={isAdminOrManager}
							isLoading={isLoadingProjects}
							page={currentProjectsPage}
							projects={projects}
							rowsPerPage={rowsPerPage}
							selectedRow={selectedRow}
							selectedTeams={selectedTeams}
							setCurrentProjectsPage={handleCurrentProjectsPage}
							sortDirection={sortDirection}
							sortKey={sortKey}
							onChangeCompany={handleChangeCompany}
							onChangePage={handleChangePage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
							onChangeTeam={handleChangeTeam}
							onClickSort={handleClickSort}
							onJoinProject={handleJoinProject}
							onLoadMore={handleLoadMoreProjects}
							onOpenSidePanel={handleCloseSidePanel}
							onSelectProject={handleProject}
							onSetIndex={handleSetIndex}
							onSetSearch={handleSetSearch}
						/>
					</div>
					<div className={`${styles.widgets} ${styles.screenPart}`}>
						{projects?.contents?.length > 0 && (
							<Widgets
								openProjectId={openProjectId}
								project={project}
								selectedIndex={selectedIndex}
								onChangeCompany={handleChangeCompany}
								onChangeUrl={handleChangeUrl}
							/>
						)}
					</div>
				</div>
			)}
			<WidgetSidePanel
				open={openSidePanel}
				openProjectId={openProjectId}
				project={project}
				projects={projects}
				selectedIndex={selectedIndex}
				onChangeCompany={handleChangeCompany}
				onChangeUrl={handleChangeUrl}
				onClose={handleCloseSidePanel}
			/>
		</div>
	);
}
