import React, { useEffect, useRef, useState } from "react";
import { Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ApiService, ProjectCreationService } from "../../../../api";
import {
	CategoryTemplateSelectionList,
	CustomButtonLink,
	I18nTranslate,
	KnowledgeBasePopOver,
} from "../../../../common/components";
import { translate } from "../../../../common/providers";
import { setCheckedCategoriesIds } from "../../slice/newProjectSlice";
import styles from "./AddCategoriesStep.module.css";

export default function AddCategoriesStep() {
	const projectCreation = useSelector((state) => state.newProject.projectCreation);
	const hasCategorySegFlag = useSelector((state) => state.newProject.hasCategorySegFlag);
	const [defaultIdsLoading, setDefaultIdsLoading] = useState(true);
	const [localCatSelection, setLocalCatSelection] = useState([]);
	const [hasCategory, setHasCategory] = useState(true);
	const [open, setOpen] = useState(true);
	const cancelTokenSourceRef = useRef(null);
	const dispatch = useDispatch();
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		ProjectCreationService.getProjectCategoriesIds(
			{ projectId: projectCreation.project.id },
			cancelTokenSourceRef.current.token
		)
			.then((data) => {
				dispatch(setCheckedCategoriesIds(data?.ids || []));
			})
			.catch((err) => console.error(err))
			.finally(() => {
				setDefaultIdsLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectCreation]);
	useEffect(() => {
		dispatch(setCheckedCategoriesIds(localCatSelection));
	}, [localCatSelection, dispatch]);

	const handleChangeCheckedIds = (newCheckedIds) => {
		setLocalCatSelection(newCheckedIds);
	};
	const className = `${styles["category-message"]} ${
		(hasCategory && styles["category-further"]) || styles["category-warning"]
	}`;
	return (
		<>
			<div className={styles["main-container"]}>
				<div className={styles.container__header}>
					<div className={styles["step-title"]}>
						{translate("new-project.add-categories.title")}
						<KnowledgeBasePopOver
							label={<I18nTranslate translationKey="new-project.add-categories.information" />}
							link={translate("new-project.add-categories.link")}
							linkLabel={translate("common:information.learn-more")}
						/>
					</div>
					<div className={styles.tooltip} />
				</div>
				{(hasCategorySegFlag && (
					<>
						{open && (
							<Alert
								className={className}
								severity="info"
								onClose={() => {
									setOpen(false);
								}}
							>
								{(!hasCategory && <>{translate("new-project.add-categories.category-warning")}</>) || (
									<ul>
										<li>{translate("new-project.add-categories.category-further")}</li>
										<li>{translate("new-project.add-categories.category-furthermore")} </li>
									</ul>
								)}
							</Alert>
						)}
						<div>
							{!defaultIdsLoading && (
								<CategoryTemplateSelectionList
									allowDefaultChanges
									checkChildren
									defaultCheckedIds={localCatSelection}
									onChangeCheckedIds={handleChangeCheckedIds}
									onSetHasCategory={(hasCat) => {
										setHasCategory(hasCat);
									}}
								/>
							)}
						</div>
					</>
				)) || (
					<div className={styles.noAccess__container}>
						{translate("new-project.add-categories.no-access")}
						<CustomButtonLink
							openInNewTab
							rel="noreferrer"
							target="_blank"
							to={translate("new-project.add-categories.no-access.redirect-url")}
						>
							{translate("common:information.learn-more")}
						</CustomButtonLink>
					</div>
				)}
			</div>
		</>
	);
}
