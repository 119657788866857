import { TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
	CategoryTemplateSelectionList,
	CategorySelectionList,
	CustomDialog,
	SearchCardTemplateSelectionList,
	SearchCardSelectionList,
	CustomAccordion,
	createNotification,
} from "../..";
import styles from "./AutomationDialog.module.css";
import { translate } from "../../../providers";
import { VISIBILITY } from "../../../constants";
import { ActionAutoService, ActionAutoTemplateService, ApiService } from "../../../../api";

export default function AutomationDialog({
	action,
	availableParents,
	isMultiProject = false,
	isNew = false,
	onClose,
	onSubmit,
	open,
	preSelectedSearchCards = null,
}) {
	const [tempName, setTempName] = useState("");
	const [tempCategoryId, setTempCategoryId] = useState([]);
	const [tempDescription, setTempDescription] = useState("");
	const [tempSCId, setTempSCId] = useState(null);
	const [displayPrivacyMessage, setDisplayPrivacyMessage] = useState(false);
	const projectId = useSelector(({ context }) => context.project?.id);
	const cancelTokenSourceRef = useRef(null);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	});
	useEffect(() => {
		setTempSCId(preSelectedSearchCards);
	}, [preSelectedSearchCards]);
	useEffect(() => {
		setTempName(action?.name || "");
		setTempDescription(action?.description || "");
		setTempCategoryId(action?.thematicTemplateId || action?.thematicId || null);
		setTempSCId(action?.searchTemplateId || action?.searchCardId || null);
	}, [action]);
	const handleSubmit = () => {
		if (isNew) {
			ActionAutoService.createAction(
				{ projectId },
				{
					name: tempName,
					description: tempDescription,
					thematicId: tempCategoryId,
					searchCardId: tempSCId,
				},
				cancelTokenSourceRef.current.token
			)
				.then(() => {
					onSubmit();
				})
				.catch(console.error);
		} else {
			ActionAutoService.updateAction(
				{ actionId: action.id, projectId },
				{
					name: tempName,
					description: tempDescription,
					thematicId: tempCategoryId,
					searchCardId: tempSCId,
				},
				cancelTokenSourceRef.current.token
			)
				.then(() => {
					onSubmit();
				})
				.catch(console.error);
		}
	};
	const handleSubmitMultiProject = () => {
		if (isNew) {
			ActionAutoTemplateService.createAction(
				{
					name: tempName,
					description: tempDescription,
					thematicTemplateId: tempCategoryId,
					searchTemplateId: tempSCId,
				},
				cancelTokenSourceRef.current.token
			)
				.then(() => {
					if (displayPrivacyMessage) {
						createNotification({
							type: "success",
							message: translate("common:automation.visibility-changed"),
						});
					}
					onSubmit();
				})
				.catch(console.error);
		} else {
			ActionAutoTemplateService.updateAction(
				{ actionId: action.id },
				{
					name: tempName,
					description: tempDescription,
					thematicTemplateId: tempCategoryId,
					searchTemplateId: tempSCId,
				},
				cancelTokenSourceRef.current.token
			)
				.then(() => {
					onSubmit();
				})
				.catch(console.error);
		}
	};
	const handleClose = () => {
		onClose();
	};
	const handleChangeTempName = (e) => {
		setTempName(e.target.value);
	};
	const handleChangeTempDescription = (e) => {
		setTempDescription(e.target.value);
	};
	const handleChangeTempSC = (newIds, selectedTemplate) => {
		setTempSCId(newIds[0]);
		if (selectedTemplate.visibility === VISIBILITY.PRIVATE) {
			setDisplayPrivacyMessage(true);
		}
	};
	const handleChangeCheckedCategoryIds = (newIds) => {
		setTempCategoryId(newIds[0]);
	};
	return (
		<CustomDialog
			isForm
			open={open}
			submitDisabled={!tempName || !tempCategoryId || !tempSCId}
			submitLabel={translate(isNew ? "common:btn.create" : "common:btn.confirm")}
			title={isNew ? translate("common:automation.actions.new-automation") : action?.name}
			onClose={handleClose}
			onSubmit={isMultiProject ? handleSubmitMultiProject : handleSubmit}
		>
			<div className={styles.formContainer}>
				<TextField
					fullWidth
					label={translate("common:automation.dialog.field.name")}
					size="small"
					value={tempName}
					onChange={handleChangeTempName}
				/>
				<TextField
					fullWidth
					multiline
					label={translate("common:automation.dialog.field.description")}
					size="small"
					value={tempDescription}
					onChange={handleChangeTempDescription}
				/>
				{(isMultiProject && (
					<>
						<CustomAccordion
							header={translate("common:automation.dialog.accordion-header.categories-templates")}
						>
							<CategoryTemplateSelectionList
								allowDefaultChanges
								allowPreExpand
								singleSelection
								availableParents={availableParents}
								canSelectAll={false}
								defaultCheckedIds={[tempCategoryId]}
								defaultRoot={action?.thematicTemplateRoot}
								projectId={projectId}
								onChangeCheckedIds={handleChangeCheckedCategoryIds}
							/>
						</CustomAccordion>
						<CustomAccordion
							header={translate("common:automation.dialog.accordion-header.search-cards-templates")}
						>
							<SearchCardTemplateSelectionList
								keepSelectionAcrossTabs
								singleSelection
								defaultCheckedIds={[tempSCId]}
								defaultPrivacy={action?.searchTemplatePrivacy}
								onChange={handleChangeTempSC}
							/>
						</CustomAccordion>
					</>
				)) || (
					<>
						<CustomAccordion header={translate("common:automation.dialog.accordion-header.categories")}>
							<CategorySelectionList
								allowDefaultChanges
								allowPreExpand
								isEditable
								singleSelection
								availableParents={availableParents}
								defaultCheckedIds={[tempCategoryId]}
								defaultRoot={action?.thematicRoot}
								projectId={projectId}
								onChangeCheckedIds={handleChangeCheckedCategoryIds}
							/>
						</CustomAccordion>
						<CustomAccordion header={translate("common:automation.dialog.accordion-header.search-cards")}>
							<SearchCardSelectionList
								singleSelection
								defaultCheckedIds={[tempSCId]}
								defaultPrivacy={action?.searchCardPrivacy}
								onChangeCheckedIds={handleChangeTempSC}
							/>
						</CustomAccordion>
					</>
				)}
			</div>
		</CustomDialog>
	);
}
