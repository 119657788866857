import React, { useMemo, useState, useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CollabService, ApiService } from "../../../../../../api";
import { flattenThematicCategories } from "../../../../../../common/utils";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../../../common/providers";
import { CustomIconButton, icon } from "../../../../../../common/components";
import StatusCheckButtons from "./StatusCheckButtons";
import SentenceManagement from "./SentenceManagement";
import StatusModal from "./status-modal/StatusModal";
import ValidationChecker from "./ValidationChecker";
import { setSelectedSearch } from "../../../../slice/document/document-slice";
import { updateNode } from "../../../../slice/pdf/pdf-slice";
import { checkHardInformation, checkReq, checkReqValMode } from "../../../../utils/utils";
import styles from "./DetailBlock.module.css";
import { usePrevious } from "../../../../../../common/hooks";

export const findAndflattenThematicCategories = (data, thematic) => {
	const dataInstance = data && thematic && data.find((x) => thematic && thematic.includes(x.name));
	if (!dataInstance) {
		return [];
	}
	return flattenThematicCategories([dataInstance]);
};

export default function DetailBlock({ currentPosition, data, hoveredBlock, onHoverSignal, onMouseEnterBlock }) {
	const [insideStatusArea, setInsideStatusArea] = useState(false);
	const [insideStatusBlock, setInsideStatusBlock] = useState(false);
	const [partnership, setPartnership] = useState([]);
	const [shouldDisplay, setShouldDisplay] = useState(false);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const companyId = useSelector(({ context }) => context.company.id);
	const redressingInformation = useSelector(({ srDocument }) => srDocument.redressingInformation);
	const selectedSearch = useSelector(({ srDocument }) => srDocument.selectedSearch);
	const cancelTokenSourceRef = useRef(null);
	const blockRef = useRef(null);
	const dispatch = useDispatch();

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		const observer = new ResizeObserver(() => {
			if (blockRef.current?.offsetWidth) {
				setShouldDisplay(blockRef.current?.offsetWidth >= (window.innerWidth > 1440 ? 417 : 362));
			}
		});
		if (blockRef.current) {
			observer.observe(blockRef.current);
		}

		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
			observer.disconnect();
		};
	}, []);
	const prevSelectedSearch = usePrevious(selectedSearch);
	useEffect(() => {
		if (
			selectedSearch &&
			prevSelectedSearch &&
			prevSelectedSearch.informationId !== selectedSearch.informationId &&
			data.informationId !== selectedSearch.informationId
		) {
			setInsideStatusArea(false);
			setInsideStatusBlock(false);
		}
	}, [data, prevSelectedSearch, selectedSearch]);

	useEffect(() => {
		const handleResize = () => {
			setShouldDisplay(blockRef.current?.offsetWidth >= (window.innerWidth > 1440 ? 417 : 362));
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const prevData = usePrevious(data);
	useEffect(() => {
		if (prevData?.sentenceType && data.sentenceType !== prevData.sentenceType) {
			setShouldDisplay(blockRef?.current?.offsetWidth >= (window.innerWidth > 1440 ? 417 : 362));
		}
	}, [data?.sentenceType, prevData?.sentenceType]);

	const getPartnership = useCallback(() => {
		if (isSegFeatureEnabled(SegFlags.PARTNERS)) {
			CollabService.getPartnershipData({ informationId: data.informationId }, cancelTokenSourceRef.current.token)
				.then((retData) => {
					setPartnership(Array.isArray(retData) && retData.length > 0 ? retData : []);
				})
				.catch((err) => console.error(err));
		} else {
			CollabService.getMyCompanyData({ informationId: data.informationId }, cancelTokenSourceRef.current.token)
				.then((retData) => {
					setPartnership([retData]);
				})
				.catch((err) => console.error(err));
		}
	}, [data.informationId]);

	const handleCommentIssueUpdate = useCallback((id, lastCommentIssue) => {
		setPartnership((prev) => prev.map((x) => (x.companyId === id ? { ...x, issueComment: lastCommentIssue } : x)));
	}, []);

	const handleHoverStatusArea = (bool) => {
		setInsideStatusArea(bool);
	};

	const handleHoverStatusBlock = (bool) => {
		setInsideStatusBlock(bool);
	};

	const setOpeningIssuer = () => {
		setInsideStatusArea(false);
		setInsideStatusBlock(false);
	};
	const handleOpenSidePanel = (source) => {
		dispatch(
			setSelectedSearch({
				pageStart: data.pageStart,
				infoId: data.informationId,
				isRequirement: checkReq(data),
				source,
				openSidePanel: true,
			})
		);
	};
	const dispatchIssueComment = (comment) => {
		const { pageStart, informationId } = data;
		dispatch(
			updateNode({
				mode,
				pageConditions: { page: pageStart },
				informationId,
				updates: [
					{
						property: "lastCommentIssue",
						value: comment,
					},
				],
			})
		);
	};
	const isRequirement = useMemo(() => checkHardInformation(data), [data]);
	const hasCommentsOrCategoriesOrLinks = useMemo(
		() => data.commentsCount || data.thematicCount || data.linkTypeCount,
		[data]
	);
	const isHighlighted = selectedSearch?.infoId === data.informationId;
	return (
		<>
			{checkReqValMode(mode) &&
				(isRequirement ||
					hasCommentsOrCategoriesOrLinks ||
					data.informationId === hoveredBlock?.informationId) && (
					<div
						className={styles.detailBlockContainer}
						data-highlighted={isHighlighted}
						data-hovered={hoveredBlock?.informationId === data.informationId}
						data-testid={`detail.block.${data.informationId}`}
					>
						<div
							ref={blockRef}
							className={styles.detailBlock}
							role="presentation"
							onMouseEnter={() => onMouseEnterBlock(data)}
							onMouseLeave={onHoverSignal}
						>
							{!redressingInformation && (
								<div className={styles.freeze}>
									<ValidationChecker meta={data} onHoverBlock={() => onMouseEnterBlock(data)} />
								</div>
							)}
							<div
								className={styles.detailBlock}
								role="presentation"
								onMouseEnter={() => onMouseEnterBlock(data)}
								onMouseLeave={onHoverSignal}
							>
								<div>
									<div className={styles.additionalBlocks}>
										<CustomIconButton
											btnClassName={styles.detailBlock__indicator}
											icon={icon.faInfo}
											size="sm"
											tooltip={data.informationId}
											tooltipPlacement="top"
										/>
									</div>
									{isRequirement && (
										<div
											className={styles.statusButtons}
											data-testid="position.status.area"
											onMouseEnter={() => handleHoverStatusArea(true)}
											onMouseLeave={() => handleHoverStatusArea(false)}
										>
											{(isRequirement && (
												<StatusCheckButtons
													data={data}
													ownCompany={
														partnership.find((x) => x.companyId === companyId) || {}
													}
													setOpeningIssuer={setOpeningIssuer}
													onDispatchIssueComment={dispatchIssueComment}
												/>
											)) || (
												<div className={styles.notForUs}>
													{translate("smart-review.detail-block.not-for-us")}
												</div>
											)}
										</div>
									)}
									<div data-management className={styles.additionalBlocks}>
										{(((shouldDisplay && isRequirement) || !isRequirement) && (
											<SentenceManagement
												categoriesCount={data.thematicCount}
												commentsCount={data.commentsCount}
												deliverablesCount={data.deliverablesCount}
												float="right"
												hasCommentsOrCategoriesOrLinks={hasCommentsOrCategoriesOrLinks}
												isHovered={
													hoveredBlock && data.informationId === hoveredBlock?.informationId
												}
												isRequirement={isRequirement}
												linkTypeCount={data.linkTypeCount}
												questionAnswer={data.questionAnswer}
												reviewCounters={data.reviewCounters}
												tasksCount={data.tasksCount}
												onDispatchIssueComment={dispatchIssueComment}
												onOpenSidePanel={handleOpenSidePanel}
											/>
										)) || (
											<CustomIconButton
												btnClassName={styles.button}
												color="secondary"
												icon={icon.faEllipsisH}
												size="sm"
												onClick={() => {
													handleOpenSidePanel(0);
												}}
											/>
										)}
									</div>
								</div>
								{isRequirement && (insideStatusArea || insideStatusBlock) && (
									<>
										<StatusModal
											currentPosition={currentPosition}
											data={data}
											getPartnership={getPartnership}
											informationId={data?.informationId || null}
											open={insideStatusArea || insideStatusBlock}
											page={data.pageStart}
											partnership={partnership}
											scrollTop={
												document.getElementById("syncedDetailsRightPanel__id")?.scrollTop
											}
											onCommentIssueUpdate={handleCommentIssueUpdate}
											onDispatchIssueComment={dispatchIssueComment}
											onHoverStatusBlock={handleHoverStatusBlock}
										/>
									</>
								)}
							</div>
						</div>
					</div>
				)}
		</>
	);
}
