import { TablePagination } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ApiService, OpportunityService } from "../../../../api";
import opportunityService from "../../../../api/services/opportunity-service";
import { createNotification, SearchInput } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import { debounce, downloadFile } from "../../../../common/utils";
import OpportunityTable from "./opportunity-table/OpportunityTable";
import { getOpportunities, setNeedUpdate, setSearchValue } from "../../slice/opportunitySlice";
import styles from "./OpportunityList.module.css";
import OpportunityFilter from "./opportunity-filter/OpportunityFilter";
import EditNameDialog from "./edit-dialog/EditNameDialog";
import { usePrevious } from "../../../../common/hooks";
import NotifySidePanel from "./notify-side-panel/NotifySidePanel";

const debounceFunc = debounce((func) => func(), 10);
export default function OpportunityList() {
	const totalElements = useSelector(({ opportunity }) => opportunity.totalElements);
	const filteredIds = useSelector(({ opportunity }) => opportunity.filteredIds);
	const needUpdate = useSelector(({ opportunity }) => opportunity.needUpdate);
	const searchValue = useSelector(({ opportunity }) => opportunity.searchValue);
	const dispatch = useDispatch();
	const [openEditDialog, setOpenEditDialog] = useState(false);
	const [openSidePanelNotify, setOpenSidePanelNotify] = useState(false);
	const [tempName, setTempName] = useState("");
	const [opportunityId, setOpportunityId] = useState();
	const [rowsPerPage, setRowsPerPage] = useState(50);
	const [page, setPage] = useState(0);
	const tokenSourceRef = useRef(null);

	useEffect(() => {
		tokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(tokenSourceRef.current);
		};
	}, []);

	const prevSearchValue = usePrevious(searchValue);
	const prevRowsPerPage = usePrevious(rowsPerPage);
	const prevPage = usePrevious(page);
	const prevFilteredIds = usePrevious(filteredIds);
	useEffect(() => {
		if (
			searchValue !== prevSearchValue ||
			needUpdate ||
			rowsPerPage !== prevRowsPerPage ||
			page !== prevPage ||
			filteredIds !== prevFilteredIds
		) {
			dispatch(
				getOpportunities({
					ownerIds: filteredIds?.length !== 0 ? filteredIds : undefined,
					name: searchValue || undefined,
					page,
					limit: rowsPerPage,
					token: tokenSourceRef.current.token,
				})
			);
			if (needUpdate) {
				dispatch(setNeedUpdate(false));
			}
		}
	}, [
		searchValue,
		dispatch,
		needUpdate,
		rowsPerPage,
		page,
		filteredIds,
		prevSearchValue,
		prevRowsPerPage,
		prevPage,
		prevFilteredIds,
	]);
	const handleChangeSearchValue = (e) => {
		const { value } = e.target;
		debounceFunc(() => {
			dispatch(setSearchValue(value));
		});
		setPage(0);
	};
	const handleClearSearchValue = () => {
		debounceFunc(() => {
			dispatch(setSearchValue(""));
			dispatch(
				getOpportunities({
					ownerIds: filteredIds?.length !== 0 ? filteredIds : undefined,
					name: undefined,
					page,
					limit: rowsPerPage,
					token: tokenSourceRef.current.token,
				})
			);
		});
		setPage(0);
	};
	const handleClickDownload = (row) => {
		setOpportunityId(row.id);
		opportunityService
			.downloadOpportunity({ opportunityId: row.id }, tokenSourceRef.current.token)
			.then(({ data, filename }) => {
				downloadFile({ data, filename: decodeURIComponent(filename), filetype: "xlsm" });
				if (!data) {
					createNotification({
						type: "info",
						message: translate("opportunity.download.notification-in-progress"),
					});
				} else {
					createNotification({
						type: "success",
						message: translate("opportunity.download.notification-completed"),
					});
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const handleClickRetry = (row) => {
		setOpportunityId(row.id);
		OpportunityService.restartOpportunityAnalyse({ opportunityId: row.id }, tokenSourceRef.current.token)
			.then(() => {
				dispatch(setNeedUpdate(true));
			})
			.catch((err) => console.error(err));
	};
	const handleClickDelete = (row) => {
		setOpportunityId(row.id);
		OpportunityService.deleteOpportunity({ opportunityId: row.id }, tokenSourceRef.current.token)
			.then(() => {
				dispatch(setNeedUpdate(true));
			})
			.catch((err) => console.error(err));
	};
	const handleClickEdit = (row) => {
		setOpenEditDialog(true);
		setTempName(row?.name || "");
		setOpportunityId(row.id);
	};
	const handleClickNotify = (row) => {
		setOpenSidePanelNotify(true);
		setOpportunityId(row.id);
	};
	const handleChangeTempName = (e) => {
		setTempName(e.target.value);
	};
	const handleCloseEditDialog = () => {
		setTempName("");
		setOpenEditDialog(false);
	};
	const handleSubmitEdit = () => {
		OpportunityService.editOpportunity({ opportunityId }, tempName, tokenSourceRef.current.token)
			.then(() => {
				dispatch(setNeedUpdate(true));
			})
			.catch(console.error)
			.finally(() => handleCloseEditDialog());
	};

	const handleChangePage = (_, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(event.target.value);
		setPage(0);
	};

	const handleCloseSidePanelNotify = () => {
		setOpportunityId(null);
		setOpenSidePanelNotify(false);
	};
	return (
		((totalElements > 0 || searchValue !== "" || filteredIds?.length > 0) && (
			<div className={styles.listContainer}>
				<div className={styles.filterWrapper}>
					<SearchInput
						className={styles.searchContainer}
						label={translate("oppotuny.list.textfield.label")}
						value={searchValue}
						onChange={handleChangeSearchValue}
						onClearSearch={handleClearSearchValue}
					/>
					<OpportunityFilter
						className={styles.filterContainer}
						page={page}
						rowsPerPage={rowsPerPage}
						setPage={setPage}
					/>
				</div>
				<OpportunityTable
					searchValue={searchValue}
					onClickDelete={handleClickDelete}
					onClickDownload={handleClickDownload}
					onClickEdit={handleClickEdit}
					onClickNotify={handleClickNotify}
					onClickRetry={handleClickRetry}
				/>
				<div className={styles.footer}>
					<TablePagination
						component="div"
						count={totalElements}
						page={page}
						rowsPerPage={rowsPerPage}
						rowsPerPageOptions={[50, 100, 200]}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</div>
				<EditNameDialog
					label={translate("opportunity.table.edit.input-label")}
					open={openEditDialog}
					title={translate("opportunity.table.edit.title")}
					value={tempName}
					onChange={handleChangeTempName}
					onClose={handleCloseEditDialog}
					onSubmit={handleSubmitEdit}
				/>
				<NotifySidePanel
					open={openSidePanelNotify}
					opportunityId={opportunityId}
					onClose={handleCloseSidePanelNotify}
				/>
			</div>
		)) ||
		null
	);
}
