import React from "react";
import { useSelector } from "react-redux";
import { CustomBreadcrumb } from "../../../../common/components";
import styles from "./DirectoryLocation.module.css";

export default function DirectoryLocation({ disabled = false, onClickDirectory }) {
	const location = useSelector(({ documentCenter }) => [...documentCenter.location]);
	const docCenterFilters = useSelector(({ documentCenter }) => documentCenter.docCenterFilters);
	return (
		<CustomBreadcrumb
			autoDisplayedItems
			enableChildrenDisplay
			className={styles.breadcrumb}
			disabled={disabled}
			iconName="faFolder"
			locations={docCenterFilters?.qualityprogress ? [] : location}
			onOptionSelection={onClickDirectory}
		/>
	);
}
