import React from "react";
import { translate } from "../../../../../providers";
import {
	CircularLoader,
	icon,
	IconComponent,
	ExpandableCategoryRow,
	HorizontalDivider,
	GenericDialogContent,
	SearchInput,
	I18nTranslate,
} from "../../../..";
import styles from "./FavoriteCatogoriesContent.module.css";

const FavoriteCategoriesContent = ({
	categories = [],
	isManaging,
	isTreeLoading = false,
	isWelcome,
	onChangeSearchValue,
	onClearSearchValue,
	onSubscribe,
	onToggleExpand,
	onUnsubscribe,
	searchValue = "",
}) =>
	(isWelcome && !isManaging && (
		<GenericDialogContent dividers>
			<I18nTranslate translationKey="common:favorite-category-dialog.helper-text.welcome" />
		</GenericDialogContent>
	)) || (
		<GenericDialogContent dividers className={styles.dialogContent}>
			<span className={styles.helperText}>{translate("common:favorite-category-dialog.helper-text")}</span>
			<HorizontalDivider />
			<div>
				<SearchInput
					className={styles.searchContainer__textfield}
					label={translate("common:favorite-category-dialog.search")}
					value={searchValue}
					onChange={onChangeSearchValue}
					onClearSearch={onClearSearchValue}
				/>
				<div className={styles.mainContainer}>
					{(isTreeLoading && (
						<div className={styles.mainContainer__loader}>
							<CircularLoader />
						</div>
					)) ||
						(Array.isArray(categories) &&
							categories.length > 0 &&
							categories.map((category) => (
								<ExpandableCategoryRow
									key={category.id}
									isEditable
									isSubscription
									category={category}
									className={styles.expandableCategoryRow}
									expanded={
										(category.filtered === false && category.expanded === undefined) ||
										category.expanded
									}
									isManaging={isManaging}
									onSubscribe={onSubscribe}
									onToggleExpand={onToggleExpand}
									onUnsubscribe={onUnsubscribe}
								/>
							))) || (
							<div className={styles.emptyState}>
								<IconComponent
									className={styles.emptyIcon}
									color="var(--color-blue)"
									icon={icon.faInfoCircle}
									size="lg"
								/>
								{translate("common:filters.project-categories-filter.no-categories")}
							</div>
						)}
				</div>
			</div>
		</GenericDialogContent>
	);

export default FavoriteCategoriesContent;
